import React from 'react';

import { ReactAux, ChoiceButtons, Datepicker, InfoBox, Button } from '@studiouxp/abc-mallorca-portal-ds';

const choiceButtons = [
    { value: '13:00', type:'lunch' },
    { value: '13:15', type:'lunch' },
    { value: '13:30', type:'lunch' },
    { value: '13:45', type:'lunch' },
    { value: '14:00', type:'lunch' },
    { value: '14:15', type:'lunch', checked: true },
    { value: '14:30', type:'lunch' },
    { value: '14:45', type:'lunch' },
    { value: '18:00', type:'dinner' },
    { value: '18:15', type:'dinner' },
    { value: '18:30', type:'dinner' },
    { value: '18:45', type:'dinner' },
    { value: '19:00', type:'dinner' },
    { value: '19:15', type:'dinner' },
    { value: '19:30', type:'dinner' },
    { value: '19:45', type:'dinner' },
];

const updateCheckedChoices = (array: any, targetValue: any) => {
    return array.map((el: any) => {
        if (el.value.toString() === targetValue) {
            el['checked'] = true;
        } else {
            delete el.checked;
        }
        return el;
    });
}

const sevenDaysFromToday = new Date();
sevenDaysFromToday.setDate(new Date().getDate() + 7);

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

class Step2 extends React.Component {

    state = {
        date: null,
        choiceButtons: choiceButtons,
        fromToValueSecond: { from: new Date(), to: sevenDaysFromToday },
        demo: false,
    }

    onChangeChoiceButtons = (e: any) => {

        let updatedChoices = {};
        if (e.target.name === 'choice-lunch-dinner') {
            const choiceButtons = updateCheckedChoices(this.state.choiceButtons, e.target.value);
            updatedChoices = { choiceButtons: choiceButtons };
        }

        this.setState({
            ...updatedChoices
        })
    }

    render() {
        const today = new Date();

        const { date, choiceButtons, fromToValueSecond, demo } = this.state;

        return (
            <ReactAux>
                {/* This Button is only for demo */}
                <div className="text-center">
                    <Button
                        appearance="secondary"
                        onClick={() => this.setState({ demo: !demo })}
                    >
                        {demo ? 'Demo show single date' : 'Demo show date with range'}
                    </Button>
                </div>

                <h2 className="mb-s">Date</h2>
                {!demo &&
                    <ReactAux>
                        <Datepicker
                            className="mb-l"
                            id="datepicker7"
                            type='inline'
                            selectedDays={date as any}
                            onDayClick={(date: any) => this.setState({ date })}
                            fromYear={today.getFullYear()}
                            toYear={today.getFullYear() + 1}
                        />

                        <ChoiceButtons
                            title="Lunch"
                            buttons={choiceButtons.filter(el => el.type === 'lunch')}
                            onChange={this.onChangeChoiceButtons}
                            choiceButtonsName={'choice-lunch-dinner'}
                            gridColProps={{ lg: '2', md: '3' }}
                        />

                        <ChoiceButtons
                            title="Dinner"
                            buttons={choiceButtons.filter(el => el.type === 'dinner')}
                            onChange={this.onChangeChoiceButtons}
                            choiceButtonsName={'choice-lunch-dinner'}
                        />
                    </ReactAux>}

                {demo &&
                    <ReactAux>
                        <Datepicker
                            type='inline-range'
                            id="datepicker9"
                            selectedDays={[fromToValueSecond.from, { from: fromToValueSecond.from, to: fromToValueSecond.to }] as any}
                            daysRangeFromTo={fromToValueSecond}
                            onSelectDaysRange={(range: any) => this.setState({ fromToValueSecond: range })}
                            fromYear={today.getFullYear()}
                            toYear={today.getFullYear() + 2}
                        />
                        <InfoBox className="mt-s mb-l" textSize="normal">
                            <strong>From:</strong> {fromToValueSecond.from && `${fromToValueSecond.from.getDate()}th ${monthNames[fromToValueSecond.from.getMonth()]} ${fromToValueSecond.from.getFullYear()}`}<br />
                            <strong>To:</strong> {fromToValueSecond.to && `${fromToValueSecond.to.getDate()}th ${monthNames[fromToValueSecond.to.getMonth()]} ${fromToValueSecond.to.getFullYear()}`}
                        </InfoBox>
                    </ReactAux>}
            </ReactAux>
        )
    }
}

export default Step2;
