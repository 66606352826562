import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Container, Row, Col } from '@studiouxp/abc-mallorca-portal-ds';

import Layout from './Layout';
import SaranaCafe from './SaranaCafe';
import VillaSonBalagueret from './VillaSonBalagueret';
import Feeds from './Feeds';
import Profile from './Profile';
import Home from './Home';
import PaymentsForm from './PaymentsForm';
import Booking from './Booking';
import Finish from './BookingSteps/Finish';
import MyProfileRoutes from './MyProfileRoutes';
import PageWithSearch from './PageWithSearch'
import MagazinePage from './MagazinePage';
import BusinessPage from'./BusinessPage';

class DemoPages extends React.Component {

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Layout>
                <Switch>
                    <Redirect exact from="/demo-pages" to="/demo-pages/sarana-cafe" />
                    <Route path="/demo-pages/sarana-cafe" component={SaranaCafe} />
                    <Route path="/demo-pages/villa-son-balaguerten" component={VillaSonBalagueret} />
                    <Route path="/demo-pages/feeds" component={Feeds} />
                    <Route path="/demo-pages/profile" component={Profile} />
                    <Route path="/demo-pages/home" component={Home} />
                    <Route path="/demo-pages/payments-form" component={() =>
                        <Container>
                            <div className="mt-l">
                                <Row>
                                    <Col lg={{ size: 8, offset: 2 }}>
                                        <PaymentsForm />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    } />
                    <Route path="/demo-pages/booking" component={Booking} />
                    <Route path="/demo-pages/finish" component={Finish} />
                    <Route path="/demo-pages/my-profile" component={MyProfileRoutes} />
                    <Route path="/demo-pages/page-with-search" component={PageWithSearch} />
                    <Route path="/demo-pages/magazine" component={MagazinePage} />
                    <Route path="/demo-pages/business" component={BusinessPage} />
                </Switch>
            </Layout>
        );
    }
}

export default DemoPages;