import React from 'react';

import { DisplayPost, Container, Row, Col, BackgroundWrapper } from '@studiouxp/abc-mallorca-portal-ds';

const Feeds: React.FC = () => {
    return (
        <>
            <BackgroundWrapper>
                <h1 className="mb-0">Feed</h1>
            </BackgroundWrapper>
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Real Estate Agents on Mallorca"
                            link={{ attrHref: '#', onClicked: () => alert('Is clicked!') }}
                            description="The top and most experienced real estate agents on Mallorca help you find your ideal home in any location within the island. Find out more here!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-620x330_c.jpg 620w'
                            }}
                            categoryTitle="Property" />
                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Best Restaurants in the Southwest of Mallorca"
                            link={{ attrHref: '/docs' }}
                            description="Stay at one of the 14 unique rooms in this extraordinary adults-only hotel in Lloseta, Serra de Tramuntana. Enjoy the beautiful scenery, homemade food and relaxing spa. Book NOW!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-300x159.jpg 300w'
                            }}
                            categoryTitle="Category Title"
                            isMasterPost
                            buttonLink={{ title: 'book now', attrHref: '#', onClicked: () => alert('Is clicked!') }} />
                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Top Mallorca Hotels 2020"
                            link={{ attrHref: '/docs' }}
                            description="Mallorca offers visitors a vast choice of hotels and accommodation, whether you are here for a beach holiday, cycling trip or city break in Palma."
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-300x159.jpg 300w'
                            }} />
                        <DisplayPost
                             className="abc-negative-gutter"
                            title="Best Restaurants in the Southwest of Mallorca"
                            link={{ attrHref: '/docs' }}
                            description="Stay at one of the 14 unique rooms in this extraordinary adults-only hotel in Lloseta, Serra de Tramuntana. Enjoy the beautiful scenery, homemade food and relaxing spa. Book NOW!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-300x159.jpg 300w'
                            }}
                            categoryTitle="Category Title"
                            isMasterPost
                            buttonLink={{ title: 'book now', attrHref: '#', onClicked: () => alert('Is clicked!') }} />

                        <p className='text-center'><a href='/'>Load more results</a></p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Feeds;
