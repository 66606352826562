import React from 'react';

import {
    Container, Row, Col, LinkBox,
} from '@studiouxp/abc-mallorca-portal-ds';
import { profileRoutes } from '../../helpers/constants';

const ReservationsPage: React.FC= () => {

    return (
        <Container>
            <Row className='mt-l'>
                <Col lg={{ size: 8, offset: 2 }}>
                    <h2>Upcoming Reservations</h2>
                    <LinkBox
                        title="Bens d'Avali"
                        description="03 Jul, 15:30 h | 10 people"
                        icon="reservationDeniedL"
                        link={{ attrHref: profileRoutes.manageReservation, attrTitle: 'Manage Reservation' }}
                    />
                    <LinkBox
                        title="Sin Embargo Art Galleria"
                        description="15 Jun, 19:00 h | 5 people"
                        icon="reservationPendingL"
                        link={{ attrHref: profileRoutes.manageReservation, attrTitle: 'Manage Reservation' }}
                    />
                    <LinkBox
                        title="Nama"
                        description="15 Jun, 19:00 h | 5 people"
                        icon="reservationConfirmedL"
                        className="mb-l"
                        link={{ attrHref: profileRoutes.manageReservation, attrTitle: 'Manage Reservation' }}
                    />
                    <h2>Past Reservations</h2>
                    <LinkBox
                        title="Moda Bar"
                        description="23 May, 20:30 h | 2 people"
                        icon="reservationCompleterdL"
                        link={{ attrHref: profileRoutes.manageReservation, attrTitle: 'Manage Reservation' }}
                    />
                    <LinkBox
                        title="Aromata"
                        description="23 May, 20:30 h | 2 people"
                        icon="reservationNoShowL"
                        link={{ attrHref: profileRoutes.manageReservation, attrTitle: 'Manage Reservation' }}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default ReservationsPage;
