import React from 'react';
import Highlight from 'react-highlight';
import { ReactAux } from '@studiouxp/abc-mallorca-portal-ds';;

const ReactAuxPage: React.FC = () => {

    return <div>
        <h1>ReactAux</h1>

        <h2>Description</h2>
        <p>A self-eradicating component for rendering multiple elements.</p>

        <ReactAux>
            <p>Hello, World!</p>
            <p>I am a demo for ReactAux.</p>
        </ReactAux>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { ReactAux } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = () => {
    return (
    <ReactAux>
        <p>Hello, World!</p>
        <p>I am a demo for ReactAux.</p>
    </ReactAux>
    );
}
export default Example;
    `}
        </Highlight>

        <Highlight className="HTML">
            {`
//Html render will be
<p>Hello, World!</p>
<p>I am a demo for ReactAux.</p>
            `}
        </Highlight>

    </div>;
}

export default ReactAuxPage;
