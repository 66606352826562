import React from 'react';
import Highlight from 'react-highlight';

import { EventPreview } from '@studiouxp/abc-mallorca-portal-ds';

const EventPreviewPage: React.FC = () => {
    return (
        <div>
            <h1>Event Preview</h1>

            <EventPreview
                title="The Festival of Light"
                image={{
                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-430x230.jpg',
                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-300x159.jpg 300w'
                }}
                link={{ attrHref: '#', onClicked: () => alert('Is clicked!') }}
                date="02/02/2020"
                time="08:00  - 09:00"
                description="Palma is coming up roses! Twice a year in the famous La Seu Cathedral (if the sun happens to be shining) the cathedral´s rose windows reflect a figure 8." />


            <Highlight className="React">
                {
                    `
import React from 'react';
import { EventPreview } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <EventPreview
            title="The Festival of Light"
            image={{
                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-430x230.jpg',
                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-300x159.jpg 300w'
            }}
            link={{ attrHref: '#', onClicked: () => alert('Is clicked!') }}
            date="02/02/2020"
            time="08:00  - 09:00"
            description="Palma is coming up roses! Twice a year in the famous La Seu Cathedral (if the sun happens to be shining) the cathedral´s rose windows reflect a figure 8." />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>image</td>
                        <td className="text-center">
                            Object with props: <br />
                            "src": string, "srcSet": string
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the image.</td>
                    </tr>
                    <tr>
                        <td>link</td>
                        <td className="text-center">
                            Object with props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the url/link for title and image.</td>
                    </tr>
                    <tr>
                        <td>date</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the text for date.</td>
                    </tr>
                    <tr>
                        <td>time</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the text for time.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the description.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default EventPreviewPage;
