import React from 'react';
import {Link} from 'react-router-dom';
import Highlight from 'react-highlight';

const SpacingPage: React.FC = () => {
    return (
        <div>
            <h1>Spacing utilites</h1>
            <h2>Description</h2>
            <p>The Design System exports a set of CSS utility classes, that provide consistent spacing options for margins and paddings.
                Their naming is by the following pattern: <i>{'.{type}{position}-{size}'}</i> where type is 'm' or 'p' (margin or padding), the position is a letter betweeen 't', 'r', 'b', 'l' (top, right, bottom, left) 
                and the size is among '0', 's', 'm' and 'l'.
            </p>
            <p><strong>Important:</strong> There are specific spacing utility classes for the elements which serve as a pre-footer.
            They are listed in the  <Link to="/docs/footer">Footer docs</Link>.
            </p>

            <div className="mb-l">
                <div style={{backgroundColor:'rgba(56, 112, 133, 0.1)'}} className="mb-0">Margin bottom 0 (explicitly removing the margin, if there is such)</div>
                <div style={{backgroundColor:'rgba(56, 112, 133, 0.1)'}} className="mb-s">Margin bottom S</div>
                <div style={{backgroundColor:'rgba(56, 112, 133, 0.1)'}} className="mb-m">Margin bottom M</div>
                <div style={{backgroundColor:'rgba(56, 112, 133, 0.1)'}} className="mb-l">Margin bottom L</div>
            </div>

            <div className="mb-l">
                <div style={{backgroundColor:'rgba(184, 110, 51, 0.1)'}} className="pt-0 pr-s pb-m pl-l">Paddings: top 0, right: S, bottom: M, left: L</div>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import '@studiouxp/abc-mallorca-portal-ds/dist/styles.css';

const Example: React.FC = (props) => {
    return (
        <div>
            {/* Background styles are only for demo purpose */}
            <div className="mb-0">Margin bottom 0 (explicitly removing the margin, if there is such)</div>
            <div className="mb-s">Margin bottom S</div>
            <div className="mb-m">Margin bottom M</div>
            <div className="mb-l">Margin bottom L</div>
            <div className="pt-0 pr-s pb-m pl-l">Paddings: top 0, right: S, bottom: M, left: L</div>
        </div>
    );
}

export default Example;
    `}
            </Highlight>

        </div>
    )
}

export default SpacingPage;
