import React from 'react';
import Highlight from 'react-highlight';

import { DisplayPost } from '@studiouxp/abc-mallorca-portal-ds';

const DisplayPostPage: React.FC = () => {
    return (
        <div>
            <h1>Display Post</h1>

            <DisplayPost
                title="Real Estate Agents on Mallorca"
                link={{ attrHref: '#', onClicked: () => alert('Is clicked!') }}
                description="The top and most experienced real estate agents on Mallorca help you find your ideal home in any location within the island. Find out more here!"
                image={{
                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg',
                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-620x330_c.jpg 620w'
                }}
                categoryTitle="Property" />
            <DisplayPost
                title="Best Restaurants in the Southwest of Mallorca"
                link={{ attrHref: '/docs' }}
                description="Stay at one of the 14 unique rooms in this extraordinary adults-only hotel in Lloseta, Serra de Tramuntana. Enjoy the beautiful scenery, homemade food and relaxing spa. Book NOW!"
                image={{
                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg',
                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-300x159.jpg 300w'
                }}
                categoryTitle="Category Title"
                isMasterPost
                buttonLink={{ title: 'book now', attrHref: '#', onClicked: () => alert('Is clicked!') }} />
            <DisplayPost
                title="Top Mallorca Hotels 2020"
                link={{ attrHref: '/docs' }}
                description="Mallorca offers visitors a vast choice of hotels and accommodation, whether you are here for a beach holiday, cycling trip or city break in Palma."
                image={{
                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-430x230.jpg',
                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-300x159.jpg 300w'
                }} />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { DisplayPost } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <div>
        <DisplayPost
            title="Real Estate Agents on Mallorca"
            link={{ attrHref: '#', onClicked: () => alert('Is clicked!') }}
            description="The top and most experienced real estate agents on Mallorca help you find your ideal home in any location within the island. Find out more here!"
            image={{
                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg',
                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-620x330_c.jpg 620w'
            }}
            categoryTitle="Property" />
        <DisplayPost
            title="Best Restaurants in the Southwest of Mallorca"
            link={{ attrHref: '/docs' }}
            description="Stay at one of the 14 unique rooms in this extraordinary adults-only hotel in Lloseta, Serra de Tramuntana. Enjoy the beautiful scenery, homemade food and relaxing spa. Book NOW!"
            image={{
                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg',
                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-300x159.jpg 300w'
            }}
            categoryTitle="Category Title"
            isMasterPost
            buttonLink={{ title: 'book now', attrHref: '#', onClicked: () => alert('Is clicked!') }} />
        <DisplayPost
            title="Top Mallorca Hotels 2020"
            link={{ attrHref: '/docs' }}
            description="Mallorca offers visitors a vast choice of hotels and accommodation, whether you are here for a beach holiday, cycling trip or city break in Palma."
            image={{
                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-430x230.jpg',
                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-300x159.jpg 300w'
            }} />
        </div>
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title for component.</td>
                    </tr>
                    <tr>
                        <td>image</td>
                        <td className="text-center">
                            Object with props: <br />
                            "src": string, "srcSet": string
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the image.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the description for component.</td>
                    </tr>
                    <tr>
                        <td>link</td>
                        <td className="text-center">
                            Object with props: <br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the link/url for image and title.</td>
                    </tr>
                    <tr>
                        <td>isMasterPost</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Set styles for master post.</td>
                    </tr>
                    <tr>
                        <td>categoryTitle</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the category title for component.</td>
                    </tr>
                    <tr>
                        <td>buttonLink</td>
                        <td className="text-center">
                            Object with props: <br />
                            "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Show the button with url.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DisplayPostPage;
