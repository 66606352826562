import React from "react";
import Highlight from "react-highlight";

import CopyIcon from "../Helpers/CopyIcon";

import { Icon } from "@studiouxp/abc-mallorca-portal-ds";

const IconPage: React.FC = () => {
  return (
    <div>
      <h1>Icon</h1>

      <p>
        This component render icon by given the name of it. Icons have three
        different sizes 'S', 'M' & 'L'.
      </p>
      <p className="small">
        <i>
          You can see the list of icons below. When you click on icon or text
          from the list you get copy of component with current icon.
        </i>
      </p>

      <Icon icon="calendarS" />
      <Icon icon="calendarM" />
      <Icon icon="facebookPrimaryL" />

      <Highlight className="React">
        {`
import React from 'react';
import { Icon } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
      <div>
          <Icon icon="calendarS" />
          <Icon icon="calendarM" />
          <Icon icon="facebookPrimaryL" />
      </div>
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-l">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>icon</td>
            <td>See the list below</td>
            <td className="text-center">-</td>
            <td>Set the icon.</td>
          </tr>
        </tbody>
      </table>

      <table className="doc-table icons">
        <thead>
          <tr>
            <th colSpan={4}>Icons sizes</th>
          </tr>
          <tr>
            <td>
              <strong>S</strong>
            </td>
            <td>
              <strong>M</strong>
            </td>
            <td>
              <strong>L</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="aboutMallorcaM" /><br />
              <CopyIcon icon="aboutMallorcaPrimaryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="actionIconL" />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="arrowLinkS" /> <br /> <br />
              <CopyIcon icon="arrowLinkWhiteS" white />
            </td>
            <td>
              <CopyIcon icon="arrowLinkM" /> <br /> <br />
              <CopyIcon icon="arrowLinkWhiteM" white />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="activitiesM" />
              <br /> <br />
              <CopyIcon icon="activitiesWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="activitiesL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="adultonlyM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="airportM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="amexL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="arrowBackL" /> <br /> <br />
              <CopyIcon icon="arrowBackWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="arrowDownS" />
            </td>
            <td>
              <CopyIcon icon="arrowDownM" />
            </td>
            <td>
              <CopyIcon icon="arrowDownL" /> <br /> <br />
              <CopyIcon icon="arrowDownPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="arrowLeftS" />
            </td>
            <td>
              <CopyIcon icon="arrowLeftM" /> <br /> <br />
              <CopyIcon icon="arrowLeftWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="arrowLeftL" /> <br /> <br />
              <CopyIcon icon="arrowLeftPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="arrowRightS" />
            </td>
            <td>
              <CopyIcon icon="arrowRightM" /> <br /> <br />
              <CopyIcon icon="arrowRightWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="arrowRightL" /> <br /> <br />
              <CopyIcon icon="arrowRightPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="arrowUpS" />
            </td>
            <td>
              <CopyIcon icon="arrowUpM" />
            </td>
            <td>
              <CopyIcon icon="arrowUpL" /> <br /> <br />
              <CopyIcon icon="arrowUpPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="bathroomM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="beachM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="bedM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><CopyIcon icon="bookWhiteS" white /></td>
            <td>
              <CopyIcon icon="bookWhiteM" white />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="burgerL" /> <br /> <br />
              <CopyIcon icon="burgerWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="byNameWhiteM" white />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="calendarS" /> <br /> <br />
              <CopyIcon icon="calendarSecondaryS" />
            </td>
            <td>
              <CopyIcon icon="calendarM" /> <br /> <br />
              <CopyIcon icon="calendarWhiteM" white />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="categoryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="checkM" />
            </td>
            <td>
              <CopyIcon icon="checkBorderL" /> <br /> <br />
              <CopyIcon icon="checkSolidL" /> <br /> <br />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td><CopyIcon icon="closeM" /></td>
            <td>
              <CopyIcon icon="closeL" /> <br /> <br />
              <CopyIcon icon="closePrimaryL" /> <br /> <br />
              <CopyIcon icon="closeActionPrimarySolidL" /> <br /> <br />
              <CopyIcon icon="closeWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="cloudyPrimaryM" /><br /> <br />
              <CopyIcon icon="cloudySecondaryM" /><br /> <br />
              <CopyIcon icon="cloudyM" /><br /> <br />
              <CopyIcon icon="cloudyWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="cloudyL" /><br /> <br />
              <CopyIcon icon="cloudyWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="collectionsWhiteS" white /><br /> <br />
              <CopyIcon icon="collectionsWhiteSolidS" white />
            </td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="contactInfoL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="dateAndTimeL" />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="dateSecondaryS" />
            </td>
            <td>
              <CopyIcon icon="dateSecondaryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="deleteL" /> <br /> <br />
              <CopyIcon icon="deleteWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="discoverL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="dinersClubL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="eloL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="itinerariesWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="itinerariesL" />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="favouritesWhiteS" white /> <br /> <br />
              <CopyIcon icon="favouritesWhiteSolidS" white />
            </td>
            <td>
              <CopyIcon icon="favouritesWhiteM" white />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="facebookPrimaryL" /> <br /> <br />
              <CopyIcon icon="facebookWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="feedL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="flagEngM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="flagEspM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="flagDeM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="generalActionL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="googlePrimaryL" /> <br /> <br />
              <CopyIcon icon="googleWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="homeL" /> <br /> <br />
              <CopyIcon icon="homeWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="hotelsM" />
              <br /> <br />
              <CopyIcon icon="hotelsWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="hotelsL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="hipercardL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="instagramPrimaryL" /> <br /> <br />
              <CopyIcon icon="instagramWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="jcbL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="laserL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="linkedinPrimaryL" /> <br /> <br />
              <CopyIcon icon="linkedinWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="locationM" /> <br /> <br />
              <CopyIcon icon="locationWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="locationL" /> <br /> <br />
              <CopyIcon icon="locationPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="myTripWhiteM" white />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="mailM" />
            </td>
            <td>
              <CopyIcon icon="mailL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="maestroL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="mastercardL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="newCollectionL" />
              <CopyIcon icon="newCollectionWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="notificationsL" /> <br />
              <br />
              <CopyIcon icon="notificationsPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="optionsL" />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="organizerSecondaryS" />
            </td>
            <td>
              <CopyIcon icon="organizerSecondaryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="paymentL" /> <br />
              <br />
              <CopyIcon icon="paymentPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="peopleM" />
            </td>
            <td>
              <CopyIcon icon="peopleL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="partlyCloudyPrimaryM" /><br /> <br />
              <CopyIcon icon="partlyCloudySecondaryM" /><br /> <br />
              <CopyIcon icon="partlyCloudyM" /><br /> <br />
              <CopyIcon icon="partlyCloudyWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="partlyCloudyL" /><br /> <br />
              <CopyIcon icon="partlyCloudyWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="phoneM" />
            </td>
            <td>
              <CopyIcon icon="phoneL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="pinterestPrimaryL" /> <br /> <br />
              <CopyIcon icon="pinterestWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="priceSecondaryS" />
            </td>
            <td>
              <CopyIcon icon="priceM" /> <br /> <br />
              <CopyIcon icon="priceSecondaryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="profileL" /> <br />
              <br />
              <CopyIcon icon="profilePrimaryL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="rainyPrimaryM" /><br /> <br />
              <CopyIcon icon="rainySecondaryM" /><br /> <br />
              <CopyIcon icon="rainyM" /><br /> <br />
              <CopyIcon icon="rainyWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="rainyL" /><br /> <br />
              <CopyIcon icon="rainyWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="reservationCompleterdL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="reservationConfirmedL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="reservationDeniedL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="reservationNoShowL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="reservationPendingL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="reservationPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="restaurantsM" /> <br /> <br />
              <CopyIcon icon="restaurantsWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="restaurantsL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="searchM" /> <br /> <br />
              <CopyIcon icon="searchSecondaryM" />
            </td>
            <td>
              <CopyIcon icon="searchL" /> <br /> <br />
              <CopyIcon icon="searchSecondaryL" /> <br /> <br />
              <CopyIcon icon="searchWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="servicesM" /> <br /> <br />
              <CopyIcon icon="servicesWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="servicesL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="settingsWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="settingsPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="sunnyPrimaryM" /><br /> <br />
              <CopyIcon icon="sunnySecondaryM" /><br /> <br />
              <CopyIcon icon="sunnyM" /><br /> <br />
              <CopyIcon icon="sunnyWhiteM" white />
            </td>
            <td>
              <CopyIcon icon="sunnyL" /><br /> <br />
              <CopyIcon icon="sunnyWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="starSecondaryM" />
            </td>
            <td>
              <CopyIcon icon="starPrimaryL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="swimmingWhiteM" white />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="shareWhiteS" white />
            </td>
            <td>
              <CopyIcon icon="shareWhiteM" white />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="timeRegularM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="timeSecondaryS" />
            </td>
            <td>
              <CopyIcon icon="timeSecondaryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="townM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="twitterPrimaryL" /> <br /> <br />
              <CopyIcon icon="twitterWhiteL" white />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="unionPayL" />
            </td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="venueSecondaryS" />
            </td>
            <td>
              <CopyIcon icon="venueSecondaryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>
              <CopyIcon icon="viewsSecondaryS" />
            </td>
            <td>
              <CopyIcon icon="viewsSecondaryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="visaL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="visaElectronL" />
            </td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="warningM" /><br />
              <CopyIcon icon="warningSecondaryM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>
              <CopyIcon icon="webM" />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <CopyIcon icon="youtubePrimaryL" /> <br /> <br />
              <CopyIcon icon="youtubeWhiteL" white />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default IconPage;
