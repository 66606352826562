import React from 'react';
import Highlight from 'react-highlight';

import { Hero } from '@studiouxp/abc-mallorca-portal-ds';

const HeroPage: React.FC = () => {
  return (
    <div>
      <h1>Hero</h1>

      <Hero img="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/04/mercado-del-olivar-32.jpg" title="Restaurant name" description="Additional description" />

      <Highlight className="React">
        {
          `
import React from 'react';
import { Hero } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Hero img="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/04/mercado-del-olivar-32.jpg" title="Restaurant name" description="Additional description" />
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>img</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set the image.</td>
          </tr>
          <tr>
            <td>title</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set the title.</td>
          </tr>
          <tr>
            <td>description</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set the description.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default HeroPage;
