import React from 'react';
import Highlight from 'react-highlight';

import { Modal, Container, Row, Col, FormControl, Input, Button } from '@studiouxp/abc-mallorca-portal-ds';
const LogoPrimary = require('../../../images/logo-primary.svg');

class ModalPage extends React.Component {

    state = {
        isModalOpen: false,
        isNestedModalOpen: false,
        isSecondModalOpen: false,
        isContentChanged: false
    }

    render() {
        return (
            <div>
                <h1>Modal</h1>

                <div>
                    <Button onClick={() => this.setState({ isModalOpen: !this.state.isModalOpen })} className="mr-s">Modal</Button>
                    <Button onClick={() => this.setState({ isSecondModalOpen: !this.state.isSecondModalOpen })}>Modal Example cases</Button>
                    <Modal
                        isOpen={this.state.isSecondModalOpen}
                        onCloseModal={() => { this.setState({ isSecondModalOpen: false, isContentChanged: false }) }}
                        isDismissible={true}
                    >
                        {!this.state.isContentChanged ?
                            <div style={{ height: "400px" }}>
                                <Button onClick={() => this.setState({ isNestedModalOpen: !this.state.isNestedModalOpen })} className={'mb-s mr-s'}>Open Nested Modal</Button>
                                <Button onClick={() => this.setState({ isSecondModalOpen: false, isModalOpen: true})}>OPEN MODAL 1</Button>
                                <Button onClick={() => this.setState({ isContentChanged: !this.state.isContentChanged })} className={'mb-l'}>Change content in this modal</Button>
                                <p>OPEN NEW MODAL 1</p>
                            </div> :
                            <p>CHANGE CONTENT</p>
                        }
                        <Modal
                            isOpen={this.state.isNestedModalOpen}
                            onCloseModal={() => { this.setState({ isNestedModalOpen: false }) }}
                            isDismissible={true}
                            isNested={true}
                        >
                            <div> NESTED MODAL</div>
                        </Modal>
                    </Modal>

                    <Modal
                        isOpen={this.state.isModalOpen}
                        onCloseModal={() => { this.setState({ isModalOpen: false }) }}
                        isDismissible={true}
                    >
                        <Container>
                            <Row>
                                <Col lg={{ size: 8, offset: 2 }}>
                                    <div className="d-flex justify-content-center mt-s mb-m">
                                        <img src={LogoPrimary} alt="logo" />
                                    </div>
                                    <h2 className="text-center">Welcome back!</h2>
                                    <FormControl
                                        htmlFor="email"
                                        label="E-mail"
                                        isRequired
                                        isValid
                                    >
                                        <Input id="email" type="email" name="email" />
                                    </FormControl>

                                    <FormControl
                                        htmlFor="password"
                                        label="Password"
                                        isRequired
                                        isValid
                                    >
                                        <Input id="password" type="password" name="password" />
                                    </FormControl>
                                    <p>
                                        <a href="/">Forgot your password?</a>
                                    </p>
                                    <Button className="w-100 mb-m" >Log in</Button>
                                    <p className="text-center"><span className="abc-word-separator">Or</span></p>
                                    <a href="/"><Button appearance="secondary" className="w-100 mb-s">Log in with Facebook</Button></a>
                                    <a href="/"><Button appearance="secondary" className="w-100 mb-s">Log in with Google</Button></a>
                                    <a href="/"><Button appearance="secondary" className="w-100 mb-m">Log in with Apple ID</Button></a>
                                    <a href="/">Don’t have an account? <br /> Sign up now!</a>
                                </Col>
                            </Row>
                        </Container>
                    </Modal>
                </div>
                <Highlight className="React">
                    {
                        `
    import React from 'react';
    import { Modal, Container, Row, Col, FormControl, Input, Button  } from '@studiouxp/abc-mallorca-portal-ds';
    
    class Example extends React.Component{
        state = {
            isModalOpen:false
        }

        render(){
            return (
                <Button onClick={() => this.setState({ isModalOpen: !this.state.isModalOpen })} className="mr-s">Modal</Button>
                <Button onClick={() => this.setState({ isSecondModalOpen: !this.state.isSecondModalOpen })}>Modal Example cases</Button>

                // Modal Example case
                <Modal
                    isOpen={this.state.isSecondModalOpen}
                    onCloseModal={() => { this.setState({ isSecondModalOpen: false, isContentChanged: false }) }}
                    isDismissible={true}
                >
                    {!this.state.isContentChanged ?
                        <div style={{ height: "400px" }}>
                            <Button onClick={() => this.setState({ isNestedModalOpen: !this.state.isNestedModalOpen })} className={'mb-s mr-s'}>Open Nested Modal</Button>
                            <Button onClick={() => this.setState({ isSecondModalOpen: false, isModalOpen: true})}>OPEN MODAL 1</Button>
                            <Button onClick={() => this.setState({ isContentChanged: !this.state.isContentChanged })} className={'mb-l'}>Change content in this modal</Button>
                            <p>OPEN NEW MODAL 1</p>
                        </div> :
                        <p>CHANGE CONTENT</p>
                    }
                    <Modal
                        isOpen={this.state.isNestedModalOpen}
                        onCloseModal={() => { this.setState({ isNestedModalOpen: false }) }}
                        isDismissible={true}
                        isNested={true}
                    >
                        <div> NESTED MODAL</div>
                    </Modal>
                </Modal>

                // Modal
                <Modal
                    isOpen={this.state.isModalOpen}
                    onCloseModal={() => { this.setState({ isModalOpen: false }) }}
                    isDismissible={true}
                >
                    <Container>
                        <Row>
                            <Col lg={{ size: 8, offset: 2 }}>
                                <div className="d-flex justify-content-center mt-s mb-m">
                                    <img src={LogoPrimary} alt="logo" />
                                </div>
                                <h2 className="text-center">Welcome back!</h2>
                                <FormControl
                                    htmlFor="email"
                                    label="E-mail"
                                    isRequired
                                    isValid
                                >
                                    <Input id="email" type="email" name="email" />
                                </FormControl>

                                <FormControl
                                    htmlFor="password"
                                    label="Password"
                                    isRequired
                                    isValid
                                >
                                    <Input id="password" type="password" name="password" />
                                </FormControl>
                                <p>
                                    <a href="/">Forgot your password?</a>
                                </p>
                                <Button className="w-100 mb-m" >Log in</Button>
                                <p className="text-center"><span className="abc-word-separator">Or</span></p>
                                <a href="/"><Button appearance="secondary" className="w-100 mb-s">Log in with Facebook</Button></a>
                                <a href="/"><Button appearance="secondary" className="w-100 mb-s">Log in with Google</Button></a>
                                <a href="/"><Button appearance="secondary" className="w-100 mb-m">Log in with Apple ID</Button></a>
                                <a href="/">Don’t have an account? <br /> Sign up now!</a>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            );
        }
    }
    
    export default Example;
        `}
                </Highlight>
                <table className="doc-table">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>isOpen</td>
                            <td>boolean</td>
                            <td className="text-center">-</td>
                            <td>Set is modal open.</td>
                        </tr>
                        <tr>
                            <td>onCloseModal</td>
                            <td>Function</td>
                            <td className="text-center">-</td>
                            <td>Set close modal.</td>
                        </tr>
                        <tr>
                            <td>isDismissible</td>
                            <td>boolean</td>
                            <td className="text-center">-</td>
                            <td>Set is modal can be closed when click out of content.</td>
                        </tr>
                        <tr>
                            <td>isNested</td>
                            <td>boolean</td>
                            <td className="text-center">-</td>
                            <td>When close nested modal body scroll is still locked until you close the parent modal.</td>
                        </tr>
                        <tr>
                            <td>className</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Set class on modal container.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

export default ModalPage;
