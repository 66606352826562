import React from 'react';
import Highlight from 'react-highlight';

import { ThumbnailsList } from '@studiouxp/abc-mallorca-portal-ds';

const thumbnails = [
    {
        image: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/palma.jpg' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Palma"
    },
    {
        image: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/central.jpg' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Central"
    },
    {
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Southwest.jpg'
        },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Southwest"
    },
    {
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Southeast.jpg'
        },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Southeast"
    },
    {
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Northwest.jpg'
        },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Northwest"
    },
    {
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Northeast.jpg'
        },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Northeast"
    },
];

const ThumbnailsListPage: React.FC = () => {
    return (
        <div>
            <h1>Thumbnails List</h1>
            <ThumbnailsList
                title="Category"
                thumbnails={thumbnails}
            />

            <ThumbnailsList
                title="ThumbnailsList with background"
                thumbnails={thumbnails}
                hasBackground
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { ThumbnailsList } from '@studiouxp/abc-mallorca-portal-ds';

const thumbnails = [
    {
        image: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/palma.jpg' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Palma"
    },
    {
        image: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/central.jpg' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Central"
    },
    {
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Southwest.jpg'
        },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Southwest"
    },
    {
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Southeast.jpg'
        },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Southeast"
    },
    {
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Northwest.jpg'
        },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Northwest"
    },
    {
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Northeast.jpg'
        },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        title: "Northeast"
    },
];

const Example: React.FC = (props) => {
    return (
        <ThumbnailsList
            title="Category"
            thumbnails={thumbnails}
        />

        <ThumbnailsList
            title="ThumbnailsList with background"
            thumbnails={thumbnails}
            hasBackground
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>thumbnails</td>
                        <td>
                            Array of objects with props: <hr />
                            "image": object with props: "src": string, "srcSet": string,<hr />
                            "url": object with props : "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}<hr />
                            "title": string
                        </td>
                        <td className="text-center">-</td>
                        <td>Set thumb title, image and url.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td >string</td>
                        <td className="text-center">-</td>
                        <td>Sets HTML class to the wrapping element of the component.</td>
                    </tr>
                    <tr>
                        <td>hasBackground</td>
                        <td className="text-center">boolean</td>
                        <td className="text-center">false</td>
                        <td>Defines if the ThumbnailsList element has a background color.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ThumbnailsListPage;
