import React, { useState } from 'react';
import Highlight from 'react-highlight';

import { Datepicker, InfoBox } from '@studiouxp/abc-mallorca-portal-ds';

const DatepickerPage: React.FC = () => {
  const toDay = new Date();
  toDay.setDate(new Date().getDate() + 3);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const [firstDatepickerValue, setFirstDatepickerValue] = useState(new Date());
  const [fromToValue, setFromToValue] = useState({ from: null, to: null });
  const [fromToValueSecond, setFromToValueSecond] = useState({ from: new Date(), to: toDay });
  const [fromToValue3, setFromToValue3] = useState({ from: null, to: null });
  const [inlineValue, setInlineValue] = useState();
  const [inlineValueSecond, setInlineValueSecond] = useState(new Date());
  const [germanDatepickerValue, setGermanDatepickerValue] = useState(new Date('05/05/2020'));
  const [spanishDatepickerValue, setSpanishDatepickerValue] = useState(new Date('10/01/1990'));

  return (
    <div>
      <h1>Datepicker</h1>

      <h2>Description</h2>
      <p>Utilizing <a href="https://react-day-picker.js.org/" target="_blank" rel="noopener noreferrer">React Day Picker</a> library, Datepicker component
      handles date inputs via interactive calendar. It also supports English, German and Spanish locales.</p>
      <div>
        <h3>Datepicker Input</h3>
        <div className="mb-l">
          <Datepicker 
             id="datepicker1" 
             format="D/M/Y" 
             value={firstDatepickerValue} 
             onDayChange={date => setFirstDatepickerValue(date)}
             dayPickerProps={{
              // disabledDays:[new Date()],  // to disable single days
              modifiers: {
                disabled: [
                  {
                    daysOfWeek: [0, 6],
                  },
                  {
                    before: new Date(new Date().getFullYear(), new Date().getMonth(), 8),
                  },
                  {
                    after: new Date(new Date().getFullYear(), new Date().getMonth(), 20)
                  },
                ],
              },
             }}
             />
        </div>
        <div className="mb-l">
          <Datepicker id="datepicker2" format="D/M/Y" fromYear={2010} toYear={2020}></Datepicker>
        </div>
        <div className="mb-l">
          <Datepicker id="datepicker3" format="" value={new Date('3/8/2019')} inputProps={{ disabled: true }}></Datepicker>
        </div>
        <div className="mb-l">
          <Datepicker
            id="datepicker60"
            selectedDays={[fromToValue3.from, { from: fromToValue3.from, to: fromToValue3.to }] as any}
            daysRangeFromTo={fromToValue3}
            rangeFromToTexts={{ fromText: 'Check-in', toText: 'Check-out', separator: '-' }}
            onSelectDaysRange={(range: any) => setFromToValue3(range)}
            format="D.M.Y"
          />
        </div>
        <div className="d-flex align-items-center mb-l">
          <p className="mb-0 mr-s">Datepicker with German locale:</p>
          <Datepicker id="datepicker4" format="D-M-Y" value={germanDatepickerValue} onDayChange={date => setGermanDatepickerValue(date)} dayPickerProps={{ locale: 'de' }}></Datepicker>
        </div>
        <div className="d-flex align-items-center mb-l">
          <p className="mb-0 mr-s">Datepicker with Spanish locale:</p>
          <Datepicker id="datepicker5" format="D.M.Y" value={spanishDatepickerValue} onDayChange={date => setSpanishDatepickerValue(date)} dayPickerProps={{ locale: 'es' }}></Datepicker>
        </div>
      </div>

      <Highlight className="React">
        {
          `
import React from 'react';
import { Datepicker } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
  const [firstDatepickerValue, setFirstDatepickerValue] = useState(new Date());
  const [fromToValue3, setFromToValue3] = useState({ from: null, to: null });
  const [germanDatepickerValue, setGermanDatepickerValue] = useState(new Date('05/05/2020'));
  const [spanishDatepickerValue, setSpanishDatepickerValue] = useState(new Date('10/01/1990'));

  return (
    <Datepicker 
      id="datepicker1" 
      format="D/M/Y" 
      value={firstDatepickerValue} 
      onDayChange={date => setFirstDatepickerValue(date)}
      dayPickerProps={{
        // disabledDays:[new Date()],  // to disable single days
        //or
        modifiers: {
          disabled: [
            {
              daysOfWeek: [0, 6],
            },
            {
              before: new Date(new Date().getFullYear(), new Date().getMonth(), 8),
            },
            {
              after: new Date(new Date().getFullYear(), new Date().getMonth(), 20)
            },
          ],
        },
        }}
    />

    <Datepicker id="datepicker2" format="D/M/Y" fromYear={2010} toYear={2020}></Datepicker>

    <Datepicker id="datepicker3" format="" value={new Date('3/8/2019')} 
      inputProps={{ disabled: true }}>
    </Datepicker>

    <Datepicker
      id="datepicker60"
      selectedDays={[fromToValue3.from, { from: fromToValue3.from, to: fromToValue3.to }] as any}
      daysRangeFromTo={fromToValue3}
      rangeFromToTexts={{ fromText: 'Check-in', toText: 'Check-out' }}
      onSelectDaysRange={(range: any) => setFromToValue3(range)}
      format="D.M.Y"
    />

    <Datepicker id="datepicker4" format="D-M-Y" value={germanDatepickerValue} 
      onDayChange={date => setGermanDatepickerValue(date)} 
      dayPickerProps={{ locale: 'de' }}
    ></Datepicker>

    <Datepicker id="datepicker5" format="D.M.Y" value={spanishDatepickerValue} 
      onDayChange={date => setSpanishDatepickerValue(date)} 
      dayPickerProps={{ locale: 'es' }}
    ></Datepicker>
  );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-l">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>All properties of DayPickerInputProps</td>
            <td><a href="https://react-day-picker.js.org/api/DayPickerInput" target="_blank"
              rel="noopener noreferrer">Available options</a></td>
            <td>The object of defaults is configured according to the design. The default format is <i>MM/DD/YYYY</i> and
            the default locale is English.</td>
            <td>For ease of use, the props of Datepicker component extend DayPickerInputProps, but there
              are restrictions for some of these props: <i>dayPickerProps</i>, <i>formatDate</i> and <i>parseDate</i> are
              not taken in account, due to implementation logic and design consistency.
            </td>
          </tr>
          <tr>
            <td>type</td>
            <td className="text-center"> 'input' || 'inline-range' || 'inline',</td>
            <td className="text-center"> 'input'</td>
            <td>Sets Datepicker type component.</td>
          </tr>
          <tr>
            <td>className</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML class to the wrapping element of the component.</td>
          </tr>
          <tr>
            <td>fromYear</td>
            <td className="text-center">number</td>
            <td>100 years behind the current year</td>
            <td>Sets the begining of the years range.</td>
          </tr>
          <tr>
            <td>toYear</td>
            <td className="text-center">number</td>
            <td>100 years ahead of current year</td>
            <td>Sets the ending of the year range.</td>
          </tr>
        </tbody>
      </table>

      <h3>Datepicker inline</h3>
      <div className="mb-l">
        <Datepicker
          type='inline'
          id="datepicker7"
          selectedDays={[inlineValue] as any}
          onDayClick={(date: any) => setInlineValue(date)}
          className="mb-s"
        />

        <Datepicker
          type='inline'
          id="datepicker8"
          selectedDays={[inlineValueSecond] as any}
          onDayClick={(date: any) => { setInlineValueSecond(date) }}
          locale="es"
          fromYear={2019}
          toYear={2021}
        />
      </div>
      <Highlight className="React">
        {
          `
import React from 'react';
import { Datepicker } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
  const [inlineValue, setInlineValue] = useState();

  return (
        <Datepicker 
          type='inline' 
          id="datepicker7" 
          selectedDays={[inlineValue] as any}
          onDayClick={(date:any)=> setInlineValue(date)}
        />

        <Datepicker
          type='inline'
          id="datepicker8"
          selectedDays={[inlineValueSecond] as any}
          onDayClick={(date: any) => { setInlineValueSecond(date) }}
          locale="es"
          fromYear={2019}
          toYear={2021}
      />
  );
}

export default Example;
    `}
      </Highlight>
      <table className="doc-table mb-l">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>All properties of DayPickerProps</td>
            <td><a href="https://react-day-picker.js.org/api/DayPicker" target="_blank"
              rel="noopener noreferrer">Available options</a></td>
            <td>The default locale is English.</td>
            <td className="text-center">-</td>
          </tr>
          <tr>
            <td>type</td>
            <td className="text-center"> 'input' || 'inline-range' || 'inline',</td>
            <td className="text-center"> 'input'</td>
            <td>Sets Datepicker type component.</td>
          </tr>
          <tr>
            <td>fromYear</td>
            <td className="text-center">number</td>
            <td>100 years behind the current year</td>
            <td>Sets the begining of the years range.</td>
          </tr>
          <tr>
            <td>toYear</td>
            <td className="text-center">number</td>
            <td>100 years ahead of current year</td>
            <td>Sets the ending of the year range.</td>
          </tr>
          <tr>
            <td>className</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML class to the wrapping element of the component.</td>
          </tr>
        </tbody>
      </table>

      <h3>Datepicker inline range</h3>
      <div className="mb-l">
        <Datepicker
          type='inline-range'
          id="datepicker6"
          selectedDays={[fromToValue.from, { from: fromToValue.from, to: fromToValue.to }] as any}
          daysRangeFromTo={fromToValue}
          onSelectDaysRange={(range: any) => setFromToValue(range)}
          className="mb-s"
        />
        <Datepicker
          type='inline-range'
          id="datepicker9"
          selectedDays={[fromToValueSecond.from, { from: fromToValueSecond.from, to: fromToValueSecond.to }] as any}
          daysRangeFromTo={fromToValueSecond}
          onSelectDaysRange={(range: any) => setFromToValueSecond(range)}
          locale="de"
          fromYear={2019}
          toYear={2021}
        />
        <InfoBox className="mt-s" textSize="normal">
          <strong>From:</strong> {fromToValueSecond.from && `${fromToValueSecond.from.getDate()}th ${monthNames[fromToValueSecond.from.getMonth()]} ${fromToValueSecond.from.getFullYear()}`}<br />
          <strong>To:</strong> {fromToValueSecond.to && `${fromToValueSecond.to.getDate()}th ${monthNames[fromToValueSecond.to.getMonth()]} ${fromToValueSecond.to.getFullYear()}`}
        </InfoBox>

      </div>
      <Highlight className="React">
        {
          `
import React from 'react';
import { Datepicker } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
  // data for the damo
  const toDay = new Date();
  toDay.setDate(new Date().getDate()+ 3);

  const [fromToValue, setFromToValue] = useState({from:undefined, to:undefined});
  const [fromToValueSecond, setFromToValueSecond] = useState({ from: new Date(), to: toDay });

  return (
        <Datepicker 
          type='inline-range' 
          id="datepicker6"
          selectedDays={[fromToValue.from, {from: fromToValue.from, to: fromToValue.to}] as any}
          daysRangeFromTo = {fromToValue}
          onSelectDaysRange= {(range:any)=> setFromToValue(range)}
        />

        <Datepicker
          type='inline-range'
          id="datepicker9"
          selectedDays={[fromToValueSecond.from, { from: fromToValueSecond.from, to: fromToValueSecond.to }] as any}
          daysRangeFromTo={fromToValueSecond}
          onSelectDaysRange={(range: any) => setFromToValueSecond(range)}
          locale="de"
          fromYear={2019} 
          toYear={2021}
      />
  );
}

export default Example;
    `}
      </Highlight>
      <table className="doc-table mb-l">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>All properties of DayPickerProps</td>
            <td><a href="https://react-day-picker.js.org/api/DayPicker" target="_blank"
              rel="noopener noreferrer">Available options</a></td>
            <td>The default locale is English.</td>
            <td> <i>onDayClick</i> and <i>modifiers</i> will be ignored due implementation logic and design consistency.</td>
          </tr>
          <tr>
            <td>type</td>
            <td className="text-center"> 'input' || 'inline-range' || 'inline',</td>
            <td className="text-center"> 'input'</td>
            <td>Sets Datepicker type component.</td>
          </tr>
          <tr>
            <td>daysRangeFromTo</td>
            <td className="text-center">
              Object with props:<br />
            "from":undefined || null || Date<br />
            "to":undefined || null || Date<br />
            </td>
            <td className="text-center">-</td>
            <td>Sets Datepicker range.</td>
          </tr>
          <tr>
            <td>rangeFromToTexts</td>
            <td className="text-center">
              Object with props:<br />
              "fromText": string<br />
              "toText": string<br />
              "separator": string<br />
            </td>
            <td className="text-center">-</td>
            <td>Sets Datepicker range texts.</td>
          </tr>
          <tr>
            <td>onSelectDaysRange</td>
            <td className="text-center">function</td>
            <td className="text-center">-</td>
            <td>Sets onDayClick with inner logic. You will receive as param the range object.</td>
          </tr>
          <tr>
            <td>fromYear</td>
            <td className="text-center">number</td>
            <td>100 years behind the current year</td>
            <td>Sets the begining of the years range.</td>
          </tr>
          <tr>
            <td>toYear</td>
            <td className="text-center">number</td>
            <td>100 years ahead of current year</td>
            <td>Sets the ending of the year range.</td>
          </tr>
          <tr>
            <td>className</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML class to the wrapping element of the component.</td>
          </tr>
        </tbody>
      </table>

    </div>
  )
}

export default DatepickerPage;
