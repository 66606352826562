import React from 'react';
import { ReactAux, Header, ActionBar, Footer, } from '@studiouxp/abc-mallorca-portal-ds';
import { withRouter } from 'react-router-dom';
import { profileRoutesAndTitles, profileRoutes } from '../helpers/constants';

type LayoutState = {
    actionBarIsActive: any
}

class Layout extends React.Component<any, LayoutState> {

    constructor(props: any) {
        super(props);
        this.state = {
            actionBarIsActive: {
                home: !window.location.pathname.includes(profileRoutes.myProfile),
                feed: false,
                collections: false,
                profile: window.location.pathname.includes(profileRoutes.myProfile)
            }
        }
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }


    goToProfile = () => {
        if (window.location.pathname === profileRoutes.manageReservation) {
            this.props.history.push(profileRoutes.reservations);
        }else if(window.location.pathname === profileRoutes.editCard){
            this.props.history.push(profileRoutes.cards);
        }else {
            this.props.history.push(profileRoutes.myProfile);
        }

        this.setState({
            actionBarIsActive: {
                home: false,
                feed: false,
                collections: false,
                profile: true
            }
        })
    }

    getProfileTitle = () => {
        return profileRoutesAndTitles.find((e) => window.location.pathname === e.link)?.title
    }


    render() {
        const { children, } = this.props;
        const { actionBarIsActive } = this.state;
        const isBookingPage = window.location.pathname.includes('/demo-pages/booking');
        const isHomePage = window.location.pathname.includes('/demo-pages/home');
        const profileTitle = this.getProfileTitle();
        const isPageWithSearch = window.location.pathname === '/demo-pages/page-with-search';
        const isPageBusiness = window.location.pathname === '/demo-pages/business';

        return (
            <ReactAux>
                <Header
                    links={[{ title: 'About Mallorca', isActive: false, attrHref: "#", attrTitle: "About", attrTargetBlank: false, onClicked: () => console.log('about') }, { title: 'Events', isActive: false, attrHref: "#", attrTitle: "Events", attrTargetBlank: false, onClicked: () => console.log('events') },
                    { title: 'Restaurants', isActive: true, attrHref: "#", attrTitle: "Restaurants", attrTargetBlank: false, onClicked: () => console.log('restaurant') }, { title: 'Hotels', isActive: false, attrHref: "#", attrTitle: "Hotels", attrTargetBlank: false, onClicked: () => console.log('hotel') },
                    { title: 'Activities', isActive: false, attrHref: "#", attrTitle: "Activities", attrTargetBlank: false, onClicked: () => console.log('activities') }, { title: 'Services', isActive: false, attrHref: "#", attrTitle: "Services", attrTargetBlank: false, onClicked: () => console.log('services') }]}
                    weatherInfo={{
                        title: "Mallorca, Dec 10 2019:",
                        link: {
                            title: "14 °C",
                            iconType: "partlyCloudy"
                        }
                    }}
                    logo={{ attrHref: "#", attrTitle: "AbcLogo", attrTargetBlank: false, onClicked: () => console.log('logo cliked new') }}
                    search={{
                        placeholder: "Type smth",
                        onInputChange: (e: any) => console.log(e.target.value),
                        onSearchSubmit: () => console.log('search submited')
                    }}
                    language={{ selectedLanguage: "en", onLanguageChange: (lan: string) => console.log(`language changed to ${lan}`) }}
                    policyLinks={[
                        { title: 'Privacy Policy', link: { attrHref: "#", attrTitle: "Privacy", attrTargetBlank: false, onClicked: () => console.log('Privacy Policy') } },
                        { title: 'Cookie Policy', link: { attrHref: "#", attrTitle: "Cookie", attrTargetBlank: false, onClicked: () => console.log('Cookie Policy') } },
                        { title: 'Terms and Conditions', link: { attrHref: "#", attrTitle: "Terms", attrTargetBlank: false, onClicked: () => console.log('Terms and Conditions') } },
                    ]}
                    socialLinks={{
                        facebookLink: { attrHref: "#", attrTitle: "facebook", attrTargetBlank: false, onClicked: () => console.log('clicked facebook') },
                        instagramLink: { attrHref: "#", attrTitle: "instagram", attrTargetBlank: false, onClicked: () => console.log('clicked instagram') },
                        twitterLink: { attrHref: "#", attrTitle: "twitter", attrTargetBlank: false, onClicked: () => console.log('clicked twitter') },
                        googleLink: { attrHref: "#", attrTitle: "google", attrTargetBlank: false, onClicked: () => console.log('clicked google') },
                        linkedinLink: { attrHref: "#", attrTitle: "linkedIn", attrTargetBlank: false, onClicked: () => console.log('clicked linkedIn') },
                        pinterestLink: { attrHref: "#", attrTitle: "pinteres", attrTargetBlank: false, onClicked: () => console.log('clicked pinteres') },
                        youtubeLink: { attrHref: "#", attrTitle: "faceyoutubebook", attrTargetBlank: false, onClicked: () => console.log('clicked youtube') },
                    }}
                    isInnerPage={false}
                    innerPageTitle={'Some inner Page title, Some inner Page title, Some inner Page title, Some inner Page title,'}
                    onBackClicked={() => this.goToProfile()}
                    {...(isBookingPage && {
                        isInnerPage: true,
                        innerPageTitle: 'Book a table'
                    })}
                    {...(profileTitle && {
                        isInnerPage: true,
                        innerPageTitle: profileTitle
                    })}
                    {...(isPageWithSearch && {
                        isInnerPage: true,
                        innerPageTitle: 'Restaurants open during the holidays'
                    })}
                    
                    {...(isPageBusiness && {
                        inPageNav: [
                            { id: 'summary', linkTitle: 'Summary' },
                            { id: 'content', linkTitle: 'Content' },
                            { id: 'services', linkTitle: 'Services' },
                            { id: 'contact', linkTitle: 'Contact' },
                            { id: 'faq', linkTitle: 'Faq' },
                        ]
                    })}
                />

                {isHomePage ? children : (
                    <div className="abc-pre-footer-mb">
                        {children}
                    </div>
                )}

                {!isBookingPage && <ActionBar
                    homeBtn={{
                        title: "Home",
                        onCliked: () => console.log('home'),
                        isActive: actionBarIsActive.home
                    }}
                    feedBtn={{
                        title: "Feed",
                        onCliked: () => console.log('feed'),
                        isActive: actionBarIsActive.feed
                    }}
                    collectionsBtn={{
                        title: "Itineraries",
                        onCliked: () => console.log('itineraries'),
                        isActive: actionBarIsActive.collections
                    }}
                    profileBtn={{
                        title: "Profile",
                        onCliked: () => this.goToProfile(),
                        isActive: actionBarIsActive.profile
                    }}
                    actionBtn={{
                        title: 'Plan',
                        titleClose: "Close",
                        subActons: [
                            {
                                title: 'My Trip',
                                icon: 'myTripWhiteM',
                                onCliked: () => console.log('my trip'),
                            },
                            {
                                title: 'Hotel',
                                icon: 'hotelsWhiteM',
                                onCliked: () => console.log('hotel'),
                            },
                            {
                                title: 'Restaurant',
                                icon: 'restaurantsWhiteM',
                                onCliked: () => console.log('restaurant'),
                            },
                            {
                                title: 'Activity',
                                icon: 'activitiesWhiteM',
                                onCliked: () => console.log('activity'),
                            },
                            {
                                title: 'Service',
                                icon: 'servicesWhiteM',
                                onCliked: () => console.log('service'),
                            }
                        ]
                    }}
                />}

                {!isBookingPage && <Footer
                    cards={[
                        {
                            title: 'Get our magazine',
                            description: 'Articles on island-related topics in seasonal and special editions. Check them out.',
                            image: {
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/get-mag-2017b.png'
                            },
                            buttonLink: {
                                title: 'get the magazine', attrHref: '#', onClicked: () => alert('Is clicked!')
                            }
                        },
                        {
                            title: 'Get our newsletter',
                            description: 'Become a member and receive regular updates!',
                            image: {
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/get-mag-2017b.png'
                            },
                            buttonLink: {
                                title: 'subscribe now', attrHref: '#', onClicked: () => alert('Is clicked!')
                            }
                        },
                        {
                            title: 'Follow us on Facebook',
                            description: 'Don´t miss our latest news, follow us on Facebook!',
                            image: {
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/02/fb.png'
                            },
                            buttonLink: {
                                title: 'follow us', attrHref: '#', onClicked: () => alert('Is clicked!')
                            }
                        },
                    ]}
                    pagesLinks={[
                        { title: 'About Mallorca', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Events', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Restaurants', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Hotels', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Activities', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Services', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    ]}
                    otherLinks={[
                        { title: 'About abcMallorca', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Newsletter', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'abcMallorca Media', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Contact us', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    ]}
                    infoText="abcMallorca is the leading luxury lifestyle magazine, website and networking community for the Spanish Balearic island of Mallorca, published in English, Spanish and German. Interesting articles, guides, expert review & fabulous photo-shoots and videos provide a wealth of valuable insider information designed to help make living, doing business or spending time on this beautiful island a memorable experience."
                    facebookLink={{ attrHref: '#', attrTargetBlank: true }}
                    instagramLink={{ attrHref: '#', attrTargetBlank: true }}
                    twitterLink={{ attrHref: '#', attrTargetBlank: true }}
                    googleLink={{ attrHref: '#', attrTargetBlank: true }}
                    linkedinLink={{ attrHref: '#', attrTargetBlank: true }}
                    pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
                    youtubeLink={{ attrHref: '#', attrTargetBlank: true }}
                    copyrightText="© 2019 abc-knowledge S.L. All Rights Reserved."
                    policyLinks={[
                        { title: 'Privacy Policy', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Cookie Policy', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Terms and Conditions', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    ]}
                />}
            </ReactAux>
        );
    }
}

export default withRouter(Layout);
