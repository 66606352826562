import React from 'react';

import { ReactAux, SelectCards, Row, Col, Checkbox, Textarea } from '@studiouxp/abc-mallorca-portal-ds';

const selectCards = [
    {
        title: "Pasta of the day - 12.99 Euro",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/Sandro-22-1.jpg',
            srcSet: ''
        },
        description: "The relaxed setting has beautiful views and the food is fresh and healthy Mediterranean cuisine at this five-star hotel restaurant in the north of Mallorca.",
        isSelected: true,
        checkBoxTitle: "Select",
        descLessBtnText: "See less",
        descMoreBtnText: "Read more",
        footer :{
            icon: 'priceSecondaryS',
            text: 'Price: 9 to 12 euro'
        }
    },
    {
        title: "Burger - 9.90 Euro",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01.jpg',
            srcSet: ''
        },
        description: "The relaxed setting has beautiful views and the food is fresh and healthy Mediterranean cuisine at this five-star hotel restaurant in the north of Mallorca.",
        isSelected: false,
        checkBoxTitle: "Select",
        descLessBtnText: "See less",
        descMoreBtnText: "Read more",
        footer :{
            icon: 'priceSecondaryS',
            text: 'Price: 9 to 12 euro'
        }
    }
];

class Step3 extends React.Component {
    state = {
        selectCards: selectCards
    }

    onToggleSlectCards = (index: any) => {
        const copySelectCards = [...this.state.selectCards];
        copySelectCards[index].isSelected = !copySelectCards[index].isSelected
        this.setState({
            selectCards: copySelectCards
        })
    }

    render() {
        return (
            <ReactAux>
                <SelectCards cards={selectCards} onSelect={this.onToggleSlectCards} categoryTitle="Menu" />

                <h2 className="mb-s">Options</h2>
                <Row>
                    <Col lg="6">
                        <Checkbox className="d-block">Celebration</Checkbox>
                        <Checkbox className="d-block">Disability</Checkbox>
                        <Checkbox className="d-block">Alergies</Checkbox>
                        <Checkbox className="d-block mb-m">Other</Checkbox>
                    </Col>
                    <Col lg="6">
                        <Textarea className="mb-l" id="textarea" placeholder="Pleace describe..." />
                    </Col>
                </Row>
                <Checkbox className="d-block mb-l">I don't have any allergy preferences.</Checkbox>
            </ReactAux>
        )
    }
}

export default Step3;
