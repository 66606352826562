import React from 'react';

import {
    Container, Row, Col, BackgroundWrapper, FormControl, Input, Datepicker,
    Toggle, Button
} from '@studiouxp/abc-mallorca-portal-ds';

const Profile: React.FC = () => {
    return (
        <>
            <BackgroundWrapper>
                <h1 className="mb-0">Profile</h1>
            </BackgroundWrapper>
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <form>
                            <Row>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="first-name"
                                        label="First name"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="first-name" type="text" name="first-name" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="last-name"
                                        label="Last name"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="last-name" type="text" name="last-name" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="email"
                                        label="E-mail"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="email" type="email" name="email" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="telephone"
                                        label="Telephone"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="telephone" type="phote" name="telephone" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="arrival-date"
                                        label="Date of birth"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Datepicker id="date-of-birth" inputProps={{name:"data-of-birth"}} format="" fromYear={new Date().getFullYear()} toYear={new Date().getFullYear() + 2} />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="password"
                                        label="Password"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="password" type="password" name="password" />
                                    </FormControl>
                                </Col>
                                <Col md="12">
                                    <FormControl
                                        htmlFor="location"
                                        className="mb-s"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Toggle name="location" value='location' onChange={() => { }}> Location </Toggle>
                                    </FormControl>
                                    <FormControl
                                        htmlFor="push-notifications"
                                        className="mb-s"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Toggle name="push-notifications" value='push-notifications' onChange={() => { }}> Push notifications </Toggle>
                                    </FormControl>
                                    <FormControl
                                        htmlFor="e-mail-toggle"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Toggle name="e-mail-toggle" value='e-mail-toggle' onChange={() => { }}> E-mail </Toggle>
                                    </FormControl>
                                    <p>Privacy settings</p>
                                    <Button type="submit" className="w-100">Save</Button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Profile;
