import React from 'react';
import Highlight from 'react-highlight';

import { RelatedArticlesSlider } from '@studiouxp/abc-mallorca-portal-ds';

const slidesArticle = [
  {
    text: "Best spa and wellness hotels on Mallorca",
    image: {
      src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg',
      srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-300x159.jpg 300w'
    },
    url: { attrHref: '#' },
  },
  {
    text: "Top 19 beachfront luxury hotels on Mallorca",
    image: {
      src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg',
      srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-300x159.jpg 300w'
    },
    url: { attrHref: '#' },
  },
  {
    text: "Best Boutique Hotels on Mallorca 2020",
    image: {
      src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg',
      srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-300x159.jpg 300w'
    },
    url: { attrHref: '#' },
  },
  {
    text: "Luxury Med-style villa rental Sa Punta de S'Aguila",
    image: {
      src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg',
      srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-300x159.jpg 300w'
    },
    url: { attrHref: '#' },
  },
  {
    text: "Deluxe Sa Terra Rotja villa with sea vistas",
    image: {
      src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg',
      srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-300x159.jpg 300w'
    },
    url: { attrHref: '#' },
  },
]

const RelatedArticlesSliderPage: React.FC = () => {
  return (
    <div>
      <h1>Related Articles Slider</h1>

      <h2>Description</h2>
      <p className="mb-m">Utilizing <a href="https://react-slick.neostack.com/docs/get-started" target="_blank" rel="noopener noreferrer">React Slick
            </a> library.</p>
      <h3>Default</h3>
      <RelatedArticlesSlider
        title={'Recommended for you'}
        slides={slidesArticle}
        dots={true}
        autoplay={true}
        pauseOnHover={true}
        autoplaySpeed={4000}
        speed={300}
        initialSlide={0}
        infinite={true}
        className="example"

      />
      <h3>Slider with Background</h3>
      <RelatedArticlesSlider
        title={'Recommended for you with background'}
        slides={slidesArticle}
        dots={true}
        autoplay={false}
        pauseOnHover={true}
        autoplaySpeed={4000}
        speed={300}
        initialSlide={0}
        infinite={true}
        className="example-with-bg"
        isBackground={true}
      />

      <Highlight className="React">
        {
          `
import React from 'react';
import { RelatedArticlesSlider } from '@studiouxp/abc-mallorca-portal-ds';

const slidesArticle =[
    {
        text: "Best spa and wellness hotels on Mallorca",
        image:{ 
            src:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg',
            srcSet:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-300x159.jpg 300w'
        },
        url:{ attrHref: '#'},   
    }, 
    {
        text: "Top 19 beachfront luxury hotels on Mallorca",
        image:{ 
            src:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg',
            srcSet:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-300x159.jpg 300w'
        },
        url:{ attrHref: '#'},   
    },
    {
        text:"Best Boutique Hotels on Mallorca 2020",
        image:{ 
            src:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg',
            srcSet:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-300x159.jpg 300w'
        },
        url:{ attrHref: '#'},   
    }, 
    {
        text:"Luxury Med-style villa rental Sa Punta de S'Aguila",
        image:{ 
            src:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg',
            srcSet:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-300x159.jpg 300w'
        },
        url:{ attrHref: '#'},   
    },
    {
        text:"Deluxe Sa Terra Rotja villa with sea vistas",
        image:{ 
            src:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg',
            srcSet:'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-300x159.jpg 300w'
        },
        url:{ attrHref: '#'},   
    },  
];

const Example: React.FC = (props) => {
    return (
        <RelatedArticlesSlider
        title={'Recommended for you'}
        slides={slidesArticle}
        dots={true}
        autoplay={true}
        pauseOnHover={true}
        autoplaySpeed={4000}
        speed={300}
        initialSlide={0}
        infinite={true}
        className="example"
      />

      <RelatedArticlesSlider
      title={'Recommended for you with background'}
      slides={slidesArticle}
      dots={true}
      autoplay={false}
      pauseOnHover={true}
      autoplaySpeed={4000}
      speed={300}
      initialSlide={0}
      infinite={true}
      className="example-with-bg"
      isBackground={true}
    />
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>title</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Set the title.</td>
          </tr>
          <tr>
            <td>slides</td>
            <td className="w-100">array of objects. Object with props:<br />
              "text" : string,<br />
              <hr />
              "image": object with: "src": string, "srcSet": string, <br />
              <hr />
              "url": object with: "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
            </td>
            <td className="text-center">-</td>
            <td>Sets image, text and link for the slide.</td>
          </tr>
          <tr>
            <td>Some of ReactSlick props: </td>
            <td>Available options: dots, infinite, autoplay, autoplaySpeed, pauseOnHover, speed, initialSlide</td>
            <td><a href="https://react-slick.neostack.com/docs/api" target="_blank"
              rel="noopener noreferrer">Default values</a></td>
            <td>For ease of use, the props of HeroSlider component extend ReactSlick, but there
            are restrictions for some of these props due to implementation logic and design consistency.
            </td>
          </tr>
          <tr>
            <td>isBackground</td>
            <td className="text-center">boolean</td>
            <td className="text-center">false</td>
            <td>Sets the background.</td>
          </tr>
          <tr>
            <td>className</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML class to the wrapping element of the component.</td>
          </tr>
          <tr>
            <td>id</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML id to the wrapping element of the component.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default RelatedArticlesSliderPage;
