import React from 'react';
import Highlight from 'react-highlight';

import { CreditCard } from '@studiouxp/abc-mallorca-portal-ds';

const CreditCardPage: React.FC = () => {
  return (
    <div>
      <h1>Credit Card</h1>

      <CreditCard title="My Work Visa" description="• • • • 2012" logo="visa" isDefault isChecked />
      <CreditCard title="My Maestro" description="• • • • 3483" logo="maestro" isUnChecked />
      <CreditCard title="Union Pay" description="• • • • 1234" logo="unionPay" />

      <Highlight className="React">
        {
          `
import React from 'react';
import { CreditCard } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <div>
          <CreditCard title="My Work Visa" description="• • • • 2012" logo="visa" isDefault isChecked />
          <CreditCard title="My Maestro" description="• • • • 3483" logo="maestro" isUnChecked />
          <CreditCard title="Union Pay" description="• • • • 1234" logo="unionPay" />
        </div>
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>title</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set title.</td>
          </tr>
          <tr>
            <td>description</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set description.</td>
          </tr>
          <tr>
            <td>logo</td>
            <td>'visa' | 'visaElectron' | 'masterCard' | 'discover' | 'amex' | 'jcb' | 'dinersClub' | 'maestro' | 'laser' | 'unionPay' | 'elo' | 'hiperCard'</td>
            <td className='text-center'>-</td>
            <td>Set logo.</td>
          </tr>
          <tr>
            <td>isDefault</td>
            <td>boolean</td>
            <td className='text-center'>-</td>
            <td>Show the 'default' text.</td>
          </tr>
          <tr>
            <td>isChecked</td>
            <td>boolean</td>
            <td className='text-center'>-</td>
            <td>Show the checked styles.</td>
          </tr>
          <tr>
            <td>isUnChecked</td>
            <td>boolean</td>
            <td className='text-center'>-</td>
            <td>Show the unchecked styles.</td>
          </tr>
          <tr>
            <td>link</td>
            <td className="text-center">
              Object with props:<br />
              "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
            </td>
            <td className="text-center">-</td>
            <td>Set link.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CreditCardPage;
