import React from 'react';
import Highlight from 'react-highlight';

import { GoogleMapAddress } from '@studiouxp/abc-mallorca-portal-ds';

const GoogleMapAddressPage: React.FC = () => {
    return (
        <div>
            <h1>Google Map Address</h1>

            <GoogleMapAddress
                title="Street Map"
                map={{
                    googleMapHelperApiKey: "AIzaSyDrX2CaIvlrLocKo4jvtga0Z3wxRH_KMsQ",
                    defaultCenter: { lat: 39.568590, lng: 2.642447 },
                    defaultZoom: 15,
                    marker: { lat: 39.568590, lng: 2.642447 },
                }}
                address="C/ Caro, 16 , Palma de Mallorca"
                buttonText="Open in google maps"
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { GoogleMapAddress } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <GoogleMapAddress
            title="Street Map"
            map={{
                googleMapHelperApiKey: "/* YOUR KEY HERE */",
                defaultCenter: { lat: 39.568590, lng: 2.642447 },
                defaultZoom: 15,
                marker: { lat: 39.568590, lng: 2.642447 },
            }}
            address="C/ Caro, 16 , Palma de Mallorca"
            buttonText="Open in google maps"
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title for component.</td>
                    </tr>
                    <tr>
                        <td>map</td>
                        <td className="text-center">
                            Object with props: <br />
                            "googleMapHelperApiKey": string, "defaultCenter": object with "lat": number, "lng": number, "defaultZoom": number, "marker": object with "lat": number, "lng": number
                        </td>
                        <td className="text-center">-</td>
                        <td>Set map options and marker.</td>
                    </tr>
                    <tr>
                        <td>address</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the address text.</td>
                    </tr>
                    <tr>
                        <td>buttonText</td>
                        <td>string</td>
                        <td>"Open in google maps"</td>
                        <td>Set the text for button.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GoogleMapAddressPage;
