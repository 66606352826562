import React from 'react';

import {
    DisplayPost, Container, Row, Col, HeroSlider, Icon, ArticleSummary,
    RelatedArticlesSlider, ReactAux, FormControl, Select, Datepicker, Button,
    SearchWidget, Banner, YoutubeVideo, Autocomplete, WeatherWidget,
} from '@studiouxp/abc-mallorca-portal-ds';
const fixedTitle = "Restaurants open during winter 2020"
const mainSlider = [
    {
        text: fixedTitle,
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/isaan-restaurant-40.jpg' } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/la-vieja-restaurant-11.jpg' } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/fabiola-01.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/01/beatnik-restaurant-10.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/01/can-marques-restaurant-01.jpg" } },
    },
];

const relatedArticles = [
    {
        text: "Best spa and wellness hotels on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Top 19 beachfront luxury hotels on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Best Boutique Hotels on Mallorca 2020",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Luxury Med-style villa rental Sa Punta de S'Aguila",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Deluxe Sa Terra Rotja villa with sea vistas",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
];

const restaurantsOptions = [
    {
        value: 'all', label: 'All restaurants',
    },
    { value: 'casaAlvaro', label: 'Casa Alvaro' },
    {
        value: 'tokiaLima', label: 'De Tokio a Lima',
    },
    {
        value: 'purobeachPalma', label: 'Purobeach Palma', isDisabled: true,
    },
    { value: '49Steps', label: '49 Steps Bar&Grill' },
    { value: 'silver', label: 'Raw&Grill The Brasserie' },
    { value: 'ritzi', label: 'Ritzi Restaurant' },
    { value: 'saFulla', label: 'Sa Fulla' },
    { value: 'sunsetSushi', label: 'Sunset Sushi Lounge' },
    { value: 'tanGustito', label: 'Tan Gustito Tapas' },
];

const PageWithSearch: React.FC = () => {

    const restaurantsForm = <ReactAux>
        <Row>
            <Col lg="6">
                <FormControl htmlFor="restaurants" label="Restaurants" isRequired isValid>
                    <Autocomplete
                        options={restaurantsOptions}
                        placeholder="Select restaurant..."
                        noOptionsMessage="No restaurant found"
                        defaultValue={restaurantsOptions[0]}
                        onChange={(option: any) => console.log(option)}
                        onInputChange={(inputValue: any) => console.log(inputValue)}
                    />
                </FormControl>
            </Col>
            <Col lg="6">
                <FormControl htmlFor="date" label="Date" isRequired isValid>
                    <Datepicker id="date" format="" />
                </FormControl>
            </Col>
            <Col lg="6">
                <FormControl htmlFor="time" label="Time" isRequired isValid>
                    <Select
                        id="time"
                        options={[
                            { value: 1, text: '20:00 h' },
                            { value: 2, text: '20:30 h' },
                            { value: 3, text: '21:00 h' },
                        ]}
                    />
                </FormControl>
            </Col>
            <Col lg="6">
                <FormControl htmlFor="numberOfGuests" label="Number of guests" isRequired isValid>
                    <Select
                        id="numberOfGuests"
                        options={[
                            { value: 1, text: '2 people' },
                            { value: 2, text: '3 people' },
                            { value: 3, text: '4 people' },
                            { value: 4, text: '5 people' },
                        ]}
                    />
                </FormControl>
            </Col>
        </Row>
        <Button className="w-100">search</Button>
    </ReactAux>;
    return (
        <>
            <HeroSlider
                slides={mainSlider}
                dots={true}
                autoplay={false}
                pauseOnHover={true}
                autoplaySpeed={3000}
                speed={300}
                initialSlide={0}
                infinite={true}
            />

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <div className="mb-s">
                            <p className="small d-inline-flex align-items-center"><a href="/">Home</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex align-items-center"><a href="/">Level</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex align-items-center"><a href="/">Level</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex">Active page</p>
                        </div>
                        <ArticleSummary
                            title="Palma de Mallorca"
                            subTitle="Your ultimate guide to Mallorca's capital city. What to do, see, shop & love in Palma"
                            viewsCount="11 243"
                            date="01.03.2020"
                            tags={[
                                { title: 'nightlife' },
                                { title: 'cycling' },
                                { title: 'summer' },
                            ]}
                            bullets={[
                                { title: 'Your ultimate guide to Mallorca’s capital city' },
                                { title: 'A 10th century Muslim fortress rebuilt in the Gothic style by the Christians' },
                                { title: 'Discover Santa Catalina!' },
                            ]}
                        />
                        <p>Palma is an exciting Mediterranean capital which seduces thousands of visitors each year with its rich offering of art, culture and gastronomy. Its historic streets are home to top restaurants, enticing boutiques stores, contemporary galleries and bustling fresh food markets. Experience everything from thrilling local festivals in the beating heart of the city to stunning sunsets along its beautiful seaside promenade.</p>
                        
                        <WeatherWidget
                                title='Weather forecast: Palma De Mallorca'
                                className='abc-negative-gutter'
                                imgSrc='https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/calo-des-moro-beach01.jpg'
                                currentDegrees="14°"
                                currentWeatherIconType="sunny"
                                currentWeatherInfo={[
                                    'Clear',
                                    '65% humidity',
                                    'Wind: 2m/s W',
                                    'H 3 - L 21'
                                ]}
                                weeklyForecast={[
                                    {
                                        dayOfweek: 'TUE',
                                        degrees: '31°',
                                        iconType: 'sunny'
                                    },
                                    {
                                        dayOfweek: 'Wed',
                                        degrees: '21°',
                                        iconType: 'partlyCloudy'
                                    },
                                    {
                                        dayOfweek: 'THU',
                                        degrees: '33°',
                                        iconType: 'sunny'
                                    },
                                    {
                                        dayOfweek: 'FRI',
                                        degrees: '31°',
                                        iconType: 'cloudy'
                                    },
                                    {
                                        dayOfweek: 'SAT',
                                        degrees: '24°',
                                        iconType: 'rainy'
                                    },
                                    {
                                        dayOfweek: 'SUN',
                                        degrees: '34°',
                                        iconType: 'sunny'
                                    }
                                ]}
                            />

                        <DisplayPost
                            title="Best Restaurants in the Southwest"
                            link={{ attrHref: '#', onClicked: () => { } }}
                            description="Stay at one of the 14 unique rooms in this extraordinary adults-only hotel in Lloseta, Serra de Tramuntana. Enjoy the beautiful scenery, homemade food and relaxing spa. Book NOW!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-620x330_c.jpg 620w'
                            }}
                            className="abc-negative-gutter mb-m"
                        />
                        <p>Palma is an exciting Mediterranean capital which seduces thousands of visitors each year with its rich offering of art, culture and gastronomy. Its historic streets are home to top restaurants, enticing boutiques stores, contemporary galleries and bustling fresh food markets. Experience everything from thrilling local festivals in the beating heart of the city to stunning sunsets along its beautiful seaside promenade.</p>

                        <SearchWidget
                            buttons={[
                                { title: 'Restaurants', icon: 'restaurantsL', onCliked: () => { }, isActive: true },
                                { title: 'Hotels', icon: 'hotelsL', onCliked: () => { }, isActive: false },
                                { title: 'Activities', icon: 'activitiesL', onCliked: () => { }, isActive: false },
                                { title: 'Services', icon: 'servicesL', onCliked: () => { }, isActive: false },
                            ]}
                            className="abc-negative-gutter mb-l"
                        >
                            {restaurantsForm}
                        </SearchWidget>

                        <DisplayPost
                            title="Best Restaurants in the Southwest of Mallorca"
                            link={{ attrHref: '/docs' }}
                            description="Stay at one of the 14 unique rooms in this extraordinary adults-only hotel in Lloseta, Serra de Tramuntana. Enjoy the beautiful scenery, homemade food and relaxing spa. Book NOW!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-300x159.jpg 300w'
                            }}
                            categoryTitle="Property"
                            isMasterPost
                            buttonLink={{ title: 'book now', attrHref: '#', onClicked: () => alert('Is clicked!') }}
                            className="abc-negative-gutter mb-m"
                        />
                        <p>Palma is an exciting Mediterranean capital which seduces thousands of visitors each year with its rich offering of art, culture and gastronomy. Its historic streets are home to top restaurants, enticing boutiques stores, contemporary galleries and bustling fresh food markets. Experience everything from thrilling local festivals in the beating heart of the city to stunning sunsets along its beautiful seaside promenade.</p>

                    </Col>
                </Row>
            </Container>
            <RelatedArticlesSlider
                title={'You might also like'}
                slides={relatedArticles}
                dots={true}
                autoplay={true}
                pauseOnHover={true}
                autoplaySpeed={4000}
                speed={300}
                initialSlide={0}
                infinite={true}
                className="example"
            />
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <YoutubeVideo
                            title="Youtube Video Playlist"
                            videoUrl="https://www.youtube.com/embed/?autoplay=1&mute=1&list=PLt6goMH2SpPzXVKaoUKztlgVxBRvW2JWF&listType=playlist&loop=1&rel=0&iv_load_policy=3"
                        />
                        <Banner
                            className="abc-negative-gutter"
                            title="At your service"
                            url={{ attrHref: '/docs/banner', onClicked: () => alert('Is clicked!') }}
                            image={{
                                src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/blevins-franks-banner-2019-12.jpg"
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PageWithSearch
