import React from 'react';
import Highlight from 'react-highlight';

import { Input, Textarea, Select, RadioButton, Checkbox, FormControl, Toggle, Autocomplete } from '@studiouxp/abc-mallorca-portal-ds';

const colorOptions = [
    {
        value: 'ocean', label: 'Ocean',
    },
    {
        value: 'blue', label: 'Blue', isDisabled: true,
    },
    { value: 'purple', label: 'Purple' },
    {
        value: 'red', label: 'Red',
    },
    { value: 'orange', label: 'Orange' },
    { value: 'yellow', label: 'Yellow' },
    { value: 'green', label: 'Green' },
    { value: 'forest', label: 'Forest' },
    { value: 'slate', label: 'Slate' },
    { value: 'silver', label: 'Silver' },
];

const FormControlsPage: React.FC = () => {
    return (
        <div>
            <h1>Form Controls</h1>

            <h2>Description</h2>
            <p>Form control components extends the native form control elements.</p>

            <h3>Input</h3>

            <div className="mb-m">
                <Input className="mb-m" id="input1" type="text" name="name" />
                <Input className="mb-m" id="input2" type="text" disabled value="Example" />
            </div>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { Input } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Input id="input1" type="text" name="name" />
        <Input id="input2" type="text" disabled value="Example" />
    );
}

export default Example;
`
                }
            </Highlight>

            <h3>Textarea</h3>

            <div className="mb-m">
                <Textarea id="textarea1" className="mb-m" />
                <Textarea id="textarea2" disabled value="Example" />
            </div>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { Textarea } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Textarea id="textarea1" />
        <Textarea id="textarea2" disabled value="Example"/>
    );
}

export default Example;
`
                }
            </Highlight>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>All HTML input/textarea attributes are supported.</td>
                    </tr>
                </tbody>
            </table>

            <h3>Select</h3>

            <div className="mb-m">
                <Select className="mb-m"
                    id="select1"
                    options={[
                        { value: 'chocolate', text: 'Chocolate' },
                        { value: 'strawberry', text: 'Strawberry' },
                        { value: 'vanilla', text: 'Vanilla' }]}
                    placeholder="Select..."
                />
                <Select className="mb-m"
                    id="select1"
                    options={[
                        { value: 'chocolate', text: 'Chocolate' },
                        { value: 'strawberry', text: 'Strawberry' },
                        { value: 'vanilla', text: 'Vanilla' }]}
                    value={'vanilla'}
                    onChange={() => { }}
                />
                <Select className="mb-m" id="select3" placeholder="Select..." options={[]} disabled />
            </div>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { Select } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Select
            id="select1"
            options={[
                { value: 'chocolate', text: 'Chocolate' },
                { value: 'strawberry', text: 'Strawberry' },
                { value: 'vanilla', text: 'Vanilla' }]}
            placeholder="Select..."
        />
        <Select
            id="select2"
            options={[
                { value: 'chocolate', text: 'Chocolate' },
                { value: 'strawberry', text: 'Strawberry' },
                { value: 'vanilla', text: 'Vanilla' }]}
            value={'vanilla'}
        />
        <Select id="select3" placeholder="Select..." disabled />
    );
}

export default Example;
`
                }
            </Highlight>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>options</td>
                        <td className='text-center'>
                            array of objects.<br />
                            Object must have props:<br />
                            "value" : string or number,
                            "text": string</td>
                        <td className='text-center'>-</td>
                        <td>Set select value and options.</td>
                    </tr>
                    <tr>
                        <td>all native attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>All HTML select attributes are supported.</td>
                    </tr>
                </tbody>
            </table>

            <h3>Autocomplete</h3>
             <p>Utilizing <a rel="noopener noreferrer"  href="https://react-select.com/home" target="_blank" title="react-select-lib">React Select</a> library.</p>
            <Autocomplete
                options={colorOptions}
                placeholder="Select color..."
                noOptionsMessage="No options"
                defaultValue={colorOptions[0]}
                onChange={(option: any) => console.log(option)}
                onInputChange={(inputValue: any) => console.log(inputValue)}
            />

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { Autocomplete } from '@studiouxp/abc-mallorca-portal-ds';

const colorOptions = [
    {
      value: 'ocean', label: 'Ocean',
    },
    {
      value: 'blue', label: 'Blue', isDisabled: true,
    },
    { value: 'purple', label: 'Purple' },
    {
      value: 'red', label: 'Red',
    },
    { value: 'orange', label: 'Orange'},
    { value: 'yellow', label: 'Yellow'},
    { value: 'green', label: 'Green'},
    { value: 'forest', label: 'Forest'},
    { value: 'slate', label: 'Slate'},
    { value: 'silver', label: 'Silver'},
  ];

const Example: React.FC = (props) => {
    return (
        <Autocomplete 
            options={colorOptions} 
            placeholder="Select color..."
            noOptionsMessage="No options"
            defaultValue={colorOptions[0]}
            onChange={(option:any)=> console.log(option)}
            onInputChange={(inputValue:any) => console.log(inputValue) }
        />
    );
}

export default Example;
`
                }
            </Highlight>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>options</td>
                        <td>
                            Array of objects with props:<br/>
                            "value": string || number,<br/>
                            "label": string || number,<br/>
                            "isDisabled": boolean
                        </td>
                        <td className='text-center'>-</td>
                        <td>
                            Set the select options.
                        </td>
                    </tr>
                    <tr>
                        <td>defaultValue</td>
                        <td>
                            Objects with props:<br/>
                            "value": string || number,<br/>
                            "label": string || number,<br/>
                            "isDisabled": boolean
                        </td>
                        <td className='text-center'>-</td>
                        <td>
                            Set the selected option.
                        </td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>function</td>
                        <td className='text-center'>-</td>
                        <td>
                            You will receive as parameter selected option.
                        </td>
                    </tr>
                    <tr>
                        <td>onInputChange</td>
                        <td>function</td>
                        <td className='text-center'>-</td>
                        <td>
                            You will receive as parameter the value typed in the select.
                        </td>
                    </tr>
                    <tr>
                        <td>isSearchable</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>
                            You will be able to write in the select. The component will filter the results.
                        </td>
                    </tr>
                    <tr>
                        <td>isClearable</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>
                            Selected value can be cleared, from close icon.
                        </td>
                    </tr>
                    <tr>
                        <td>placeholder</td>
                        <td>string</td>
                        <td>empty string</td>
                        <td>
                            Set the placeholder of the select
                        </td>
                    </tr>
                    <tr>
                        <td>noOptionsMessage</td>
                        <td>string</td>
                        <td>empty string</td>
                        <td>
                            Set the message, when no value found on search.
                        </td>
                    </tr>
                    <tr>
                        <td>disabled</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>
                            Set disabled state to the select.
                        </td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>
                            Set name and render hidden HTML input with value equal to selected value.
                        </td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td >string</td>
                        <td className='text-center'>-</td>
                        <td>Sets HTML class to the wrapping element of the component.</td>
                    </tr>
                </tbody>
            </table>

            <h3>Radio button</h3>
            <div className="mb-m">
                <RadioButton name="agree" value="yes">Yes</RadioButton>
                <RadioButton name="agree" value="no">No</RadioButton>
                <RadioButton disabled name="disabled" value="notSure">Not sure</RadioButton>
                <RadioButton disabled checked name="disabled" value="Other">Other</RadioButton>
            </div>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { RadioButton } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <RadioButton name="agree" value="yes">Yes</RadioButton>
        <RadioButton name="agree" value="no">No</RadioButton>
        <RadioButton disabled name="disabled" value="notSure">Not sure</RadioButton>
        <RadioButton disabled checked name="disabled" value="Other">Other</RadioButton>
    );
}

export default Example;
`
                }
            </Highlight>

            <h3>Checkbox</h3>
            <div className="mb-m">
                <Checkbox name="dessert" value={'chocolate'} onChange={() => { }}> Chocolate </Checkbox>
                <Checkbox name="dessertSecond" value={'banana'} onChange={() => { }}> Banana </Checkbox>
                <Checkbox disabled name="dessertThird" value={'cake'} onChange={() => { }}> Cake </Checkbox>
                <Checkbox disabled checked name="dessertFourth" value={'pancake'} onChange={() => { }}> Pancake </Checkbox>
            </div>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { Checkbox } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Checkbox name="dessert" value={'chocolate'} onChange={()=>{}}> Chocolate </Checkbox>
        <Checkbox name="dessertSecond" value={'banana'} onChange={()=>{}}> Banana </Checkbox>
        <Checkbox disabled name="dessertThird" value={'cake'} onChange={()=>{}}> Cake </Checkbox>
        <Checkbox disabled checked name="dessertFourth" value={'pancake'} onChange={()=>{}}> Pancake </Checkbox>
    );
}

export default Example;
`
                }
            </Highlight>

            <h3>Toggle</h3>
            <div className="mb-m">
                <Toggle name="radioToogle" value={'radio'} onChange={() => {}}> Radio </Toggle>
                <Toggle disabled name="acToggle" value={'ac'} onChange={() => { }}> Air Conditioner </Toggle>
                <Toggle disabled checked name="doorToggle" value={'door'} onChange={() => { }}> Door </Toggle>
            </div>
            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { Toggle } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Toggle name="radioToogle" value={'radio'} onChange={() => {}}> Radio </Toggle>
        <Toggle disabled name="acToggle" value={'ac'} onChange={() => { }}> Air Conditioner </Toggle>
        <Toggle disabled checked name="doorToggle" value={'door'} onChange={() => { }}> Door </Toggle>
    );
}

export default Example;
`
                }
            </Highlight>
            
            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>All HTML input type radio/checkbox attributes are supported.</td>
                    </tr>
                </tbody>
            </table>

            <h3>Form Control</h3>

            <p>Wrapping your form controls in this component you can set label and validation message to them.</p>

            <div className="mb-m">
                <FormControl
                    htmlFor="demo-input1"
                    label="Name"
                    isRequired
                    isValid={false}
                    validationMessage="This field is required">
                    <Input id="demo-input1" type="text" name="name" />
                </FormControl>
                <FormControl
                    htmlFor="demo-input2"
                    label="City"
                    isRequired={false}
                    isValid
                    validationMessage="This field is not valid">
                    <Input id="demo-input2" type="text" name="name" />
                </FormControl>
                <FormControl
                    htmlFor="demo-checkbox1"
                    label="Checkboxes"
                    isRequired
                    isValid={false}
                    validationMessage="This field is required">
                    <Checkbox name="dessert" value={'chocolate'} onChange={() => { }}> Chocolate </Checkbox>
                    <Checkbox name="dessertSecond" value={'banana'} onChange={() => { }}> Banana </Checkbox>
                </FormControl>
            </div>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { FormControl, Input, Checkbox } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <div>
            <FormControl
                htmlFor="demo-input1"
                label="Name"
                isRequired
                isValid={false}
                validationMessage="This field is required">
                <Input id="demo-input1" type="text" name="name" />
            </FormControl>
            <FormControl
                htmlFor="demo-input2"
                label="City"
                isRequired={false}
                isValid
                validationMessage="This field is not valid">
                <Input id="demo-input2" type="text" name="name" />
            </FormControl>
            <FormControl
                htmlFor="demo-checkbox1"
                label="Checkboxes"
                isRequired
                isValid={false}
                validationMessage="This field is required">
                <Checkbox name="dessert" value={'chocolate'} onChange={() => { }}> Chocolate </Checkbox>
                <Checkbox name="dessertSecond" value={'banana'} onChange={() => { }}> Banana </Checkbox>
            </FormControl>
        </div>
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>htmlFor</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>The id of the element the label is bound to.</td>
                    </tr>
                    <tr>
                        <td>label</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets label title to the group.<br />
                            <strong>Notice</strong>: Always set value to this prop if you have inputs on same row, to have them with equal height.</td>
                    </tr>
                    <tr>
                        <td>isRequired</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Shows the asterisk symbol (*) after the label. <br/>
                        <strong>Notice</strong>: If the label is empty, the symbol will not be rendered.</td>
                    </tr>
                    <tr>
                        <td>isValid</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Show validation message text.</td>
                    </tr>
                    <tr>
                        <td>validationMessage</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set validation message text.</td>
                    </tr>
                </tbody>
            </table>
        </div >
    )
}

export default FormControlsPage;
