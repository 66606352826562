import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';

const ActionBarPage: React.FC = () => {
    return (
        <div>
            <h1>Action Bar</h1>
            <p>Example <Link to='/components'>here</Link></p>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { ActionBar } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <ActionBar 
            homeBtn={{
            title:"Home",
            onCliked:()=>console.log('home'),
            isActive:true
            }}
            feedBtn={{
                title:"Feed",
                onCliked:()=>console.log('feed'),
            }}
            collectionsBtn={{
                title:"Itineraries",
                onCliked:()=>console.log('itineraries'),
            }}
            profileBtn={{
                title:"Profile",
                onCliked:()=>console.log('profile'),
            }}
            actionBtn={{
                title:"Plan",
                titleClose:"Close",
                subActons:[
                    {
                        title: 'My Trip',
                        icon: 'myTripWhiteM',
                        onCliked: () => console.log('my trip'),
                    },
                    {
                        title: 'Hotel',
                        icon: 'hotelsWhiteM',
                        onCliked: () => console.log('hotel'),
                    },
                    {
                        title: 'Restaurant',
                        icon: 'restaurantsWhiteM',
                        onCliked: () => console.log('restaurant'),
                    },
                    {
                        title: 'Activity',
                        icon: 'activitiesWhiteM',
                        onCliked: () => console.log('activity'),
                    },
                    {
                        title: 'Service',
                        icon: 'servicesWhiteM',
                        onCliked: () => console.log('service'),
                    }
                ]
            }}
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>homeBtn,<br />feedBtn,<br />collectionsBtn,<br />profileBtn</td>
                        <td>Object with:"title": string, "isActive": boolean,"onClicked": Function</td>
                        <td className="text-center">-</td>
                        <td>Set action buttons.</td>
                    </tr>
                    <tr>
                        <td>actionBtn</td>
                        <td>Object with:<br />
                            "title": string,<br />
                            "titleClose": string, <br />
                            "subActons": Array of objects with: "title":string, "icon":string, "onClicked": Function
                         </td>
                        <td className="text-center">-</td>
                        <td>Set the "action" button and subactions.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td className="text-center">string</td>
                        <td className="text-center">-</td>
                        <td>Sets HTML class to the wrapping element of the component.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ActionBarPage;
