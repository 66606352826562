import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { ReactAux, Hero, BookingSteps, Container, Row, Col } from '@studiouxp/abc-mallorca-portal-ds';

import { Step1, Step2, Step3, Step4, Step5, } from './BookingSteps'

const Booking: React.FC = () => {

    const [currentStep, setCurrentStep] = useState(1);

    useEffect(() => {
        const pathStep = +window.location.pathname[window.location.pathname.length - 1];

        if (currentStep !== pathStep)
            setCurrentStep(+window.location.pathname[window.location.pathname.length - 1])
    }, [currentStep]);

    const history = useHistory();

    function onStepClick(step: any) {
        setCurrentStep(step);
        history.push(`/demo-pages/booking/step${step}`)
    }

    return (
        <ReactAux>
            <Hero img="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/04/mercado-del-olivar-32.jpg" title="Restaurant name" description="Additional description" />

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <Switch>
                            <Redirect exact from="/demo-pages/booking" to="/demo-pages/booking/step1" />
                            <Route path="/demo-pages/booking/step1" component={Step1} />
                            <Route path="/demo-pages/booking/step2" component={Step2} />
                            <Route path="/demo-pages/booking/step3" component={Step3} />
                            <Route path="/demo-pages/booking/step4" component={Step4} />
                            <Route path="/demo-pages/booking/step5" component={Step5} />
                        </Switch>
                    </Col>
                </Row>
            </Container>

            <BookingSteps
                nextStep={{ onClick: () => currentStep < 5 ? onStepClick(currentStep + 1) : history.push('/demo-pages/finish'), title: currentStep === 5 ? 'Finish' : undefined }}
                buttons={[
                    { title: 'People', icon: 'peopleL', onCliked: () => onStepClick(1), isActive: currentStep >= 1 },
                    { title: 'Date & Time', icon: 'dateAndTimeL', onCliked: () => onStepClick(2), isActive: currentStep >= 2 },
                    { title: 'Options', icon: 'optionsL', onCliked: () => onStepClick(3), isActive: currentStep >= 3 },
                    { title: 'Contacts', icon: 'contactInfoL', onCliked: () => onStepClick(4), isActive: currentStep >= 4 },
                    { title: 'Payment', icon: 'paymentL', onCliked: () => onStepClick(5), isActive: currentStep >= 5 },
                ]} />
        </ReactAux>
    )
}

export default Booking;
