import React from 'react';
import Highlight from 'react-highlight';

import { BusinessContacts } from '@studiouxp/abc-mallorca-portal-ds';

const BusinessContactsPage: React.FC = () => {
    return (
        <div>
            <h1>Business Contacts</h1>

            <BusinessContacts
              title="Business Contacts"
              аddress="La Rambla, 20, Palma"
              telephone="+34 871 045 174"
              website={{title:"tast.com", href:"https://www.tast.com"}}
              languages={["English", "Spanish", "German"]}
              />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { BusinessContacts } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <BusinessContacts
            title="Business Contacts"
            аddress="La Rambla, 20, Palma"
            telephone="+34 871 045 174"
            website= {{title:"tast.com" href: "https://www.tast.com"}}
            languages={["English", "Spanish", "German"]}
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>аddress</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the аddress.</td>
                    </tr>
                    <tr>
                        <td>telephone</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the telephone.</td>
                    </tr>
                    <tr>
                        <td>website</td>
                        <td>Objects with props: <br />
                        "title": string, "href": string</td>
                        <td className="text-center">-</td>
                        <td>Set the website.</td>
                    </tr>
                    <tr>
                        <td>languages</td>
                        <td>array of strings.</td>
                        <td className="text-center">-</td>
                        <td>Set the languages.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default BusinessContactsPage;
