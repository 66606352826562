import React from 'react';
import Highlight from 'react-highlight';

import { LinkBox } from '@studiouxp/abc-mallorca-portal-ds';

const LinkBoxPage: React.FC = () => {
  return (
    <div>
      <h1>Link Box</h1>

      <LinkBox title="Edit Profile" description="Edit your personal information." icon="profilePrimaryL" />
      <LinkBox title="Sin Embargo Art Galleria" description="15 Jun, 19:00 h | 5 people" icon="reservationPendingL" />
      <LinkBox title="Moda Bar" description="23 May, 20:30 h | 2 people" icon="reservationCompleterdL" />

      <Highlight className="React">
        {
          `
import React from 'react';
import { LinkBox } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <div>
          <LinkBox title="Edit Profile" description="Edit your personal information." icon="profilePrimaryL" />
          <LinkBox title="Sin Embargo Art Galleria" description="15 Jun, 19:00 h | 5 people" icon="reservationPendingL" />
          <LinkBox title="Moda Bar" description="23 May, 20:30 h | 2 people" icon="reservationCompleterdL" />
        </div>
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>title</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set title.</td>
          </tr>
          <tr>
            <td>description</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set description.</td>
          </tr>
          <tr>
            <td>icon</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set icon.</td>
          </tr>
          <tr>
            <td>link</td>
            <td className="text-center">
              Object with props:<br />
              "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
            </td>
            <td className="text-center">-</td>
            <td>Set link.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default LinkBoxPage;
