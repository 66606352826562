import React from 'react';

import { ReactAux, InfoBox, CreditCard, Button } from '@studiouxp/abc-mallorca-portal-ds';

import PaymentsForm from '../PaymentsForm';

class Step5 extends React.Component {

    state = {
        demo: false,
        cardsData: [
            {title: 'My Work Visa', description: '• • • • 2012', logo: 'visa', isDefault: true, isChecked: true },
            {title: 'My Maestro', description: '• • • • 3483', logo: 'maestro', },
            {title: 'My American Express', description: '• • • • 9676', logo: 'amex',},
        ]
    };

    onDefaultCardChange(index: any){
        const copyCards = [...this.state.cardsData];
        const checkedProps = {
            isChecked: true,
            isUnChecked: false
        }

       const updatedCards = copyCards.map((el) => {
           return {
              ...el,
              isChecked: !checkedProps.isChecked,
              isUnChecked: !checkedProps.isUnChecked,
           }
       });
       updatedCards[index] = {
            ...updatedCards[index],
            ...checkedProps
       }

       this.setState({
         cardsData: updatedCards
       });

    }

    render() {
        const { demo, cardsData } = this.state;

        return (
            <ReactAux>
                {/* This Button is only for demo */}
                <div className="text-center">
                    <Button
                        appearance="secondary"
                        onClick={() => this.setState({ demo: !demo })}
                    >
                        {demo ? 'Demo show payment form' : 'Demo show choose card'}
                    </Button>
                </div>

                <h2 className="mb-s">Payment</h2>
                <InfoBox className="mb-m" textSize="normal">
                    <strong>Deposit:</strong> 12 EURO
                </InfoBox>

                {demo && <PaymentsForm />}
                {!demo && <ReactAux>
                    <hr className="mb-l mt-l" />
                    {
                        cardsData.map((el, i)=>{
                            const index = i;
                            return <CreditCard 
                                        key={index}
                                        title={el.title}
                                        description={el.description}
                                        logo={el.logo as any}
                                        {...el.isDefault && {isDefault: true}}
                                        {...el.isChecked ? {isChecked: true} : { isUnChecked: true}}
                                        link={{ onClicked: ()=> this.onDefaultCardChange(index)}}
                                    />
                        })
                    }
                </ReactAux>
                }
            </ReactAux>
        )
    }
}

export default Step5;
