import React from 'react';

import {
    Container, Row, Col, FormControl,
    BackgroundWrapper, Toggle
} from '@studiouxp/abc-mallorca-portal-ds';

const SettingsPage: React.FC = () => {

    return (
        <>
            <BackgroundWrapper>
                <h1 className="mb-0">Settings</h1>
            </BackgroundWrapper>
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <form>
                            <Row>
                                <Col md="12">
                                    <FormControl
                                        htmlFor="location-toggle"
                                        className="mb-0"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Toggle name="location-toggle" value='location-toggle' onChange={() => { }}> Location </Toggle>
                                    </FormControl>
                                    <hr/>
                                    <FormControl
                                        htmlFor="newsletter"
                                        className="mb-0"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Toggle name="newsletter" value='newsletter' onChange={() => { }}> Newsletter </Toggle>
                                    </FormControl>
                                    <hr/>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SettingsPage;
