import React from 'react';
import Highlight from 'react-highlight';

import { Cookies, Button } from '@studiouxp/abc-mallorca-portal-ds';

class CookiesPage extends React.Component {

    state = {
        cookiesAgree: false
    }

    render() {
        return (
            <div>
                <h1>Cookies</h1>
                <div>
                    <Cookies isAgreed={this.state.cookiesAgree}>
                        <p>
                            The website is using cookies.
                          If you continue browsing the website you agree with the <a href="/">cookie policy</a>.
                      </p>
                        <Button className="w-100" onClick={() => this.setState({ cookiesAgree: !this.state.cookiesAgree })}>Accept</Button>
                    </Cookies>
                </div>

                <Highlight className="React">
                    {
                        `
    import React from 'react';
    import { Cookies, Button  } from '@studiouxp/abc-mallorca-portal-ds';
    
    class Example extends React.Component{
        state = {
            cookiesAgree: false
        }

        render(){
            return (
                <Cookies isAgreed={this.state.cookiesAgree}>
                    <p>
                        The website is using cookies.
                        If you continue browsing the website you agree with the <a href="/">cookie policy</a>.
                    </p>
                    <Button className="w-100" onClick={() => this.setState({ cookiesAgree: !this.state.cookiesAgree })}>Accept</Button>
                </Cookies>
            );
        }
    }
    
    export default Example;
        `}
                </Highlight>

                <table className="doc-table">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>isAgreed</td>
                            <td>boolean</td>
                            <td className="text-center">-</td>
                            <td>Set is Cookies component visible.</td>
                        </tr>
                        <tr>
                            <td>className</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Set class on cookies container.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

export default CookiesPage;
