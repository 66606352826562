import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { 
    MyProfile, EditProfile, Notifications, Settings, 
    Reservations, ManageReservation, Cards, EditCard
} from './MyProfilePages';
import { profileRoutes } from '../helpers/constants';

const MyProfileRoutes: React.FC = () => {
    return (
        <Switch>
            <Route path={profileRoutes.editProfile} component={EditProfile} />
            <Route path={profileRoutes.notifications} component={Notifications} />
            <Route path={profileRoutes.settings} component={Settings} />
            <Route path={profileRoutes.manageReservation} component={ManageReservation} />
            <Route path={profileRoutes.reservations} component={Reservations} />
            <Route path={profileRoutes.editCard} component={EditCard} />
            <Route path={profileRoutes.cards} component={Cards} />
            <Route path={profileRoutes.myProfile} component={MyProfile} />
        </Switch>
    );
}

export default MyProfileRoutes;
