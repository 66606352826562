import React from 'react';
import Highlight from 'react-highlight';

import { HeroSlider } from '@studiouxp/abc-mallorca-portal-ds';

const slides = [
  { text: "Example video", videoCode: '0p-vXwu0xZk?rel=0&enablejsapi=1&autoplay=1&mute=1' },
  {
    text: "Example 1",
    image: {
      imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_platos-56.jpg' },
      imageLink: { attrHref: '#' }
    },
  },
  {
    text: "Example 2",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/el-pesquero-restaurant-exterior-955x505_c.jpg' } },
  },
  {
    text: "Example 3",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/SALMON-copia.jpg' } },
  },
  {
    text: "Example 4",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_interior_25-955x505_c.jpg' } },
  },
  {
    text: "Example 5",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/el-pesquero-restaurant-exterior-evening.jpg' } },
  },
  {
    text: "Example 6",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_platos-33.jpg' } }
  }
];

const HeroSliderPage: React.FC = () => {
  return (
    <div>
      <h1>Hero Slider</h1>

      <h2>Description</h2>
      <p className="mb-m">Utilizing <a href="https://react-slick.neostack.com/docs/get-started" target="_blank" rel="noopener noreferrer">React Slick
            </a> library.</p>

      <h3>Default</h3>

      <HeroSlider
        slides={slides}
        dots={true}
        autoplay={true}
        pauseOnHover={true}
        autoplaySpeed={4000}
        speed={300}
        initialSlide={0}
        infinite={true}
        className="example"
      />

      <h3>No text slider</h3>
      <HeroSlider
        slides={slides}
        dots={true}
        autoplay={true}
        pauseOnHover={true}
        autoplaySpeed={4000}
        speed={300}
        initialSlide={0}
        infinite={true}
        className="example"
        noTextSlides={true}
      />


      <h3>Slider with action button</h3>
      <HeroSlider
        slides={slides}
        dots={true}
        autoplay={true}
        pauseOnHover={true}
        autoplaySpeed={4000}
        speed={300}
        initialSlide={0}
        infinite={true}
        className="example"
        slidesAction={{
          title: 'Thinking about a holiday home in Mallorca?',
          subTitle: 'Investment in holiday homes soars in Mallorca thanks to its climate, safety and accessibility.',
          actionLink:{
            title: 'Contact us',
            onClicked: () => console.log('example click'),
          }
        }}
      />

      <Highlight className="React">
        {
          `
import React from 'react';
import { HeroSlider } from '@studiouxp/abc-mallorca-portal-ds';

const slides = [
  { text: "Example video", videoCode: '0p-vXwu0xZk?rel=0&enablejsapi=1&autoplay=1&mute=1' },
  {
    text: "Example 1",
    image: {
      imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_platos-56.jpg' },
      imageLink: { attrHref: '#' }
    },
  },
  {
    text: "Example 2",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/el-pesquero-restaurant-exterior-955x505_c.jpg' } },
  },
  {
    text: "Example 3",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/SALMON-copia.jpg' } },
  },
  {
    text: "Example 4",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_interior_25-955x505_c.jpg' } },
  },
  {
    text: "Example 5",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/el-pesquero-restaurant-exterior-evening.jpg' } },
  },
  {
    text: "Example 6",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_platos-33.jpg' } }
  }
];

const Example: React.FC = (props) => {
    return (
        <HeroSlider 
            slides={slides}
            dots={true}
            autoplay={true}
            pauseOnHover={true}
            autoplaySpeed={4000}
            speed={300}
            initialSlide={0}
            infinite={true}
            className="example"
            // Use when all of the slides doesn't have text.
            noTextSlides={true}
            // Use when want to have text and action btn on the slides
            slidesAction={{
              title: 'Thinking about a holiday home in Mallorca?',
              subTitle: 'Investment in holiday homes soars in Mallorca thanks to its climate, safety and accessibility.',
              actionLink:{
                title: 'Contact us',
                onClicked: () => console.log('example click'),
              }
            }}
        />
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>slides</td>
            <td className="w-100">array of objects. Object with props:<br />
              "text" : string,<br />
              <hr />
              "image": object with: "imagePath": object with: "src": string, "srcSet": string ,"imageLink": object with: "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}<br />
              <hr />
              "videoCode": string
            </td>
            <td className="text-center">-</td>
            <td>Sets image or video slides.</td>
          </tr>
          <tr>
            <td>Some of ReactSlick props: </td>
            <td>Available options: dots, infinite, autoplay, autoplaySpeed, pauseOnHover, speed, initialSlide</td>
            <td><a href="https://react-slick.neostack.com/docs/api" target="_blank"
              rel="noopener noreferrer">Default values</a></td>
            <td>For ease of use, the props of HeroSlider component extend ReactSlick, but there
            are restrictions for some of these props due to implementation logic and design consistency.
            </td>
          </tr>
          <tr>
            <td>slidesAction</td>
            <td className="w-100">array of objects. Object with props:<br />
              "title" : string,<br />
              <hr />
              "subTitle": string<br />
              <hr />
              "actionLink": object with: "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}<br />
            </td>
            <td className="text-center">-</td>
            <td>Sets title,subtitle and action settings on slides.</td>
          </tr>
          <tr>
            <td>noTextSlides</td>
            <td className="text-center">boolean</td>
            <td className="text-center">-</td>
            <td>Sets styles and HTML of the component when all slides are without text.</td>
          </tr>
          <tr>
            <td>className</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML class to the wrapping element of the component.</td>
          </tr>
          <tr>
            <td>id</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML id to the wrapping element of the component.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default HeroSliderPage;
