import React from 'react';
import Highlight from 'react-highlight';

import { ReactAux } from '@studiouxp/abc-mallorca-portal-ds';
import { Quote } from '@studiouxp/abc-mallorca-portal-ds';

const QuotePage: React.FC = () => {
    return (
        <div>
            <h1>Quote</h1>

            <Quote
                text="“Many people struggle to find inner peace, so I can up with a tailormade program to get you into the best physical and mental state of your lifetime!”"
                subText="~ Jane Doe"
            />

            <Quote
                text={<ReactAux>"Many people struggle to find <strong>inner peace</strong>, so I can up with a tailormade program to get you into the best physical and mental state of your lifetime!"</ReactAux>}
                subText="~ Jane Doe"
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Quote } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Quote
            text="“Many people struggle to find inner peace, so I can up with a tailormade program to get you into the best physical and mental state of your lifetime!”"
            subText="~ Jane Doe"
        />
        
        <Quote
            text={<ReactAux>"Many people struggle to find <strong>inner peace</strong>, so I can up with a tailormade program to get you into the best physical and mental state of your lifetime!"</ReactAux>}
            subText="~ Jane Doe"
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>text</td>
                        <td>string or JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set the text.</td>
                    </tr>
                    <tr>
                        <td>subText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the subText.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default QuotePage;
