import React from 'react';

import {
    Row, Col, FormControl,
    CardNumberInput, CardExpirationDateInput, CVVInput, CardHolderInput
} from '@studiouxp/abc-mallorca-portal-ds';

interface PaymentsFormState {
    cardNumber: string,
    isCardNumberValid: boolean,
    isCardNumberDirty: boolean,
    cardHolder: string,
    isCardHolderValid: boolean,
    isCardHolderDirty: boolean,
    cardExpiration: string,
    isCardExpirationValid: boolean,
    isCardExpirationDirty: boolean,
    cvv: string,
    isCVVValid: boolean,
    isCVVDirty: boolean,
    cardProvider: string
}

class PaymentsForm extends React.Component<any, PaymentsFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            cardNumber: '',
            isCardNumberValid: true,
            isCardNumberDirty: false,
            cardHolder: '',
            isCardHolderValid: true,
            isCardHolderDirty: false,
            cardExpiration: '',
            isCardExpirationValid: true,
            isCardExpirationDirty: false,
            cvv: '',
            isCVVValid: true,
            isCVVDirty: false,
            cardProvider: ''
        }
    }

    onInputChange(event: any) {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        if (targetName === 'cardNumber') {
            this.setState({ cardNumber: targetValue });
        } else if (targetName === 'cardHolder') {
            this.setState({ cardHolder: targetValue });
        } else if (targetName === 'cardExpiration') {
            this.setState({ cardExpiration: targetValue });
        } else if (targetName === 'cvv') {
            this.setState({ cvv: targetValue });
        }
    }

    blurControl(event: any) {
        let controlDirtyStateName: 'isCardNumberDirty' | 'isCardHolderDirty' | 'isCardExpirationDirty' | 'isCVVDirty';
        switch (event.target.name) {
            case 'cardNumber': controlDirtyStateName = 'isCardNumberDirty';
                break;
            case 'cardHolder': controlDirtyStateName = 'isCardHolderDirty';
                break;
            case 'cardExpiration': controlDirtyStateName = 'isCardExpirationDirty';
                break;
            case 'cvv': controlDirtyStateName = 'isCVVDirty';
                break;
            default:
                return;
        }
        const isDirty = this.state[controlDirtyStateName];
        if (isDirty === false) {
            const updatedState = { ...this.state };
            updatedState[controlDirtyStateName] = true;
            this.setState(updatedState);
        }
    }

    updateCardNumberValidity(isValid: boolean) {
        const { isCardNumberValid } = this.state;
        if (isCardNumberValid !== isValid) {
            this.setState({ isCardNumberValid: isValid });
        }
    }

    updateCardProvider(newProvider: string) {
        const { cardProvider } = this.state;
        if (newProvider !== cardProvider) {
            this.setState({ cardProvider: newProvider });
        }
    }

    updateCardHolderValidity(isHolderValid: boolean) {
        const { isCardHolderValid } = this.state;
        if (isHolderValid !== isCardHolderValid) {
            this.setState({ isCardHolderValid: isHolderValid });
        }
    }

    updateCardExpDateValidity(isExpired: boolean) {
        const { isCardExpirationValid } = this.state;
        if (isCardExpirationValid !== isExpired) {
            this.setState({ isCardExpirationValid: isExpired });
        }
    }

    updateCVVValidity(isValid: boolean) {
        const { isCVVValid } = this.state;
        if (isCVVValid !== isValid) {
            this.setState({ isCVVValid: isValid });
        }
    }

    render() {
        const {
            cardNumber, isCardNumberValid, isCardNumberDirty,
            cardHolder, isCardHolderValid, isCardHolderDirty,
            cardExpiration, isCardExpirationValid, isCardExpirationDirty,
            cvv, isCVVValid, isCVVDirty,
            cardProvider
        } = this.state;

        return (
            <Row>
                <Col sm="12">
                    <FormControl
                        htmlFor="card-number"
                        label="Card number"
                        isRequired
                        isValid={!isCardNumberDirty || isCardNumberValid}
                        validationMessage={'Invalid card number.'}>
                        <CardNumberInput
                            id="card-number"
                            name="cardNumber"
                            value={cardNumber}
                            onBlur={e => this.blurControl(e)}
                            onChange={e => this.onInputChange(e)}
                            onCardValidityChange={(isValid: boolean) => this.updateCardNumberValidity(isValid)}
                            onCardProviderChange={(provider: string) => this.updateCardProvider(provider)}
                        />
                    </FormControl>
                </Col>
                <Col sm="12">
                    <FormControl
                        htmlFor="card-holder"
                        label="Card holder name"
                        isRequired
                        isValid={!isCardHolderDirty || isCardHolderValid}
                        validationMessage="Invalid card holder.">
                        <CardHolderInput
                            id="card-holder"
                            type="text"
                            name="cardHolder"
                            value={cardHolder}
                            onBlur={e => this.blurControl(e)}
                            onChange={e => this.onInputChange(e)}
                            onCardHolderValidityChange={(isHolderValid: boolean) => this.updateCardHolderValidity(isHolderValid)}
                        />
                    </FormControl>
                </Col>
                <Col md="6" sm="12">
                    <FormControl
                        htmlFor="card-expiration"
                        label="Expiration date"
                        isRequired
                        isValid={!isCardExpirationDirty || isCardExpirationValid}
                        validationMessage="Invalid expiration date.">
                        <CardExpirationDateInput
                            id="card-expiration"
                            name="cardExpiration"
                            value={cardExpiration}
                            onBlur={e => this.blurControl(e)}
                            onChange={e => this.onInputChange(e)}
                            onExpDateValidityChange={(isExpired: boolean) => this.updateCardExpDateValidity(isExpired)}
                        />
                    </FormControl>
                </Col>
                <Col md="6" sm="12">
                    <FormControl
                        htmlFor="card-cvv"
                        label="CVV"
                        isRequired
                        isValid={!isCVVDirty || isCVVValid}
                        validationMessage="Invalid CVV.">
                        <CVVInput
                            id="card-cvv"
                            name="cvv"
                            value={cvv}
                            onBlur={e => this.blurControl(e)}
                            onChange={e => this.onInputChange(e)}
                            cardProvider={cardProvider}
                            onCVVValidityChange={(isValid: boolean) => this.updateCVVValidity(isValid)}
                        />
                    </FormControl>
                </Col>
            </Row>
        );
    }
}

export default PaymentsForm;
