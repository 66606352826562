import React from 'react';
import Highlight from 'react-highlight';

import { Service } from '@studiouxp/abc-mallorca-portal-ds';

const ServicePage: React.FC = () => {
    return (
        <div>
            <h1>Service</h1>
            <h2>Note</h2>
            <p>
            The height of component depends on image proportions. Make sure you use images with equal proportions.
            </p>

            <Service
                image={{
                    src: "https://www.abc-mallorca.es/wp-content/uploads/2018/09/Hotel.jpg"
                }}
                title="Luxury hotel on Mallorca"
                description={{
                    text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
                    btnMoreText: "Read more",
                    btnLessText: "See less"
                }}
                footer={{
                    icon: 'priceSecondaryS',
                    text: 'Price: 9 to 12 euro'
                }}
                className='abc-negative-gutter'
            />

            <Service
                image={{
                    src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-430x230.jpg"
                }}
                title="Top Mallorca Hotel 2020"
                description={{
                    text: "There are many variations of passages of <strong>Lorem Ipsum</strong> available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
                    btnMoreText: "Read more",
                    btnLessText: "See less"
                }}
                footer={{
                    icon: 'priceSecondaryS',
                    text: 'Price: 9 to 12 euro'
                }}
                className='abc-negative-gutter'
                link={{
                    attrHref: "#"
                }}
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Service } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Service
            image={{
                src: "https://www.abc-mallorca.es/wp-content/uploads/2018/09/Hotel.jpg"
            }}
            title="Luxury hotel on Mallorca"
            description={{
            text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
            btnMoreText:"Read more",
            btnLessText:"See less"
            }}
            footer={{
                icon: 'priceSecondaryS',
                text: 'Price: 9 to 12 euro'
            }}
            className='abc-negative-gutter'

            // set link to title and img
            link={{
                attrHref: "#"
            }}
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td className="text-center">string</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td className="text-center">
                            Object with props:<br />
                            "text": string, "btnMoreText": string, "btnLessText": string,
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the description.</td>
                    </tr>
                    <tr>
                        <td>footer</td>
                        <td className="text-center">
                            Object with props:<br />
                            "icon": string
                            "text" string
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the footer icon and text.</td>
                    </tr>
                    <tr>
                        <td>image</td>
                        <td className="text-center">
                            Object with props:<br />
                            "src": string, "srcSet": string,
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the image.</td>
                    </tr>
                    <tr>
                        <td>link</td>
                        <td className="text-center">
                            Object with props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the link to image and title.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ServicePage;
