import React, { useState } from 'react';

import {
    Container, Row, Col, Button, CreditCard
} from '@studiouxp/abc-mallorca-portal-ds';
import { profileRoutes } from '../../helpers/constants';

const CardsPage: React.FC = () => {
    const [noCardContent, setNoCardContent] = useState(false);
    return (
        <Container>
            <div className="text-center mt-l">
                <Button
                    appearance="secondary"
                    onClick={() => setNoCardContent(!noCardContent)}
                >
                    {noCardContent ?"SHOW DEMO WITH CARDS" : "SHOW DEMO WITH NO CARDS"}
                </Button>
            </div>
            <Row className='mt-l'>
                <Col lg={{ size: 8, offset: 2 }}>
                    {!noCardContent &&
                        <> 
                            <CreditCard 
                               title="My Work Visa" 
                               description="• • • • 2012" 
                               logo="visa" 
                               isDefault
                               link={{ attrHref: profileRoutes.editCard, attrTitle: 'Edit Card' }}
                               />
                            <CreditCard 
                               title="My Maestro"
                               description="• • • • 3483"
                               logo="maestro"
                               link={{ attrHref: profileRoutes.editCard, attrTitle: 'Edit Card' }}
                            />
                            <CreditCard
                               title="My American Express"
                               description="• • • • 1234"
                               logo='amex'
                               link={{ attrHref: profileRoutes.editCard, attrTitle: 'Edit Card' }}
                            />
                        </>
                      }
                    {noCardContent && <p className="mt-l mb-l text-center text-dark">No card added.</p>}
                    <Button 
                       type="button"
                       className={`w-100 mb-l ${noCardContent ? 'mt-l' : 'mt-s'}`}
                    >
                        {noCardContent ?"Add a card" : "Add another card"}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default CardsPage;
