import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';


const BookingStepsPage: React.FC = () => {

    return (
        <div>
            <h1>Booking Steps</h1>

            <p>Example <Link to='/demo-pages/booking'>here</Link></p>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { BookingSteps } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {

    const [currentStep, setCurrentStep] = useState(1);

    return (
        <BookingSteps
            nextStep={{ onClick: () => setCurrentStep(currentStep + 1) }}
            buttons={[
                { title: 'People', icon: 'peopleL', onCliked: () => setCurrentStep(1), isActive: currentStep >= 1 },
                { title: 'Date & Time', icon: 'dateAndTimeL', onCliked: () => setCurrentStep(2), isActive: currentStep >= 2 },
                { title: 'Options', icon: 'optionsL', onCliked: () => setCurrentStep(3), isActive: currentStep >= 3 },
                { title: 'Contacts', icon: 'contactInfoL', onCliked: () => setCurrentStep(4), isActive: currentStep >= 4 },
                { title: 'Payment', icon: 'paymentL', onCliked: () => setCurrentStep(5), isActive: currentStep >= 5 },
            ]} />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>nextStep</td>
                        <td>Object with props: <br />
                        "onClick": void, "title": string</td>
                        <td className="text-center">-</td>
                        <td>Set the title and click event for button.</td>
                    </tr>
                    <tr>
                        <td>buttons</td>
                        <td>array of objects with props: <br />
                        "title": string, "icon": string, "onCliked": void, "isActive": boolean
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the buttons.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default BookingStepsPage;
