import React from 'react';

import {
    Container, Row, Col, FormControl,
    BackgroundWrapper, Toggle
} from '@studiouxp/abc-mallorca-portal-ds';

const NotificationPage: React.FC = () => {

    return (
        <>
            <BackgroundWrapper>
                <h1 className="mb-0">Notifications</h1>
            </BackgroundWrapper>
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <form>
                            <Row>
                                <Col md="12">
                                    <FormControl
                                        htmlFor="e-mail-toggle"
                                        className="mb-0"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Toggle name="e-mail-toggle" value='e-mail-toggle' onChange={() => { }}> E-mail </Toggle>
                                    </FormControl>
                                    <hr/>
                                    <FormControl
                                        htmlFor="push-notifications"
                                        className="mb-0"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Toggle name="push-notifications" value='push-notifications' onChange={() => { }}> Push notifications </Toggle>
                                    </FormControl>
                                    <hr/>
                                    <FormControl
                                        htmlFor="sms"
                                        className="mb-0"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Toggle name="sms" value='location' onChange={() => { }}> SMS </Toggle>
                                    </FormControl>
                                    <hr/>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NotificationPage;
