import React from 'react';
import Highlight from 'react-highlight';


const PaymentFormControlsPage: React.FC = () => {

    return (
        <div>
            <h1>Payment Form Controls</h1>

            <h2>Description</h2>
            <p>A set of form controls which handle payment-related input, mainly - credit card data.</p>
            <p><strong>Important:</strong> All of the controls require binding with parent component
            state, so that their <i>"value"</i> property gets updated on change.</p>

            <h3>CardNumberInput</h3>
            <p>
                A specific input for credit card numbers. Validates internally the typed/passed value,
                resulting in two independent validity indicators: which is the card provider (among a predefined set
                of supported providers) and is the card number itself valid.
            </p>
            <p>
                It is crucial to bind the value of CardNumberInput with the state of its parent component,
                because this will allow card number mask to be added in the input box for better UX. There is
                an important point here - the value of the component is not exactly what gets showcased in the input box.
                For example, the value of the state object can be <i>4242424242424242</i> (a valid card number format),
                while the input box will show <i>4242-4242-4242-4242</i>.
            </p>
            <p>Supported providers:</p>
            <ul>
                <li>Visa</li>
                <li>Visa Electron</li>
                <li>Mastercard</li>
                <li>Discover</li>
                <li>American Express</li>
                <li>JCB</li>
                <li>Diners Club</li>
                <li>Maestro</li>
                <li>Laser</li>
                <li>UnionPay</li>
                <li>Elo</li>
                <li>Hipercard</li>
            </ul>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { CardNumberInput } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <CardNumberInput
            id="card-number"
            value={...}
            onChange={...} 
            onCardValidityChange={(isValid: boolean) => ...}
            onCardProviderChange={(provider: string) => ...}
        />
    );
}

export default Example;
`
                }
            </Highlight>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>onCardValidityChange</td>
                        <td>{'(isValid: boolean) => void'}</td>
                        <td className='text-center'>-</td>
                        <td>Emits an event when there is a change in the validity of the input value. Indicates
                        whether the given card number is valid according to the Luhn algorithm.
                        </td>
                    </tr>
                    <tr>
                        <td>onCardProviderChange</td>
                        <td>{'(cardProvider: string) => void'}</td>
                        <td className='text-center'>-</td>
                        <td>Emits an event when there is a change in the validity of the input value and the
                        card provider pattern is recognized.
                        </td>
                    </tr>
                    <tr>
                        <td>all native attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>All HTML input attributes are supported.</td>
                    </tr>
                </tbody>
            </table>

            <h3>CardHolderInput</h3>
            <p>
                An input for card holder name. Accepts only letters, spaces and dashes. Transforms
                the typed letters to upper case.
            </p>
            <p>
                It is crucial to bind the value of CardHolderInput with the state of its parent component,
                because this will transform the passed in value to upper case.
            </p>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { CardHolderInput } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <CardHolderInput
            id="card-holder"
            value={...}
            onChange={...}
            onCardHolderValidityChange={(isHolderValid: boolean) => ...}
        />
    );
}

export default Example;
`
                }
            </Highlight>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>onCardHolderValidityChange</td>
                        <td>{'(isValid: boolean) => void'}</td>
                        <td className='text-center'>-</td>
                        <td>Emits an event when there is a change in the validity of the input. Indicates
                        whether the given card holder name is correct - contains only letters, spaces and dashes.
                        </td>
                    </tr>
                    <tr>
                        <td>all native attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>All HTML input attributes are supported.</td>
                    </tr>
                </tbody>
            </table>

            <h3>CardExpirationDateInput</h3>
            <p>
                Handles credit card expiration date. Applies specific formatting and validates whether the
                expiration date is not passed.
            </p>
            <p>
                It is crucial to bind the value of CardExpirationDateInput with the state of its parent component,
                because this will add a mask the to the typed value. Values with incorrect format are not accepted.
                The actual value of the component is in the specific card expiration date format: <i>MM/YY</i>.
            </p>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { CardExpirationDateInput } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <CardExpirationDateInput
            id="card-expiration"
            value={...}
            onChange={...}
            onExpDateValidityChange={(isExpired: boolean) => ...}
        />
    );
}

export default Example;
`
                }
            </Highlight>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>onExpDateValidityChange</td>
                        <td>{'(isExpired: boolean) => void'}</td>
                        <td className='text-center'>-</td>
                        <td>Emits an event when there is a change in the validity of the input. Indicates
                        the date has already passed, thus making it invalid as expiration date of a credit  card.
                        </td>
                    </tr>
                    <tr>
                        <td>all native attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>All HTML input attributes are supported.</td>
                    </tr>
                </tbody>
            </table>

            <h3>CVVInput</h3>
            <p>
                An input for card verification value - CVV. According to the passed in card provider (if there is such),
                the CVVInput can validate whether the passed in CVV is compliant with the given provider.
            </p>
            <p>
                It is crucial to bind the value of CVVInput with the state of its parent component,
                because this will restrict the length of the CVV number, not allowing it to grow more than 4 digits.
            </p>

            <Highlight className="React mb-m">
                {
                    `
import React from 'react';
import { CVVInput } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <CVVInput
            id="card-cvv"
            value={cvv}
            onChange={e => ...}
            cardProvider={...}
            onCVVValidityChange={(isValid: boolean) => ...}
        />
    );
}

export default Example;
`
                }
            </Highlight>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>cardProvider</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>The name of the credit card provider, according to which the CVV validation can be done.</td>
                    </tr>
                    <tr>
                        <td>onCVVValidityChange</td>
                        <td>{'(isValid: boolean) => void'}</td>
                        <td className='text-center'>-</td>
                        <td>Emits an event when there is a change in the validity of the input. Indicates
                        whether the CVV is compliant with the card provider, if it is known.
                        </td>
                    </tr>
                    <tr>
                        <td>all native attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>All HTML input attributes are supported.</td>
                    </tr>
                </tbody>
            </table>

            <h3>Exampe Payments Form</h3>

            <p>A full example of a payment form can be seen <a href="/demo-pages/payments-form" target="_blank">here</a>.</p>

            <p>Source:</p>


            <Highlight className="React mb-m">
                {
                    `
import React from 'react';

import {
    ReactAux, Container, Row, Col, FormControl,
    CardNumberInput, CardExpirationDateInput, CVVInput, CardHolderInput
} from '@studiouxp/abc-mallorca-portal-ds';

interface PaymentsFormState {
    cardNumber: string,
    isCardNumberValid: boolean,
    isCardNumberDirty: boolean,
    cardHolder: string,
    isCardHolderValid: boolean,
    isCardHolderDirty: boolean,
    cardExpiration: string,
    isCardExpirationValid: boolean,
    isCardExpirationDirty: boolean,
    cvv: string,
    isCVVValid: boolean,
    isCVVDirty: boolean,
    cardProvider: string
}

class PaymentsForm extends React.Component<any, PaymentsFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            cardNumber: '',
            isCardNumberValid: true,
            isCardNumberDirty: false,
            cardHolder: '',
            isCardHolderValid: true,
            isCardHolderDirty: false,
            cardExpiration: '',
            isCardExpirationValid: true,
            isCardExpirationDirty: false,
            cvv: '',
            isCVVValid: true,
            isCVVDirty: false,
            cardProvider: ''
        }
    }

    onInputChange(event: any) {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        if (targetName === 'cardNumber') {
            this.setState({ cardNumber: targetValue });
        } else if (targetName === 'cardHolder') {
            this.setState({ cardHolder: targetValue });
        } else if (targetName === 'cardExpiration') {
            this.setState({ cardExpiration: targetValue });
        } else if (targetName === 'cvv') {
            this.setState({ cvv: targetValue });
        }
    }

    blurControl(event: any) {
        let controlDirtyStateName: 'isCardNumberDirty' | 'isCardHolderDirty' | 'isCardExpirationDirty' | 'isCVVDirty';
        switch (event.target.name) {
            case 'cardNumber': controlDirtyStateName = 'isCardNumberDirty';
                break;
            case 'cardHolder': controlDirtyStateName = 'isCardHolderDirty';
                break;
            case 'cardExpiration': controlDirtyStateName = 'isCardExpirationDirty';
                break;
            case 'cvv': controlDirtyStateName = 'isCVVDirty';
                break;
            default:
                return;
        }
        const isDirty = this.state[controlDirtyStateName];
        if (isDirty === false) {
            const updatedState = { ...this.state };
            updatedState[controlDirtyStateName] = true;
            this.setState(updatedState);
        }
    }

    updateCardNumberValidity(isValid: boolean) {
        const { isCardNumberValid } = this.state;
        if (isCardNumberValid !== isValid) {
            this.setState({ isCardNumberValid: isValid });
        }
    }

    updateCardProvider(newProvider: string) {
        const { cardProvider } = this.state;
        if (newProvider !== cardProvider) {
            this.setState({ cardProvider: newProvider });
        }
    }

    updateCardHolderValidity(isHolderValid: boolean) {
        const { isCardHolderValid } = this.state;
        if (isHolderValid !== isCardHolderValid) {
            this.setState({ isCardHolderValid: isHolderValid });
        }
    }

    updateCardExpDateValidity(isExpired: boolean) {
        const { isCardExpirationValid } = this.state;
        if (isCardExpirationValid !== isExpired) {
            this.setState({ isCardExpirationValid: isExpired });
        }
    }

    updateCVVValidity(isValid: boolean) {
        const { isCVVValid } = this.state;
        if (isCVVValid !== isValid) {
            this.setState({ isCVVValid: isValid });
        }
    }

    render() {
        const {
            cardNumber, isCardNumberValid, isCardNumberDirty,
            cardHolder, isCardHolderValid, isCardHolderDirty,
            cardExpiration, isCardExpirationValid, isCardExpirationDirty,
            cvv, isCVVValid, isCVVDirty,
            cardProvider
        } = this.state;

        return (
            <Row>
                <Col lg={{ size: 8, offset: 2 }}>
                    <Row>
                        <Col sm={{ size: 12 }}>
                            <FormControl
                                htmlFor="card-number"
                                label="Card number"
                                isRequired
                                isValid={!isCardNumberDirty || isCardNumberValid}
                                validationMessage={'Invalid card number.'}>
                                <CardNumberInput
                                    id="card-number"
                                    name="cardNumber"
                                    value={cardNumber}
                                    onBlur={e => this.blurControl(e)}
                                    onChange={e => this.onInputChange(e)}
                                    onCardValidityChange={(isValid: boolean) => this.updateCardNumberValidity(isValid)}
                                    onCardProviderChange={(provider: string) => this.updateCardProvider(provider)}
                                />
                            </FormControl>
                        </Col>
                        <Col sm={{ size: 12 }}>
                            <FormControl
                                htmlFor="card-holder"
                                label="Card holder name"
                                isRequired
                                isValid={!isCardHolderDirty || isCardHolderValid}
                                validationMessage="Invalid card holder.">
                                <CardHolderInput
                                    id="card-holder"
                                    type="text"
                                    name="cardHolder"
                                    value={cardHolder}
                                    onBlur={e => this.blurControl(e)}
                                    onChange={e => this.onInputChange(e)}
                                    onCardHolderValidityChange={(isHolderValid: boolean) => this.updateCardHolderValidity(isHolderValid)}
                                />
                            </FormControl>
                        </Col>
                        <Col sm={{ size: 6 }}>
                            <FormControl
                                htmlFor="card-expiration"
                                label="Expiration date"
                                isRequired
                                isValid={!isCardExpirationDirty || isCardExpirationValid}
                                validationMessage="Invalid expiration date.">
                                <CardExpirationDateInput
                                    id="card-expiration"
                                    name="cardExpiration"
                                    value={cardExpiration}
                                    onBlur={e => this.blurControl(e)}
                                    onChange={e => this.onInputChange(e)}
                                    onExpDateValidityChange={(isExpired: boolean) => this.updateCardExpDateValidity(isExpired)}
                                />
                            </FormControl>
                        </Col>
                        <Col sm={{ size: 6 }}>
                            <FormControl
                                htmlFor="card-cvv"
                                label="CVV"
                                isRequired
                                isValid={!isCVVDirty || isCVVValid}
                                validationMessage="Invalid CVV.">
                                <CVVInput
                                    id="card-cvv"
                                    name="cvv"
                                    value={cvv}
                                    onBlur={e => this.blurControl(e)}
                                    onChange={e => this.onInputChange(e)}
                                    cardProvider={cardProvider}
                                    onCVVValidityChange={(isValid: boolean) => this.updateCVVValidity(isValid)}
                                />
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default PaymentsForm;
                    
    `}
            </Highlight>
        </div>
    )
}

export default PaymentFormControlsPage;
