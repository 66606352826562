import React from 'react';
import Highlight from 'react-highlight';

import { FAQ } from '@studiouxp/abc-mallorca-portal-ds';

const FAQPage: React.FC = () => {
    return (
        <div>
            <h1>FAQ component</h1>

            <FAQ title="FAQ" rows={[
                { title: 'Do I need to have a certain skill level before I start training?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
                { title: 'Can I have a gym buddy?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
                { title: 'Do I need to rent equipment to train?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
            ]}
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { FAQ } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <FAQ title="FAQ" rows={[
            { title: 'Do I need to have a certain skill level before I start training?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
            { title: 'Can I have a gym buddy?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
            { title: 'Do I need to rent equipment to train?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
        ]}
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>

                    <tr>
                        <td>rows</td>
                        <td>
                            Array of objects with props: <hr />
                            "title": string <hr />
                            "text": string or JSX.Element
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the rows title and content.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets HTML class to the wrapping element of the component.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FAQPage;
