import React from 'react';

import {
    ReactAux, HeroSlider, Container, Row, Col, Icon, ArticleSummary, Banner, DisplayPost,
    OpeningHours, BusinessContacts, GoogleMapAddress, Socials, RelatedArticlesSlider,
    WidgetWrapper, YoutubeVideo, WeatherWidget,
} from '@studiouxp/abc-mallorca-portal-ds';

const slides = [
    {
        videoCode: 'IztclG9_1Bk?rel=0&enablejsapi=1&autoplay=1&mute=1'
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img03.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img04.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img02.jpg' }
        },
    }
];

const slidesArticle = [
    {
        text: "Saranna Cafe & Gastrobar",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/05/Sarrana-img04-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/05/Sarrana-img04-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/05/Sarrana-img04-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Delicious cuisine at Saranna Café & Gastrobar",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/04/Sarrana-img26-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/04/Sarrana-img26-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/04/Sarrana-img26-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Evenings at Saranna Café & Gastobar",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/05/saranna-cocktails-img01-430x230.jpg',
        },
        url: { attrHref: '#' },
    },
]

const slidesMustRead = [
    {
        text: "Palma de Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/02/palma-de-mallorca-catedral-80x80.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/02/palma-de-mallorca-catedral-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/02/palma-de-mallorca-catedral-150x150.jpg 150w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Your Ultimate Guide to Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/08/ultimate-guide-mallorca-80x80.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/08/ultimate-guide-mallorca-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/08/ultimate-guide-mallorca-150x150.jpg 150w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Living in Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/04/living-mallorca-img01-80x80.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/04/living-mallorca-img01-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/04/living-mallorca-img01-150x150.jpg 150w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Best Restaurants on Mallorca 2020",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-80x80.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-150x150.jpg 150w'
        },
        url: { attrHref: '#' },
    },
]

const SaranaCafe: React.FC = () => {
    return (
        <ReactAux>
            <HeroSlider
                slides={slides}
                dots={true}
                autoplay={true}
                pauseOnHover={true}
                autoplaySpeed={4000}
                speed={300}
                initialSlide={0}
                noTextSlides={true}
            />

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <div className="mb-s">
                            <p className="small d-inline-flex align-items-center"><a href="/">Home</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex align-items-center"><a href="/">Restaurants</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex align-items-center"><a href="/">Restaurants News</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex">Foodie heaven at Saranna Café & Gastrobar</p>
                        </div>

                        <ArticleSummary
                            title="Foodie heaven at Saranna Café & Gastrobar"
                            subTitle="Securing that top spot for the best burger in Palma!"
                            viewsCount="11 243"
                            date="01.03.2020"
                            tags={[
                                { title: 'gastrobar' },
                                { title: 'tapas' },
                                { title: 'foodie' },
                            ]}
                            bullets={[
                                { title: 'Located between Santa Catalina and Paseo Mallorca' },
                                { title: 'Home-made marmalade on toast' },
                                { title: 'Selection of freshly-made tapas and winner burgers' },
                            ]}
                        />

                        <p>When hunger strikes and you’ re pacing the cobbled streets of <strong>Palma</strong> unsure of where to go, only one place should come to mind. <strong>Saranna Café & Gastrobar</strong>. Located between <strong>Santa Catalina</strong> and <strong>Paseo Mallorca</strong>, this humble Mediterranean eatery offers everything from artisan breads to tapas to juicy burgers – a one-to-remember foodie gem in the heart of Palma.</p>

                        <WidgetWrapper className="abc-negative-gutter">
                            <img src="https://i.ibb.co/d7NVz80/Screenshot-2020-03-06-at-9-32-46.png" alt="widget" />
                        </WidgetWrapper>

                        <p>Owner <strong>Hamlet Ramirez</strong> – with his 25 years of restaurant experience – has applied his culinary magic to Saranna where good vibes and healthy cuisine come together. Hungry passers-by will find it difficult on a Saturday morning to walk past their <strong>freshly-baked artisan breads</strong> and <strong>pastries</strong> and not be tempted in for breakfast. <strong>Home-made marmalade on toast</strong> or a <strong>creamy plate of eggs benedict</strong> certainly hit that weekend spot – made more delicious by a cup of fresh coffee.</p>
                        <p>When the sun goes down, the kitchen at Saranna continues to deliver – this time with its selection of <strong>freshly-made tapas</strong> and <strong>winner burgers</strong>. The competition might be high between restaurants fighting for that top burger spot in Palma but Saranna is changing the game. Focused entirely on fresh, organic produce, burger-lovers will appreciate the love and care gone into each layer – from juicy caramelised onions and grilled cheese to toasted sesame bun. Unmissable.</p>
                        <p>The warm brightly-lit café by day turns into a romantic candle-lit restaurant by night. Order a glass or two from their <strong>selection of local wines</strong>, or for that more party spirit, a <strong>fruity cocktail</strong> is a good choice before a night-out. Whatever you fancy, Saranna is sure to satisfy your appetite… so what is it going to be? Breakfast? Burgers? Or everything in between? Hamlet and his team are ready for you!</p>
                        <WeatherWidget
                                title='Weather forecast: Palma De Mallorca'
                                className='abc-negative-gutter'
                                currentDegrees="14°"
                                currentWeatherIconType="sunny"
                                currentWeatherInfo={[
                                    'Clear',
                                    '65% humidity',
                                    'Wind: 2m/s W',
                                    'H 3 - L 21'
                                ]}
                                weeklyForecast={[
                                    {
                                        dayOfweek: 'TUE',
                                        degrees: '31°',
                                        iconType: 'sunny'
                                    },
                                    {
                                        dayOfweek: 'Wed',
                                        degrees: '21°',
                                        iconType: 'partlyCloudy'
                                    },
                                    {
                                        dayOfweek: 'THU',
                                        degrees: '33°',
                                        iconType: 'sunny'
                                    },
                                    {
                                        dayOfweek: 'FRI',
                                        degrees: '31°',
                                        iconType: 'cloudy'
                                    },
                                    {
                                        dayOfweek: 'SAT',
                                        degrees: '24°',
                                        iconType: 'rainy'
                                    },
                                    {
                                        dayOfweek: 'SUN',
                                        degrees: '34°',
                                        iconType: 'sunny'
                                    }
                                ]}
                            />

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Restaurants in Mallorca"
                            link={{ attrHref: '/' }}
                            description="Get to know the best restaurants on Mallorca, by region or type. Gourmet trends, hot recommendations and the most talented chefs – it’s all here!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/12/mallorca-restaurants-main-001.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/12/mallorca-restaurants-main-001.jpg 416w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/12/mallorca-restaurants-main-001-300x160.jpg 300w'
                            }}
                            categoryTitle="More Restaurants on Mallorca" />

                        <p>We hope you have found this article about the <strong>Foodie heaven at Saranna Café &amp; Gastrobar</strong> useful and that you have many memorable stays in Mallorca as a result of our recommendations.</p>
                        <p>We are always pleased to hear from you, so do send us your <a href="/">feedback here</a>.</p>
                        <p><strong>Bon Profit</strong> as the locals say!</p>

                        <OpeningHours
                            className="abc-negative-gutter"
                            title="Opening Hours"
                            note="Open all year."
                            rows={[
                                { title: 'Monday', hours: ['08:30 - 18:00 h'] },
                                { title: 'Tuesday', hours: ['08:30 - 18:00 h'] },
                                { title: 'Wednesday', hours: ['08:30 - 18:00 h'] },
                                { title: 'Thursday', hours: ['08:30 - 23:00 h'] },
                                { title: 'Friday', hours: ['08:30 - 23:00 h'] },
                                { title: 'Saturday', hours: ['08:30 - 23:00 h'] },
                                { title: 'Sunday', hours: ['08:30 - 18:00 h'] },
                            ]} />

                        <BusinessContacts
                            className="abc-negative-gutter"
                            title="Saranna Cafe & Gastrobar"
                            аddress="Calle Forn de l’Olivera, 4, Palma"
                            telephone="+34 971 536 432"
                            website={{ title: "sarannacafe.com", href: "https://www.sarannacafe.com" }}
                            languages={['Spanish', 'Polish', 'Portuguese', 'English', 'German']}
                        />

                        <GoogleMapAddress
                            className="abc-negative-gutter"
                            title="Street Map"
                            map={{
                                googleMapHelperApiKey: "AIzaSyDrX2CaIvlrLocKo4jvtga0Z3wxRH_KMsQ",
                                defaultCenter: { lat: 39.570570, lng: 2.642870 },
                                defaultZoom: 15,
                                marker: { lat: 39.570570, lng: 2.642870 },
                            }}
                            address="Calle Forn de l’Olivera, 4, Palma"
                            buttonText="Open in google maps"
                        />

                        <Socials
                            className="abc-negative-gutter"
                            language="en"
                            facebookLink={{ attrHref: '#' }}
                            instagramLink={{ attrHref: '#' }}
                            twitterLink={{ attrHref: '#' }}
                            pinterestLink={{ attrHref: '#' }}
                        />


                    </Col>
                </Row>
            </Container>

            <RelatedArticlesSlider
                title="You might also like"
                slides={slidesArticle}
                dots={true}
            />

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <Banner
                            className="mb-l abc-negative-gutter"
                            title="Read our magazine"
                            url={{ onClicked: () => alert('Magazine!') }}
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/magazine-online_whatson_2020_EN.jpg',
                            }}
                            buttonText="read now"
                        />
                        <Banner
                            className="mb-l abc-negative-gutter"
                            title="Videos"
                            url={{ onClicked: () => alert('Magazine!') }}
                            video={<YoutubeVideo
                                videoUrl="https://www.youtube.com/embed/6qErv1uzC-U" />}
                        />
                        <Banner
                            className="mb-l abc-negative-gutter"
                            title="At your service"
                            url={{ onClicked: () => alert('At your service!') }}
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/knox-design-banner-2020.gif',
                            }}
                        />
                        <Banner
                            className="mb-l abc-negative-gutter"
                            title=""
                            url={{ onClicked: () => alert('At your service 2!') }}
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/blevins-franks-banner-2019-12.jpg',
                            }}
                        />
                    </Col>
                </Row>
            </Container>

            <RelatedArticlesSlider
                title="You might also like"
                slides={slidesMustRead}
                dots={true}
            />

        </ReactAux>
    );
}

export default SaranaCafe;
