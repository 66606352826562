import React from 'react';
import Highlight from 'react-highlight';

import { Magazine } from '@studiouxp/abc-mallorca-portal-ds';

const magazinesList = [
    {
        image: { src: 'https://i.ibb.co/pZTSh5P/Screenshot-2020-10-20-at-11-12-20.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/1rvJ5xv/Screenshot-2020-10-20-at-11-12-35.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/8BQJV89/Screenshot-2020-10-20-at-11-12-47.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/hFLdLbb/Screenshot-2020-10-20-at-13-20-54.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.postimg.cc/ZYj95CKW/logooo.jpg' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        buttonText: 'buy now!',
    },
    {
        image: { src: 'https://i.ibb.co/2cWWcPM/Screenshot-2020-10-20-at-13-21-06.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
];

const MagazinePage: React.FC = () => {
    return (
        <div>
            <h1>Magazine</h1>

            <p><strong>Important:</strong> All images need to be same width and height.</p>

            <Magazine magazines={magazinesList} />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Magazine } from '@studiouxp/abc-mallorca-portal-ds';

const magazinesList = [
    {
        image: { src: 'https://i.ibb.co/pZTSh5P/Screenshot-2020-10-20-at-11-12-20.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/1rvJ5xv/Screenshot-2020-10-20-at-11-12-35.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/8BQJV89/Screenshot-2020-10-20-at-11-12-47.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/hFLdLbb/Screenshot-2020-10-20-at-13-20-54.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.postimg.cc/ZYj95CKW/logooo.jpg' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        buttonText: 'buy now!',
    },
    {
        image: { src: 'https://i.ibb.co/2cWWcPM/Screenshot-2020-10-20-at-13-21-06.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
];

const Example: React.FC = (props) => {
    return (
        <Magazine magazines={magazinesList} />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>magazines</td>
                        <td>
                            Array of objects with props: <hr />
                            "image": object with props: "src": string, "srcSet": string,<hr />
                            "url": object with props : "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}<hr />
                            "buttonText": string
                        </td>
                        <td className="text-center">-</td>
                        <td>Set magazine image and url. To show box with button give buttonText.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MagazinePage;
