import React from 'react';
import Highlight from 'react-highlight';

import { FilterWrapper, FormControl, Select, Input, Button } from '@studiouxp/abc-mallorca-portal-ds';

const exampleOptions = [
    { value: 'chocolate', text: 'Chocolate' },
    { value: 'strawberry', text: 'Strawberry' },
    { value: 'vanilla', text: 'Vanilla' }];

const FilterWrapperPage: React.FC = () => {
    return (
        <div>
            <h1>Filter Wrapper</h1>
            <h2>Description</h2>
            <p>
                Filter Wrapper wraps your form with form controls and help to align it.<br/>
                <strong>Aways add HTML form element and then FormControl components in it</strong>.
            </p>

             <h3>Default</h3>
             <p>
                FormControl components will be on one row. They will take auto width to fit and fill the container.<br/>
                Make sure content looks fine and is readable on screen width 1024px and above. If it isn't you should config it like next topic - Wrap.
             </p>

            <FilterWrapper >
                <form >
                    <FormControl
                        htmlFor="regionsDef"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Select
                            id="regionsDef"
                            options={exampleOptions}
                            placeholder="All regions"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="cuisinesDef"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Select
                            id="cuisinesDef"
                            options={exampleOptions}
                            placeholder="All Cuisines"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="categoriesDef"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Select
                            id="categoriesDef"
                            options={exampleOptions}
                            placeholder="All categories"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="keywordDef"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Input id="keywordDef" type="text" name="keywordDef" placeholder="Keyword" />
                    </FormControl>
                    <Button >search</Button>
                </form>
            </FilterWrapper>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { FilterWrapper } from '@studiouxp/abc-mallorca-portal-ds';

const exampleOptions = [
    { value: 'chocolate', text: 'Chocolate' },
    { value: 'strawberry', text: 'Strawberry' },
    { value: 'vanilla', text: 'Vanilla' }];

const Example: React.FC = (props) => {
    return (
            <FilterWrapper >
                <form >
                    <FormControl
                        htmlFor="regions"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Select
                            id="regions"
                            options={exampleOptions}
                            placeholder="All regions"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="cuisines"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Select
                            id="cuisines"
                            options={exampleOptions}
                            placeholder="All Cuisines"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="categories"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Select
                            id="categories"
                            options={exampleOptions}
                            placeholder="All categories"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="keyword"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Input id="keyword" type="text" name="keyword" placeholder="Keyword" />
                    </FormControl>
                    <Button >search</Button>
                </form>
            </FilterWrapper>
    );
}

export default Example;
    `}
            </Highlight>

            <h3>Wrap</h3>
            <p> Add class <strong>"wrap"</strong> to HTML form element.
             It defines FormControl components to be flowed into multiple lines.<br/>
             You can also choose after which form controls to go on new line and set proportions for them (by default they have equal width).<br/>
             - new line: Use empty HTML element div with class "break"({`<div className="break" />`}).<br/>
             - proportions: Use utility classes "abc-flex-0" to "abc-flex-10" to FormControl element.
            </p>

            <FilterWrapper >
                <form 
                className="wrap"
                >
                    <FormControl
                        htmlFor="regionsWrap"
                        isValid
                        validationMessage="This field is not valid"
                        className="abc-flex-3"
                    >
                        <Select
                            id="regionsWrap"
                            options={exampleOptions}
                            placeholder="All regions"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="cuisinesWrap"
                        isValid
                        validationMessage="This field is not valid"
                        className="abc-flex-5"
                    >
                        <Select
                            id="cuisinesWrap"
                            options={exampleOptions}
                            placeholder="All Cuisines"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="categoriesWrap"
                        isValid
                        validationMessage="This field is not valid"
                        className="abc-flex-10"
                    >
                        <Select
                            id="categoriesWrap"
                            options={exampleOptions}
                            placeholder="All categories"
                        />
                    </FormControl>
                    <div className="break"/>
                    <FormControl
                        htmlFor="keywordWrap"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Input id="keywordWrap" type="text" name="keywordWrap" placeholder="Keyword" />
                    </FormControl>
                    <FormControl
                        htmlFor="keywordSecondWrap"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Input id="keywordSecondWrap" type="text" name="keywordSecondWrap" placeholder="Keyword 2" />
                    </FormControl>
                    <Button >search</Button>
                </form>
            </FilterWrapper>

<Highlight className="React">
                {
                    `
import React from 'react';
import { FilterWrapper } from '@studiouxp/abc-mallorca-portal-ds';

const exampleOptions = [
    { value: 'chocolate', text: 'Chocolate' },
    { value: 'strawberry', text: 'Strawberry' },
    { value: 'vanilla', text: 'Vanilla' }];

const Example: React.FC = (props) => {
    return (
        <FilterWrapper >
        <form 
        className="wrap"
        >
            <FormControl
                htmlFor="regions"
                isValid
                validationMessage="This field is not valid"
                className="abc-flex-3"
            >
                <Select
                    id="regions"
                    options={exampleOptions}
                    placeholder="All regions"
                />
            </FormControl>
            <FormControl
                htmlFor="cuisines"
                isValid
                validationMessage="This field is not valid"
                className="abc-flex-5"
            >
                <Select
                    id="cuisines"
                    options={exampleOptions}
                    placeholder="All Cuisines"
                />
            </FormControl>
            <FormControl
                htmlFor="categories"
                isValid
                validationMessage="This field is not valid"
                className="abc-flex-10"
            >
                <Select
                    id="categories"
                    options={exampleOptions}
                    placeholder="All categories"
                />
            </FormControl>
            <div className="break"/>
            <FormControl
                htmlFor="keyword"
                isValid
                validationMessage="This field is not valid"
            >
                <Input id="keyword" type="text" name="keyword" placeholder="Keyword" />
            </FormControl>
            <FormControl
                htmlFor="keywordSecond"
                isValid
                validationMessage="This field is not valid"
            >
                <Input id="keyword" type="text" name="keywordSecond" placeholder="Keyword 2" />
            </FormControl>
            <Button >search</Button>
        </form>
    </FilterWrapper>
    );
}

export default Example;
    `}
</Highlight>

            <h3>No grow</h3>
            <p> Add class <strong>"no-grow"</strong> to HTML form element.
             It defines FormControl components to be flowed , centered and set min-width according to the design.<br/>
             Use this aproach when you have form controls that can be fitted on one row. Make sure content looks fine and is readable on screen width 1024px and above.
            </p>
            <FilterWrapper >
                <form 
                className="no-grow"
                >
                    <FormControl
                        htmlFor="regionsNoGrow"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Select
                            id="regionsNoGrow"
                            options={exampleOptions}
                            placeholder="All regions"
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="keywordNoGrow"
                        isValid
                        validationMessage="This field is not valid"
                    >
                        <Input id="keywordNoGrow" type="text" name="keywordNoGrow" placeholder="Keyword" />
                    </FormControl>
                    <Button >search</Button>
                </form>
            </FilterWrapper>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>native div attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>All HTML div attributes are supported.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FilterWrapperPage;
