import React from 'react';
import Highlight from 'react-highlight';

import { SelectCards } from '@studiouxp/abc-mallorca-portal-ds';

const selectCards = [
    {
        title: "Pasta of the day - 12.99 Euro",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/Sandro-22-1.jpg',
            srcSet: ''
        },
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        descLessBtnText: "See less",
        descMoreBtnText: "Read more",
        isSelected: true,
        checkBoxTitle: "Select",
        footer :{
            icon: 'priceSecondaryS',
            text: 'Price: 9 to 12 euro'
        }
    },
    {
        title: "Burger - 9.90 Euro",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01.jpg',
            srcSet: ''
        },
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        descLessBtnText: "See less",
        descMoreBtnText: "Read more",
        isSelected: false,
        checkBoxTitle: "Select"
    }
];



class SelectCardsPage extends React.Component{
     
    state = {
        selectCards:selectCards
    }

    onToggleSlectCards = (index: any) =>{
        const copySelectCards = [...this.state.selectCards];
        copySelectCards[index].isSelected = !copySelectCards[index].isSelected
        this.setState({
            selectCards: copySelectCards
        })
    }

    render(){
        return (
            <div>
                <h1>Select Cards</h1>

                <SelectCards  cards={selectCards} onSelect={this.onToggleSlectCards} categoryTitle="Menu" />
    
                <Highlight className="React">
                    {
                        `
    import React from 'react';
    import { SelectCards } from '@studiouxp/abc-mallorca-portal-ds';
    
    const selectCards = [
        {
            title: "Pasta of the day - 12.99 Euro",
            image: {
                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/Sandro-22-1.jpg',
                srcSet: ''
            },
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            descLessBtnText: "See less",
            descMoreBtnText: "Read more",
            isSelected: true,
            checkBoxTitle: "Select",
            footer :{
                icon: 'priceSecondaryS',
                text: 'Price: 9 to 12 euro'
            }
        },
        {
            title: "Burger - 9.90 Euro",
            image: {
                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01.jpg',
                srcSet: ''
            },
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            descLessBtnText: "See less",
            descMoreBtnText: "Read more",
            isSelected: false,
            checkBoxTitle: "Select"
        }
    ];

    class Example extends React.Component{
        state = {
            selectCards:selectCards
        }
    
        onToggleSlectCards = (index: any) =>{
            const copySelectCards = [...this.state.selectCards];
            copySelectCards[index].isSelected = !copySelectCards[index].isSelected
            this.setState({
                selectCards: copySelectCards
            })
        }

        render(){
            return (
                <SelectCards  
                 cards={selectCards} 
                 onSelect={this.onToggleSlectCards} 
                 categoryTitle="Menu" 
                /> 
            );
        }
    }
    
    export default Example;
        `}
                </Highlight>
    
                <table className="doc-table">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>categoryTitle</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Set the title for select cards group.</td>
                        </tr>
                        <tr>
                            <td>cards</td>
                            <td>
                                Array of objects with props:<br/>
                                "title":string,<br/>
                                "description":string,<br/>
                                "descLessBtnText": string,<br/>
                                "descMoreBtnText: string,<br/>
                                "image": object with props "src" and "srcSet",<br/>
                                "isSelected": boolean,<br/>
                                "checkBoxTitle": string<br/>
                                "footer": objects with props :   icon: string, text: string,
                            </td>
                            <td className="text-center">-</td>
                            <td>Set cards.</td>
                        </tr>
                        <tr>
                            <td>onSelect</td>
                            <td>Function</td>
                            <td className="text-center">-</td>
                            <td>Set every card on click. You will receive the index of the card from the array as parameter.</td>
                        </tr>

                        <tr>
                            <td>className</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Set class to cards wrapper.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

export default SelectCardsPage;
