import React, { useState } from 'react';

import {
     Container, Row, Col, HeroSlider, ArticleSummary,
    RelatedArticlesSlider, ReactAux, FormControl, Select, Datepicker, Button, Autocomplete, Quote, Service, InfoBox,
    TestimonialsSlider, BusinessContacts, GoogleMapAddress, OpeningHours, FAQ,Socials,Textarea,Input
} from '@studiouxp/abc-mallorca-portal-ds';
const fixedTitle = "Jane Doe Personal Trainer"
const mainSlider = [
    {
        text: fixedTitle,
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/almond-blossom-field-mallorca.jpg' } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/almond-blossom-mallorca-16.jpg' } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/01/mallorca-preservation-foundation-01.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/01/ecocirer-soller-mallorca.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/02/palma-de-mallorca-catedral.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/javier-james-27-955x505_c.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/javier-james-2-955x505_c.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/javier-james-16-955x505_c.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/javier-james-16-955x505_c.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/javier-james-6-955x505_c.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/javier-james-13-955x505_c.jpg" } },
    },
    {
        text: fixedTitle,
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2021/02/javier-james-31-955x505_c.jpg" } },
    },
];

const relatedArticles = [
    {
        text: "Best spa and wellness hotels on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Top 19 beachfront luxury hotels on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Best Boutique Hotels on Mallorca 2020",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Luxury Med-style villa rental Sa Punta de S'Aguila",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Deluxe Sa Terra Rotja villa with sea vistas",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
];

const restaurantsOptions = [
    {
        value: 'all', label: 'All restaurants',
    },
    { value: 'casaAlvaro', label: 'Casa Alvaro' },
    {
        value: 'tokiaLima', label: 'De Tokio a Lima',
    },
    {
        value: 'purobeachPalma', label: 'Purobeach Palma', isDisabled: true,
    },
    { value: '49Steps', label: '49 Steps Bar&Grill' },
    { value: 'silver', label: 'Raw&Grill The Brasserie' },
    { value: 'ritzi', label: 'Ritzi Restaurant' },
    { value: 'saFulla', label: 'Sa Fulla' },
    { value: 'sunsetSushi', label: 'Sunset Sushi Lounge' },
    { value: 'tanGustito', label: 'Tan Gustito Tapas' },
];


const testimonialsArticle = [
    {
        title: "Andrea Pussin",
        subTitle: "Organic Studio",
        text: "One of the key factor of the success in securing new clients for Organic Studio from abcMallorca was them being able to provide high quality, perfectly styled images of projects completed which could be featured online and in the magazine. One of the key factor of the success in securing new clients for Organic Studio from abcMallorca was them being able to provide high quality, perfectly styled images of projects completed which could be featured online and in the magazine.",
        image: {
            src: 'https://images.unsplash.com/photo-1551836022-deb4988cc6c0?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1234&q=80',
        },
        url: {
            attrHref: '#'
        },
    },
    {
        title:"El llorenc",
        subTitle: "SEO",
        text: "Launching the new boutique hotel in Palma in 2019 into a competitive marketplace, Ruben and his team enjoyed outstanding success in a very short time as a result of their superior product, excellent management and of course advertising online and in the magazine of abcMallorca.",
        image: {
            src: 'https://images.unsplash.com/photo-1582896911227-c966f6e7fb93?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
        }
    },
    {
        title:"Bodhana",
        subTitle: "SEO",
        text: "The history of Bodhana and abcMallorca is a very long and happy one! Most of the existing clients of Bodhana come as a result of word of mouth recommendations from friends.",
        image: {
            src: 'https://images.unsplash.com/photo-1521488741203-dcc320950ce5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1234&q=80',
        },
    },
    {
        title:"Sadhana Works",
        subTitle:'Engineer',
        text: "Initially Mariana worked with Helen Cummins to develop and refine the business concept, strategy and plan. After the initial phase the collaboration continued on every aspect of the launch of the business in Mallorca",
        image: {
            src: 'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1234&q=80',
        },
    },
    {
        title: "John Doe",
        text: "Initially Mariana worked with Helen Cummins to develop and refine the business concept, strategy and plan. After the initial phase the collaboration continued on every aspect of the launch of the business in Mallorca",
        image: {
            src: 'https://images.unsplash.com/photo-1520341280432-4749d4d7bcf9?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1268&q=80',
        },
    },
];

const BusinessPage: React.FC = () => {
    const [select1, setSelect1] = useState(false);
    const [select2, setSelect2] = useState(true);
    const restaurantsForm = <ReactAux>
        <Row>
            <Col lg="6">
                <FormControl htmlFor="restaurants" label="Restaurants" isRequired isValid>
                    <Autocomplete
                        options={restaurantsOptions}
                        placeholder="Select restaurant..."
                        noOptionsMessage="No restaurant found"
                        defaultValue={restaurantsOptions[0]}
                        onChange={(option: any) => console.log(option)}
                        onInputChange={(inputValue: any) => console.log(inputValue)}
                    />
                </FormControl>
            </Col>
            <Col lg="6">
                <FormControl htmlFor="date" label="Date" isRequired isValid>
                    <Datepicker id="date" format="" />
                </FormControl>
            </Col>
            <Col lg="6">
                <FormControl htmlFor="time" label="Time" isRequired isValid>
                    <Select
                        id="time"
                        options={[
                            { value: 1, text: '20:00 h' },
                            { value: 2, text: '20:30 h' },
                            { value: 3, text: '21:00 h' },
                        ]}
                    />
                </FormControl>
            </Col>
            <Col lg="6">
                <FormControl htmlFor="numberOfGuests" label="Number of guests" isRequired isValid>
                    <Select
                        id="numberOfGuests"
                        options={[
                            { value: 1, text: '2 people' },
                            { value: 2, text: '3 people' },
                            { value: 3, text: '4 people' },
                            { value: 4, text: '5 people' },
                        ]}
                    />
                </FormControl>
            </Col>
        </Row>
        <Button className="w-100">search</Button>
    </ReactAux>;
    return (
        <>
            <HeroSlider
                slides={mainSlider}
                dots={true}
                autoplay={false}
                pauseOnHover={true}
                autoplaySpeed={3000}
                speed={300}
                initialSlide={0}
                infinite={true}
                id='summary'
                slidesAction={{
                    title: 'Hi, I’m Jane Doe',
                    subTitle: 'Your new personal coach for better life!',
                    actionLink:{
                      title: 'Contact us',
                      onClicked: () => console.log('example click'),
                    }
                  }}
            />

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <ArticleSummary
                            bulletTitle="How can we help?"
                            bullets={[
                                { title: 'One to one tailormade coaching sessions', url: { attrHref: '#' } },
                                { title: 'Relaxation & meditation sessions', url: { attrHref: '#' } },
                                { title: 'Healthy diet plan', url: { attrHref: '#' } },
                                { title: 'Healthy diet plan', url: { attrHref: '#' } },
                                { title: 'Massage therapy', url: { attrHref: '#' } },
                                { title: 'Rehabilitation', url: { attrHref: '#' } },
                                { title: 'Shiatsu', url: { attrHref: '#' } },
                            ]}
                        />

                        <Quote
                            text="“Many people struggle to find inner peace, so I can up with a tailormade program to get you into the best physical and mental state of your lifetime!”"
                            subText="~ Jane Doe"
                            className="abc-negative-gutter"
                        />

                        <Row>
                            <Col lg={{ size: 6, offset: 3 }}>
                                <Button icon="bookWhiteM" size="big" className="w-100 mb-l">book appointment</Button>
                            </Col>
                        </Row>
                        <hr className="mt-0 mb-m abc-negative-gutter" />

                        <h2 id="content">Overview</h2>

                        <p>Jane was born on the Caribbean island of Saint Maarten. He moved to Holland at the age of 19 to study physical and sports therapy and to pursue a career as an osteopath</p>

                        <hr className="mt-0 mb-m abc-negative-gutter" />

                        <h2>Services</h2>
                        <p>After 20 years of learning from and working with the best people in the business, Ihe decided to move to Mallorca to use his years of experience to grow and develop a sports therapy clinic. Along the way Joe also became fluent in four languages! English, Dutch, French, Spanish and he even speaks pretty good Catalan! </p>

                        <h3>Traditional Chinese Medicine</h3>
                        <p className="mb-l">Traditional Chinese medicine (TCM) is a branch of traditional medicine that is said to be based on more than 3,500 years of Chinese medical practice that includes various forms of herbal medicine, acupuncture, cupping therapy, gua sha, massage (tui na), bonesetter (die-da), exercise (qigong), and dietary therapy.
                        One of the basic tenets of TCM is that the body's vital energy (ch'i or qi) is circulating through channels, called meridians, that have branches connected to bodily organs and functions." TCM describes health as the harmonious interaction of these entities and the outside world, and disease as a disharmony in interaction.
                        At Care 4 Health we offer treatments harnessing acupuncture, moxibustion, and cupping to alleviate discomfort in the body and restore physical peace and harmony.</p>

                        <ArticleSummary
                            className="mb-s"
                            viewsCount="11 243"
                            date="01.03.2020"
                            tags={[
                                { title: 'nightlife' },
                                { title: 'cycling' },
                                { title: 'summer' },
                            ]}
                            bookBtn={{ title: 'Book', onClicked: () => console.log('bookBtn') }}
                            addToPlanBtn={{ title: 'Plan', isSelected: select1, onClicked: () => { setSelect1(!select1); console.log('addToPlanBtn') } }}
                            addToFavouritesBtn={{ title: 'Favourites', isSelected: select2, onClicked: () => { setSelect2(!select2); console.log('addToFavouritesBtn') } }}
                        />

                        <h2 className="mb-s" id='services'>Services</h2>

                        {
                            [
                                'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/09/sant-francesc-hotel-02.jpg',
                                'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/10/rotana-restaurant-8.jpg',
                                'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/11/port-soller-74.jpg',
                            ].map((link, i) =>
                                <Service
                                    key={i}
                                    image={{
                                        src: link
                                    }}
                                    title="Luxury hotel on Mallorca"
                                    description={{
                                        text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
                                        btnMoreText: "Read more",
                                        btnLessText: "See less"
                                    }}
                                    footer={{
                                        icon: 'priceSecondaryS',
                                        text: 'Price: 9 to 12 euro'
                                    }}
                                    className='abc-negative-gutter'

                                    // set link to title and img
                                    link={{
                                        attrHref: "#"
                                    }}
                                />
                            )
                        }

                        <InfoBox className="mb-l abc-negative-gutter" textSize='normal' title="Professional Qualifications & Awards">
                            <p className="mb-0">
                                Jane has worked with many top athletes, coaches and specialists. One of his specialities is to work with and support professional and competitive cycling teams from around the world, giving him the advantage to be able to give services to any cyclists needing high quality treatments and prevention sessions while training on the island.
                                He is also a member of the Colegio Oficial de Fisioterapeutas de las Islas Baleares, number 1513.
                            </p>
                        </InfoBox>

                    </Col>
                </Row>
            </Container>

            <TestimonialsSlider
                title={'What our clients say? Testoimonials'}
                slides={testimonialsArticle}
                dots={true}
                autoplay={true}
                pauseOnHover={true}
                autoplaySpeed={4000}
                speed={300}
                initialSlide={0}
                infinite={true}
                className="example"
            />

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <BusinessContacts
                            title="Jane Doe"
                            аddress="La Rambla, 20, Palma"
                            telephone="+34 871 045 174"
                            website={{ title: "www.coach-jane.com", href: "#" }}
                            languages={["English", "Spanish", "German"]}
                            id='contact'
                        />

                        <GoogleMapAddress
                            title="Location"
                            map={{
                                googleMapHelperApiKey: "AIzaSyDrX2CaIvlrLocKo4jvtga0Z3wxRH_KMsQ",
                                defaultCenter: { lat: 39.568590, lng: 2.642447 },
                                defaultZoom: 15,
                                marker: { lat: 39.568590, lng: 2.642447 },
                            }}
                            address="C/ Caro, 16 , Palma de Mallorca"
                            buttonText="Open in google maps"
                        />

                        <OpeningHours
                            title="Opening Hours"
                            note="Open all year"
                            rows={[
                                { title: 'Monday', hours: ['13:00 - 01:00 h'] },
                                { title: 'Tuesday', hours: ['09:00 - 12:00 h'] },
                                { title: 'Wednesday', hours: ['13:00 - 01:00 h'] },
                                { title: 'Thursday', hours: ['13:00 - 01:00 h'] },
                                { title: 'Friday', hours: ['13:00 - 01:00 h'] },
                                { title: 'Saturday', hours: ['13:00 - 01:00 h'] },
                                { title: 'Sunday', hours: ['closed'] },
                            ]} />

                        <Row>
                            <Col lg={{ size: 6, offset: 3 }}>
                                <Button icon="bookWhiteM" size="big" className="w-100 mb-l">book appointment</Button>
                            </Col>
                        </Row>

                        <div id="faq">
                            <FAQ title="FAQ" rows={[
                                { title: 'Do I need to have a certain skill level before I start training?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
                                { title: 'Can I have a gym buddy?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
                                { title: 'Do I need to rent equipment to train?', text: 'Yes, you could join in with a buddy to keep yourself motivated and make your trainings more enjoyable. Although keep in mind that yours and your buddy’s program won’t be identical.' },
                            ]}
                                className="mb-l abc-negative-gutter"
                            />
                        </div>



                        <div className="abc-negative-gutter mb-l abc-basic-border-wrapper">

                            <div>
                                <img className="mr-s" style={{ float: 'left' }} src="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/01/Villa-Rentals.png" alt="Villa-Rentals" />
                                <p className="sub-title">Enquire here!</p>
                                <p>To make your enquiry, simply complete all the information required below and submit the form.</p>
                                <p>You’ll receive a booking confirmation from the company shortly.</p>
                            </div>

                            <div style={{ clear: 'both' }} />

                            <Row>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="first-name"
                                        label="First name"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="first-name" type="text" name="first-name" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="last-name"
                                        label="Last name"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="last-name" type="text" name="last-name" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="guest"
                                        label="Guests (optional)"
                                        isValid={true}
                                    >
                                        <Input id="guest" type="text" name="guest" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="email"
                                        label="E-mail"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="email" type="email" name="email" />
                                    </FormControl>
                                </Col>
                                <Col sm="12">
                                    <FormControl
                                        htmlFor="telephone"
                                        label="Phone number"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="telephone" type="phote" name="telephone" />
                                    </FormControl>
                                </Col>
                                <Col sm="12">
                                    <FormControl
                                        htmlFor="special-requests"
                                        label="Comment (optional)"
                                        isValid={true}
                                    >
                                        <Textarea id="special-requests" rows={4} />
                                    </FormControl>
                                </Col>
                            </Row>

                            <Button className="w-100" type="submit">Send enquiry</Button>
                        </div>

                        <InfoBox className="mb-l abc-negative-gutter" textSize='small'>
                            <strong>Before sending the form, you must read the following basic information about data protection:</strong> The party responsible for processing data is ABC-Knowledge S.L. (abcMallorca). Your data will be processed to deal with your application and in order to comply with our legal obligations. The legitimacy of processing the data is based on managing relations with our users and legal obligations. Data will not be passed on to third parties, unless there is a legal obligation to do so, or in order to deal with your application properly. You are entitled to access, rectify and delete your data, along with other rights, as listed in the <a href="#" title="privacy">privacy policy</a> published on this Web site.
                        </InfoBox>

                        <Socials
                            language="en"
                            facebookLink={{ attrHref: '#', attrTargetBlank: true }}
                            instagramLink={{ attrHref: '#', attrTargetBlank: true }}
                            twitterLink={{ attrHref: '#', attrTargetBlank: true }}
                            pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
                            className="abc-negative-gutter"
                        />

                        <InfoBox className="mb-l abc-negative-gutter" textSize='small'>
                            We hope you have found this article about the <strong>Personal Training with Johanna Janik</strong> useful and that you have many memorable stays in Mallorca as a result of our recommendations. We are always pleased to hear from you, so do send us your <a href="#" title="feedback">feedback</a> here. Enjoy Mallorca!
                        </InfoBox>
                    </Col>
                </Row>
            </Container>

            <RelatedArticlesSlider
                title={'You might also like'}
                slides={relatedArticles}
                dots={true}
                autoplay={true}
                pauseOnHover={true}
                autoplaySpeed={2000}
                speed={300}
                initialSlide={0}
                infinite={true}
                className="example"
            />
        </>
    )
}

export default BusinessPage
