import React from 'react';
import ReactTooltip from 'react-tooltip'

import { Icon } from '@studiouxp/abc-mallorca-portal-ds';

type CopyIconProps = {
    icon: any,
    white?: boolean
}

class CopyIcon extends React.Component<CopyIconProps> {

    state = {
        showText: true
    }

    onMouseLeave = () => {
        this.setState({
            showText: true
        });
    }

    onClick = () => {
        navigator.clipboard.writeText(`<Icon icon="${this.props.icon}" />`);
        this.setState({
            showText: false
        });
    }

    render() {
        const { icon, white } = this.props;

        return (
            <div
                style={{ cursor: 'pointer', }}
                className={`d-inline-block${white ? ' icon-white' : ''}`}
                data-for={icon} data-tip
                onMouseLeave={this.onMouseLeave}
                onClick={this.onClick}>
                <Icon icon={icon} />
                <p className="mb-0">{icon}</p>
                <ReactTooltip
                    id={icon}
                    getContent={() => this.state.showText ? 'Copy to clipboard' : 'Copied'}
                    place="bottom"
                    type="dark"
                    effect="solid"
                    className="custom-tooltip" />
            </div>
        )
    }
}

export default CopyIcon;
