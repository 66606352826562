import React from 'react';
import { Link } from 'react-router-dom';

const DemoPages: React.FC = () => {
    return (
        <div>
            <h1>Demo Pages</h1>

            <p><Link to="/demo-pages/sarana-cafe" target="blank">Sarana Cafe</Link></p>
            <p><Link to="/demo-pages/villa-son-balaguerten" target="blank">Villa Son Balaguerten</Link></p>
            <p><Link to="/demo-pages/feeds" target="blank">Feeds</Link></p>
            <p><Link to="/demo-pages/profile" target="blank">Profile</Link></p>
            <p><Link to="/demo-pages/home" target="blank">Home</Link></p>
            <p><Link to="/demo-pages/payments-form" target="blank">Payments form</Link></p>
            <p><Link to="/demo-pages/booking" target="blank">Booking</Link></p>
            <p><Link to="/demo-pages/my-profile" target="blank">My Profile</Link></p>
            <p><Link to="/demo-pages/page-with-search" target="blank">Page with search</Link></p>
            <p><Link to="/demo-pages/magazine" target="blank">Magazine</Link></p>
            <p><Link to="/demo-pages/business" target="blank">Business Page</Link></p>
        </div>
    )
}

export default DemoPages;
