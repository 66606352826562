import React from 'react';
import Highlight from 'react-highlight';

import { SearchWidget, Row, Col, FormControl, Select, Datepicker, Button, ReactAux } from '@studiouxp/abc-mallorca-portal-ds';

const SearchWidgetPage: React.FC = () => {

  const restaurantsForm = <ReactAux>
    <Row>
      <Col lg="6">
        <FormControl htmlFor="restaurants" label="Restaurants" isRequired isValid>
          <Select
            id="restaurants"
            options={[
              { value: 1, text: 'Restaurant' },]}
            placeholder="All restaurants"
          />
        </FormControl>
      </Col>
      <Col lg="6">
        <FormControl htmlFor="date" label="Date" isRequired isValid>
          <Datepicker id="date" format="" />
        </FormControl>
      </Col>
      <Col lg="6">
        <FormControl htmlFor="time" label="Time" isRequired isValid>
          <Select
            id="time"
            options={[
              { value: 1, text: '20:00 h' },
              { value: 2, text: '20:30 h' },
              { value: 3, text: '21:00 h' },
            ]}
          />
        </FormControl>
      </Col>
      <Col lg="6">
        <FormControl htmlFor="numberOfGuests" label="Number of guests" isRequired isValid>
          <Select
            id="numberOfGuests"
            options={[
              { value: 1, text: '2 people' },
              { value: 2, text: '3 people' },
              { value: 3, text: '4 people' },
              { value: 4, text: '5 people' },
            ]}
          />
        </FormControl>
      </Col>
    </Row>
    <Button className="w-100">search</Button>
  </ReactAux>;

  return (
    <div>
      <h1>Search Widget</h1>

      <SearchWidget
        buttons={[
          { title: 'Restaurants', icon: 'restaurantsL', onCliked: () => { }, isActive: true },
          { title: 'Hotels', icon: 'hotelsL', onCliked: () => { }, isActive: false },
          { title: 'Activities', icon: 'activitiesL', onCliked: () => { }, isActive: false },
          { title: 'Services', icon: 'servicesL', onCliked: () => { }, isActive: false },
        ]} >
        {restaurantsForm}
      </SearchWidget>

      <Highlight className="React">
        {
          `
import React from 'react';
import { SearchWidget, Row, Col, FormControl, Select, Datepicker, Button, ReactAux } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {

  const restaurantsForm = <ReactAux>
    <Row>
      <Col lg="6">
        <FormControl htmlFor="restaurants" label="Restaurants" isRequired isValid>
          <Select
            id="restaurants"
            options={[
              { value: 1, text: 'Restaurant' },]}
            placeholder="All restaurants"
          />
        </FormControl>
      </Col>
      <Col lg="6">
        <FormControl htmlFor="date" label="Date" isRequired isValid>
          <Datepicker id="date" format="" />
        </FormControl>
      </Col>
      <Col lg="6">
        <FormControl htmlFor="time" label="Time" isRequired isValid>
          <Select
            id="time"
            options={[
              { value: 1, text: '20:00 h' },
              { value: 2, text: '20:30 h' },
              { value: 3, text: '21:00 h' },
            ]}
          />
        </FormControl>
      </Col>
      <Col lg="6">
        <FormControl htmlFor="numberOfGuests" label="Number of guests" isRequired isValid>
          <Select
            id="numberOfGuests"
            options={[
              { value: 1, text: '2 people' },
              { value: 2, text: '3 people' },
              { value: 3, text: '4 people' },
              { value: 4, text: '5 people' },
            ]}
          />
        </FormControl>
      </Col>
    </Row>
    <Button className="w-100">search</Button>
  </ReactAux>;

    return (
      <SearchWidget
        buttons={[
          { title: 'Restaurants', icon: 'restaurantsL', onCliked: () => { }, isActive: true },
          { title: 'Hotels', icon: 'hotelsL', onCliked: () => { }, isActive: false },
          { title: 'Activities', icon: 'activitiesL', onCliked: () => { }, isActive: false },
          { title: 'Services', icon: 'servicesL', onCliked: () => { }, isActive: false },
        ]} >
        {restaurantsForm}
      </SearchWidget>
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>buttons</td>
            <td>array of objects with props: <br />
                "title": string, "icon": string, "onCliked": void, "isActive": boolean
            </td>
            <td className="text-center">-</td>
            <td>Set the buttons.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SearchWidgetPage;
