import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';

import { Banner, YoutubeVideo } from '@studiouxp/abc-mallorca-portal-ds';

const BannerPage: React.FC = () => {
    return (
        <div>
            <h1>Banner</h1>

            <Banner
                className='mb-l'
                title="Read our magazine"
                buttonText="Read now"
                url={{ attrHref: '/docs/banner', onClicked: () => alert('Is clicked!') }}
                image={{
                    src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/magazine-online_whatson_2020_EN.jpg"
                }}
            />

            <Banner
                className='mb-l'
                title="At your service"
                url={{ attrHref: '/docs/banner', onClicked: () => alert('Is clicked!') }}
                image={{
                    src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/blevins-franks-banner-2019-12.jpg"
                }}
            />

            <Banner
                className="mb-l"
                title="Video"
                url={{ onClicked: () => alert('Magazine!') }}
                video={<YoutubeVideo
                    videoUrl="https://www.youtube.com/embed/6qErv1uzC-U" />}
            />


            <h3>Sponsored baner</h3>
            <Banner
                className="mb-l"
                url={{ onClicked: () => alert('Magazine!') }}
                image={{
                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg'
                }}
                sponsoredText="SPONSORED"
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Banner } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Banner 
            title="Read our magazine"
            buttonText="Read now" 
            url={{ attrHref: '/docs/banner', onClicked: () => alert('Is clicked!') }}
            image={{
                src:"https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/magazine-online_whatson_2020_EN.jpg"
            }}
        />

        <Banner 
            title="At your service"
            url={{ attrHref: '/docs/banner', onClicked: () => alert('Is clicked!') }}
            image={{
                src:"https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/blevins-franks-banner-2019-12.jpg"
            }}
        />

        <Banner
            title="Video"
            url={{ onClicked: () => alert('Magazine!') }}
            video={<YoutubeVideo
                videoUrl="https://www.youtube.com/embed/6qErv1uzC-U" />}
        />

        <Banner
            url={{ onClicked: () => alert('Magazine!') }}
            image={{
                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg'
            }}
            sponsoredText="SPONSORED"
         />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>buttonText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set button text.</td>
                    </tr>
                    <tr>
                        <td>image</td>
                        <td>Object with: "src": string, "srcSet": string </td>
                        <td className="text-center">-</td>
                        <td>Set the image.</td>
                    </tr>
                    <tr>
                        <td>url</td>
                        <td>Object with: "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, "onClicked": {'() => void'}</td>
                        <td className="text-center">-</td>
                        <td>Set the url for image and button.</td>
                    </tr>
                    <tr>
                        <td>video</td>
                        <td><Link to="/docs/youtube-video">YoutubeVideo component</Link></td>
                        <td className="text-center">-</td>
                        <td>Set the video.</td>
                    </tr>
                    <tr>
                        <td>sponsoredText</td>
                        <td className="text-center">string</td>
                        <td className="text-center">-</td>
                        <td>Sets lable text.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td className="text-center">string</td>
                        <td className="text-center">-</td>
                        <td>Sets HTML class to the wrapping element of the component.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default BannerPage;
