import React from 'react';
import Highlight from 'react-highlight';

import { Loader, Button } from '@studiouxp/abc-mallorca-portal-ds';

class LoaderPage extends React.Component {

    state = {
        visible: false
    }

    render() {
        return (
            <div>
                <h1>Loader</h1>

                <Button style={{ position: 'relative', zIndex: 999 }} onClick={() => this.setState({ visible: !this.state.visible })}>Toggle Loader</Button>
                <Loader isVisible={this.state.visible} />

                <Highlight className="React">
                    {
                        `
import React from 'react';
import { Loader, Button  } from '@studiouxp/abc-mallorca-portal-ds';

class Example extends React.Component{
    state = {
        visible: false
    }

    render(){
        return (
            // Button is for demo only
            <Button style={{ position: 'relative', zIndex: 999 }} onClick={() => this.setState({ visible: !this.state.visible })}>Toggle Loader</Button>
            
            <Loader isVisible={this.state.visible} />
        );
    }
}

export default Example;
        `}
                </Highlight>

                <table className="doc-table">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>isVisible</td>
                            <td>boolean</td>
                            <td className="text-center">-</td>
                            <td>Show or hide the loader.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

export default LoaderPage;
