export const profileRoutes = {
    editProfile: '/demo-pages/my-profile/edit-profile',
    notifications: '/demo-pages/my-profile/notifications',
    settings: '/demo-pages/my-profile/settings',
    reservations: '/demo-pages/my-profile/reservations',
    manageReservation: '/demo-pages/my-profile/reservations/manage-reservation',
    cards: '/demo-pages/my-profile/cards',
    editCard: '/demo-pages/my-profile/cards/edit-card',
    myProfile: '/demo-pages/my-profile'
}

export const profileRoutesAndTitles = [
    {link: profileRoutes.editProfile, title: 'Edit Profile'},
    {link: profileRoutes.notifications, title: 'Notifications'},
    {link: profileRoutes.settings, title:'Settings'},
    {link: profileRoutes.reservations, title:'Reservations'},
    {link: profileRoutes.manageReservation, title:'Manage Reservation'},
    {link: profileRoutes.cards, title:'Cards'},
    {link: profileRoutes.editCard, title:'Edit Card Details'},
    {link: profileRoutes.myProfile, title:'My Profile'}
 ];


