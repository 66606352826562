import React from 'react';
import Highlight from 'react-highlight';

import { Container } from '@studiouxp/abc-mallorca-portal-ds';

const ContainerPage: React.FC = () => {
    return (
        <div>
            <h1>Container</h1>

            <h2>Description</h2>
            <p>Containers wrap your content and help to separate or align it.
                Container component centers your content horizontally. It's the most basic layout element.
            </p>
            <div style={{ backgroundColor: 'black' }}>
                <Container>
                    <p style={{ backgroundColor: '#e2f1f3', height: '200px' }}></p>
                </Container>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Container } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Container>
            <p style={{ backgroundColor: '#e2f1f3', height:'200px' }}></p>
        </Container>
    );
}

export default Example;
    `}
            </Highlight>
        </div>
    )
}

export default ContainerPage;
