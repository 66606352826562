import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

const TypographyPage: React.FC = () => {
    return (
        <div>
            <h1>Typography</h1>
            <h2>Description</h2>

            <p><strong>Required!</strong> Import this fonts in HTML head.</p>

            <Highlight className="HTML">
                {
                    `
<link href="https://fonts.googleapis.com/css?family=Merriweather:400,700&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap" rel="stylesheet">
    `}
            </Highlight>

            <p>One of the key features of the CSS framework in abcMallorca Portal Design System is the set
		of typographical elements. Notice that they are not React components, but pure HTML elements instead.</p>

            <p>An important thing to keep in mind is the need for environment without other stylesheets, except
            the one of abcMallorca Portal Design System. The typography is easy to use via global styles, which can easily be
		overriden by other style rules, thus breaking the design system.</p>

            <p>Notice also that the typographical elements in the CSS framework of abcMallorca Design System have
            spacings (margins) by default.</p>

            <h1>Heading - H1</h1>
            <h2>Heading - H2</h2>
            <h3>Heading - H3</h3>
            <p>Paragraph - P</p>
            <p className="small">Small paragraph - {'<p className="small">'}</p>
            <p className="text-bold">Bold paragraph - {'<p className="text-bold">'}</p>
            <p className="font-size-component-body">Font size component body paragraph - {'<p className="font-size-component-body">'}</p>
            <p>Paragraph with <Link to="#">Link</Link></p>
            <p className="sub-title">Sub Title - className="sub-title"</p>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Link } from 'react-router-dom';
import '@studiouxp/abc-mallorca-portal-ds/dist/styles.css';

const Example: React.FC = (props) => {
    return (
        <div>
            <h1>Heading - H1</h1>
            <h2>Heading - H2</h2>
            <h3>Heading - H3</h3>
            <p>Paragraph - &lt;p></p>
            <p className="small">Small paragraph - &lt;p className="small"></p>
            <p className="text-bold">Bold paragraph - &lt;p className="text-bold"></p>
            <p className="font-size-component-body">Font size component body paragraph - &lt;p className="font-size-component-body"></p>
            <p>Paragraph with <Link to="#">Link</Link></p>
            <p className="sub-title">Sub Title - className="sub-title"</p>
        </div>
    );
}

export default Example;
    `}
            </Highlight>

        </div>
    )
}

export default TypographyPage;
