import React from 'react';

import { ReactAux, Hero, Container, Row, Col, Button } from '@studiouxp/abc-mallorca-portal-ds';

const confirmed = require('../../../images/icon-confirmed.svg');
const pending = require('../../../images/icon-pending.svg');
const rejected = require('../../../images/icon-rejected.svg');

class Finish extends React.Component {

    state = {
        status: 'confirmed'
    }

    render() {
        const { status } = this.state;

        return (
            <ReactAux>
                <Hero img="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/04/mercado-del-olivar-32.jpg" title="Restaurant name" description="Additional description" />

                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <div className="text-center mb-l">
                                {status !== 'confirmed' && <Button className="mb-m" appearance="secondary" onClick={() => this.setState({ status: 'confirmed' })}>Demo switch to confirmed status</Button>}
                                {status !== 'pending' && <Button className="mb-m" appearance="secondary" onClick={() => this.setState({ status: 'pending' })}>Demo switch to pending status</Button>}
                                {status !== 'rejected' && <Button className="mb-m" appearance="secondary" onClick={() => this.setState({ status: 'rejected' })}>Demo switch to rejected status</Button>}
                            </div>

                            {status === 'confirmed' &&
                                <ReactAux>
                                    <div className="text-center mb-m">
                                        <img src={confirmed} alt="confirmed" />
                                    </div>
                                    <p className="sub-title text-center mb-s">Congratulations!</p>
                                    <h2 className="text-center mb-l">Your reservation has been confirmed.</h2>
                                </ReactAux>
                            }

                            {status === 'pending' &&
                                <ReactAux>
                                    <div className="text-center mb-m">
                                        <img src={pending} alt="pending" />
                                    </div>
                                    <p className="sub-title text-center mb-s">You have requested a reservation!</p>
                                    <h2 className="text-center mb-l">Restaurant will confirm shortly.</h2>
                                </ReactAux>
                            }

                            {status === 'rejected' &&
                                <ReactAux>
                                    <div className="text-center mb-m">
                                        <img src={rejected} alt="rejected" />
                                    </div>
                                    <p className="sub-title text-center mb-s">Sorry we couldn’t process your reservation.</p>
                                    <h2 className="text-center mb-l">Please try again later.</h2>
                                </ReactAux>
                            }

                            <Row>
                                <Col lg="4"><Button className="w-100 mb-s" appearance="secondary">Send to a friend</Button></Col>
                                <Col lg="4"><Button className="w-100 mb-s" appearance="secondary">share</Button></Col>
                                <Col lg="4"><Button className="w-100 mb-l" appearance="secondary">add to calendar</Button></Col>
                                <Col lg={{ size: 8, offset: 2 }}><p className="small text-center mb-l">You can always amend or cancel your reservations by going to your Profile {'>'} Mahage bookings.</p></Col>
                                <Col lg={{ size: 4, offset: 4 }}><Button className="w-100 mb-l">go to home</Button></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </ReactAux>
        )
    }
}

export default Finish;
