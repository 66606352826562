import React from 'react';

import { ReactAux, Row, Col, Input, FormControl, InfoBox } from '@studiouxp/abc-mallorca-portal-ds';

const Step4: React.FC = () => {

    return (
        <ReactAux>
            <h2 className="mb-s">Contact Information</h2>
            <Row>
                <Col lg="6" sm="12">
                    <FormControl htmlFor="firstName" label="First name" isRequired isValid>
                        <Input id="firstName" name="firstName" type="text" />
                    </FormControl>
                </Col>
                <Col lg="6" sm="12">
                    <FormControl htmlFor="lastName" label="Last name" isRequired isValid>
                        <Input id="lastName" name="lastName" type="text" />
                    </FormControl>
                </Col>
                <Col lg="6" sm="12">
                    <FormControl htmlFor="phone" label="Phone" isRequired isValid>
                        <Input id="phone" name="phone" type="tel" />
                    </FormControl>
                </Col>
                <Col lg="6" sm="12">
                    <FormControl className="mb-l" htmlFor="email" label="Email" isRequired isValid>
                        <Input id="email" name="email" type="email" />
                    </FormControl>
                </Col>
            </Row>
            <InfoBox className="mb-l" title="Summary" textSize="normal">
                <strong>Adults:</strong> 6 <br />
                <strong>Baby 0-2y:</strong> 1 <br />
                <strong>Date:</strong> 7th November 2020 <br />
                <strong>Time:</strong> 18:00h <br />
                <strong>Menu:</strong> Pasta of the day <br />
                <strong>Options:</strong> Celebration
            </InfoBox>
        </ReactAux>
    )
}

export default Step4;
