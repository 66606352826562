import React from 'react';
import Highlight from 'react-highlight';

import { Collections } from '@studiouxp/abc-mallorca-portal-ds';

const collections = [
    {
        collection: {
            title: 'Plan my trip',
            content: '6 articles',
            img: 'https://www.abc-mallorca.es/wp-content/uploads/2018/09/Hotel.jpg',
        },
        link: {
            attrHref: '/components',
            onClicked: () => console.log('link clicked')
        }
    },
    {
        collection: {
            title: 'Hottest restaurants',
            content: '3 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/isaan-restaurant-40-430x330.jpg',
        }
    },
    {
        collection: {
            title: 'Favourites',
            content: '10 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/08/fosh-lab-01-430x330.jpg',
        },
    },
    {
        collection: {
            title: 'Favourite museums',
            content: '3 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/11/joan-miro-visit-museum-mallorca-img03.jpg',
        },
    },
    {
        collection: {
            title: 'To read later',
            content: '0 articles',
        },
    },
    {
        collection: {
            title: 'Valentine\'s place',
            content: '2 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/11/valentine-day-february.jpg',
        },
    },
    {
        action: { title: 'Add new', icon: 'newCollectionWhiteL' },
        isAction: true,
    },
];

const CollectionsPage: React.FC = () => {
    return (
        <div>
            <h1>Collections</h1>


            <Collections collections={collections} title="Collections Title" />
            <Collections collections={collections} title="Collections with background" hasBackground />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Collections } from '@studiouxp/abc-mallorca-portal-ds';

const collections = [
    {
        collection: {
            title: 'Plan my trip',
            content: '6 articles',
            img: 'https://www.abc-mallorca.es/wp-content/uploads/2018/09/Hotel.jpg',
        },
        link: {
            attrHref: '/components',
            onClicked: () => console.log('link clicked')
        }
    },
    {
        collection: {
            title: 'Hottest restaurants', 
            content: '3 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/isaan-restaurant-40-430x330.jpg',
        }
    },
    {
        collection: {
            title: 'Favourites', 
            content: '10 articles', 
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/08/fosh-lab-01-430x330.jpg',
        },
    },
    {
        collection: {
            title: 'Favourite museums', 
            content: '3 articles', 
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/11/joan-miro-visit-museum-mallorca-img03.jpg',
        },
    },
    {
        collection: {
            title: 'To read later', 
            content: '0 articles',
        },
    },
    {
        collection: {
            title: 'Valentine\\'s place', 
            content: '2 articles', 
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/11/valentine-day-february.jpg',
        },
    },
    {
        action: { title: 'Add new', icon: 'newCollectionWhiteL' },
        isAction: true,
    },
];

const Example: React.FC = (props) => {
    return (
        <Collections collections={collections} title="Collections Title" />
        <Collections collections={collections} title="Collections with background" hasBackground />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td className="text-center">string</td>
                        <td className="text-center">-</td>
                        <td>Sets collections wrapper title.</td>
                    </tr>
                    <tr>
                        <td>collections</td>
                        <td >Array of objects. Object with props:<br />
                            "collection" : Object with props: <br />
                            "title":string<br />
                            "content":string<br />
                            "img":string
                            <hr />
                            "action" : Object with props: <br />
                            "title":string <br />
                            "content":string <br />
                            "icon":string <br />
                            <hr />
                            "isAction": boolean,
                            <hr />
                            "link": Object with props:<br />
                            "attrHref": string<br />
                            "attrTitle": string<br />
                            "attrTargetBlank": boolean<br />
                            {'"onClicked": () => void'}
                         </td>
                        <td className="text-center">-</td>
                        <td>Set the collections.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td className="text-center">string</td>
                        <td className="text-center">-</td>
                        <td>Sets HTML class to the wrapping element of the component.</td>
                    </tr>
                    <tr>
                        <td>hasBackground</td>
                        <td className="text-center">boolean</td>
                        <td className="text-center">false</td>
                        <td>Defines if the Collections element has a background color.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CollectionsPage;
