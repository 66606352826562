import React from 'react';
import Highlight from 'react-highlight';

import { ContactDetails } from '@studiouxp/abc-mallorca-portal-ds';

const ContactDetailsPage: React.FC = () => {
    return (
        <div>
            <h1>Contact Details</h1>

            <ContactDetails language="en" googleMapApiKey="AIzaSyDrX2CaIvlrLocKo4jvtga0Z3wxRH_KMsQ" />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { ContactDetails } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <ContactDetails language="en" googleMapApiKey="/* YOUR KEY HERE */" />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>language</td>
                        <td>"en", "de", "es"</td>
                        <td className="text-center">-</td>
                        <td>Set the langueage for component.</td>
                    </tr>
                    <tr>
                        <td>googleMapApiKey</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the ApiKey for google map.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ContactDetailsPage;
