import React from 'react';
import Highlight from 'react-highlight';

import { WeatherWidget } from '@studiouxp/abc-mallorca-portal-ds';

const WeatherWidgetPage: React.FC = () => {

    const weeklyForecast = [
        {
            dayOfweek: 'TUE',
            degrees: '31°',
            iconType: 'sunny'
        },
        {
            dayOfweek: 'Wed',
            degrees: '21°',
            iconType: 'partlyCloudy'
        },
        {
            dayOfweek: 'THU',
            degrees: '33°',
            iconType: 'sunny'
        },
        {
            dayOfweek: 'FRI',
            degrees: '31°',
            iconType: 'cloudy'
        },
        {
            dayOfweek: 'SAT',
            degrees: '24°',
            iconType: 'rainy'
        },
        {
            dayOfweek: 'SUN',
            degrees: '34°',
            iconType: 'sunny'
        }
    ] as any;

    const currentWeatherInfo = [
        'Clear',
        '65% humidity',
        'Wind: 2m/s W',
        'H 3 - L 21'
    ];
    return (
        <div>
            <h1>Weather Widget</h1>

            <div>
                <h3>Default</h3>
                <WeatherWidget
                    title='Weather forecast: Palma De Mallorca'
                    className='abc-negative-gutter'
                    currentDegrees="14°"
                    currentWeatherIconType="sunny"
                    currentWeatherInfo={currentWeatherInfo}
                    weeklyForecast={weeklyForecast}
                />
                <h3>Weather Widget with background image.</h3>
                <WeatherWidget
                    title='Weather forecast: Palma De Mallorca'
                    className='abc-negative-gutter'
                    imgSrc='https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/calo-des-moro-beach01.jpg'
                    currentDegrees="14°"
                    currentWeatherIconType="sunny"
                    currentWeatherInfo={currentWeatherInfo}
                    weeklyForecast={weeklyForecast}
                />
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { WeatherWidget } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    
    const weeklyForecast = [
        {
            dayOfweek: 'TUE',
            degrees: '31°',
            iconType: 'sunny'
        },
        {
            dayOfweek: 'Wed',
            degrees: '21°',
            iconType: 'partlyCloudy'
        },
        {
            dayOfweek: 'THU',
            degrees: '33°',
            iconType: 'sunny'
        },
        {
            dayOfweek: 'FRI',
            degrees: '31°',
            iconType: 'cloudy'
        },
        {
            dayOfweek: 'SAT',
            degrees: '24°',
            iconType: 'rainy'
        },
        {
            dayOfweek: 'SUN',
            degrees: '34°',
            iconType: 'sunny'
        }
    ] as any;

    const currentWeatherInfo = [
        'Clear',
        '65% humidity',
        'Wind: 2m/s W',
        'H 3 - L 21'
    ];

    return (

    <WeatherWidget
        title='Weather forecast: Palma De Mallorca'
        className='abc-negative-gutter'
        currentDegrees="14°"
        currentWeatherIconType="sunny"
        currentWeatherInfo={currentWeatherInfo}
        weeklyForecast={weeklyForecast}
    />

    <WeatherWidget
        title='Weather forecast: Palma De Mallorca'
        className='abc-negative-gutter'
        imgSrc='https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/calo-des-moro-beach01.jpg'
        currentDegrees="14°"
        currentWeatherIconType="sunny"
        currentWeatherInfo={ currentWeatherInfo }
        weeklyForecast={ weeklyForecast }
    />

    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Sets the title of the weather widget.</td>
                    </tr>
                    <tr>
                        <td>imgSrc</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Sets background image of the component.</td>
                    </tr>
                    <tr>
                        <td>currentDegrees</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Sets current degrees.</td>
                    </tr>
                    <tr>
                        <td>currentWeatherIconType</td>
                        <td>'sunny' || 'rainy' || 'cloudy' || 'partlyCloudy'</td>
                        <td className='text-center'>-</td>
                        <td>Sets current current weather icon.</td>
                    </tr>
                    <tr>
                        <td>currentWeatherInfo</td>
                        <td>Array of strings</td>
                        <td className='text-center'>-</td>
                        <td>Sets additional info for current weather.</td>
                    </tr>
                    <tr>
                        <td>weeklyForecast</td>
                        <td>
                            Array of objects with props: <br/>
                            "dayOfweek": string <br/>
                            "degrees": string <br/>
                            "iconType": 'sunny' || 'rainy' || 'cloudy' || 'partlyCloudy'
                        </td>
                        <td className='text-center'>-</td>
                        <td>Sets weekly forecast.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td >string</td>
                        <td className='text-center'>-</td>
                        <td>Set the class name of the component wrapper.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default WeatherWidgetPage;
