import React, { useState } from 'react';

import {
    Container, Row, Col, FormControl, Input,
    Button, InfoBox, Icon, Toggle, CVVInput, CardExpirationDateInput, CardHolderInput
} from '@studiouxp/abc-mallorca-portal-ds';

const EditCardPage: React.FC = () => {
    const [userDetails, setUserDetails] = useState({
        cardLabel: 'My Work Visa',
        cardHolder: 'Yana Stoyanova',
        cardValidUntil: '11/20',
        cardCode: '',
        cardDefault:true
    });
    const [isCardExpirationValid, setCardExpirationValid] = useState(true)
    const [isCardCodeValid, setCardCodeValid] = useState(true)
    const [isCardHolderValid, setCardHolder] = useState(true)

    const onDetailsChange = (e: any) => {
        const copyUserDetails = { ...userDetails } as any;
        if (e.target.name === 'cardDefault') {
            copyUserDetails[e.target.name] = e.target.checked;
        }else{
            copyUserDetails[e.target.name] = e.target.value;
        }

        setUserDetails(copyUserDetails);
    }

    const updateCardExpDateValidity = (isExpired: boolean) => {
        setCardExpirationValid(isExpired);
    }

    const updateCardCodeValidity = (isValid: boolean) => {
        setCardCodeValid(isValid);
    }

    const updateCardHolder = (isValid: boolean) => {
        setCardHolder(isValid);
    }
    return (
        <Container>
            <Row>
                <Col lg={{ size: 8, offset: 2 }}>
                    <InfoBox className="mb-m mt-l abc-negative-gutter" title="Card Details" textSize="normal">
                        <span className="d-flex"><strong className="mr-s">Card number:</strong>• • • • 2012</span>
                        <span className="d-flex align-items-center"><strong className="mr-s">Card provider:</strong><Icon icon='visaL' /></span>
                    </InfoBox>
                    <form>
                        <Row>
                            <Col md="12">
                                <hr className="w-100" />
                                <FormControl
                                    htmlFor="card-default"
                                    className="mb-0"
                                    isRequired
                                    isValid={true}
                                    validationMessage="This field is required">
                                    <Toggle name="cardDefault" onChange={onDetailsChange} checked={userDetails.cardDefault}>Default Card</Toggle>
                                </FormControl>
                                <hr className="w-100 mb-m" />
                            </Col>
                            <Col md="12">
                                <FormControl
                                    htmlFor="cardLabel"
                                    label="Card label"
                                    isRequired
                                    isValid={true}
                                    validationMessage="This field is required">
                                    <Input
                                        id="cardLabel"
                                        type="text"
                                        name="cardLabel"
                                        value={userDetails.cardLabel}
                                        onChange={onDetailsChange}
                                    />
                                </FormControl>
                            </Col>
                            <Col md="12">
                                <FormControl
                                    htmlFor="cardHolder"
                                    label="Name of Card holder"
                                    isRequired
                                    isValid={isCardHolderValid}
                                    validationMessage="Invalid card holder">
                                    <CardHolderInput
                                        id="cardHolder"
                                        type="text"
                                        name="cardHolder"
                                        value={userDetails.cardHolder}
                                        onChange={onDetailsChange}
                                        onCardHolderValidityChange={(isValid: boolean) => updateCardHolder(isValid)}
                                    />
                                </FormControl>
                            </Col>
                            <Col md="6">
                                <FormControl
                                    htmlFor="card-expiration"
                                    label="Valid Until"
                                    isRequired
                                    isValid={isCardExpirationValid}
                                    validationMessage="Invalid expiration date."
                                >
                                    <CardExpirationDateInput
                                        id="card-expiration"
                                        name="cardValidUntil"
                                        value={userDetails.cardValidUntil}
                                        onChange={onDetailsChange}
                                        onExpDateValidityChange={(isExpired: boolean) => updateCardExpDateValidity(isExpired)}
                                    />
                                </FormControl>
                            </Col>
                            <Col md="6">
                                <FormControl
                                    htmlFor="card-cvv"
                                    label="Security Code"
                                    isRequired
                                    isValid={isCardCodeValid}
                                    validationMessage="Invalid security code."
                                >
                                    <CVVInput
                                        id="card-cvv"
                                        name="cardCode"
                                        value={userDetails.cardCode}
                                        onChange={onDetailsChange}
                                        onCVVValidityChange={(isValid: boolean) => updateCardCodeValidity(isValid)}
                                    />
                                </FormControl>
                            </Col>
                            <Col md="6">
                                <Button type="submit" className="w-100 mb-s">save changes</Button>
                            </Col>
                            <Col md="6">
                                <Button type="button" className="w-100 mb-s" appearance="secondary">cancel</Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        </Container>
    );
}

export default EditCardPage;
