import React from 'react';
import Highlight from 'react-highlight';

import { Socials } from '@studiouxp/abc-mallorca-portal-ds';

const SocialsPage: React.FC = () => {
    return (
        <div>
            <h1>Socials</h1>

            <Socials
                language="en"
                facebookLink={{ attrHref: '#', attrTargetBlank: true }}
                instagramLink={{ attrHref: '#', attrTargetBlank: true }}
                twitterLink={{ attrHref: '#', attrTargetBlank: true }}
                pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
            />
            <Socials
                language="es"
                facebookLink={{ attrHref: '#', attrTargetBlank: true }}
                instagramLink={{ attrHref: '#', attrTargetBlank: true }}
                twitterLink={{ attrHref: '#', attrTargetBlank: true }}
                pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
            />
            <Socials
                language="de"
                facebookLink={{ attrHref: '#', attrTargetBlank: true }}
                instagramLink={{ attrHref: '#', attrTargetBlank: true }}
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Socials } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <Socials
            language="en"
            facebookLink={{ attrHref: '#', attrTargetBlank: true }}
            instagramLink={{ attrHref: '#', attrTargetBlank: true }}
            twitterLink={{ attrHref: '#', attrTargetBlank: true }}
            pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
        />
        <Socials
            language="es"
            facebookLink={{ attrHref: '#', attrTargetBlank: true }}
            instagramLink={{ attrHref: '#', attrTargetBlank: true }}
            twitterLink={{ attrHref: '#', attrTargetBlank: true }}
            pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
        />
        <Socials
            language="de"
            facebookLink={{ attrHref: '#', attrTargetBlank: true }}
            instagramLink={{ attrHref: '#', attrTargetBlank: true }}
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>language</td>
                        <td>"en", "es", "de"</td>
                        <td className="text-center">-</td>
                        <td>Set the langueage for component.</td>
                    </tr>
                    <tr>
                        <td>facebookLink</td>
                        <td className="text-center">
                            Object with props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the link.</td>
                    </tr>
                    <tr>
                        <td>instagramLink</td>
                        <td className="text-center">
                            Object with props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the link.</td>
                    </tr>
                    <tr>
                        <td>twitterLink</td>
                        <td className="text-center">
                            Object with props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the link.</td>
                    </tr>
                    <tr>
                        <td>pinterestLink</td>
                        <td className="text-center">
                            Object with props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the link.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default SocialsPage;
