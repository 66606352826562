import React from 'react';
import { Link } from 'react-router-dom';

import logo from './logo.svg';
import './sidebar.scss';

import LinkItem from './LinkItem/LinkItem';

const Sidebar: React.FC = () => {

    return <div className="doc-sidebar">
        <div className="space"></div>
        <div className="menu-header">
            <Link to="/">
                <img src={logo} alt="abcMallorca" />
            </Link>
            <h1>Design System</h1>
            <span>v. 0.17.6</span>
        </div>
        <div className="menu-links">
            <LinkItem title="Getting started" links={[
                { title: 'Introduction', path: '/docs/introduction' },
            ]} />
            <LinkItem title="Typography" links={[
                { title: 'Basic', path: '/docs/typography' }
            ]} />
            <LinkItem title="Components" links={[
                { title: 'Article Summary', path: '/docs/article-summary' },
                { title: 'Banner', path: '/docs/banner' },
                { title: 'Booking Steps', path: '/docs/booking-steps' },
                { title: 'Business Contacts', path: '/docs/business-contacts' },
                { title: 'Button', path: '/docs/button' },
                { title: 'ChoiceButtons', path: '/docs/choice-buttons' },
                { title: 'Collections', path: '/docs/collections' },
                { title: 'Cookies', path: '/docs/cookies' },
                { title: 'Credit Card', path: '/docs/credit-card' },
                { title: 'Contact Details', path: '/docs/contact-details' },
                { title: 'Datepicker', path: '/docs/datepicker' },
                { title: 'Display Category', path: '/docs/display-category' },
                { title: 'Display Post', path: '/docs/display-post' },
                { title: 'Event Preview', path: '/docs/event-preview' },
                { title: 'FAQ', path: '/docs/faq' },
                { title: 'Form controls', path: '/docs/form-controls' },
                { title: 'Google Map Address', path: '/docs/google-map-address' },
                { title: 'Hero', path: '/docs/hero' },
                { title: 'Hero Slider', path: '/docs/hero-slider' },
                { title: 'Home Slider', path: '/docs/home-slider' },
                { title: 'Hotel Overview', path: '/docs/hotel-overview' },
                { title: 'Icon', path: '/docs/icon' },
                { title: 'Info Box', path: '/docs/info-box' },
                { title: 'Link Box', path: '/docs/link-box' },
                { title: 'Loader', path: '/docs/loader' },
                { title: 'Magazine', path: '/docs/magazine' },
                { title: 'Modal', path: '/docs/modal' },
                { title: 'Opening Hours', path: '/docs/opening-hours' },
                { title: 'Payment Form Controls', path: '/docs/payment-form-controls' },
                { title: 'Quote', path: '/docs/quote' },
                { title: 'Related Articles Slider', path: '/docs/related-articles-slider' },
                { title: 'Search Widget', path: '/docs/search-widget' },
                { title: 'Select Cards', path: '/docs/select-cards' },
                { title: 'Service', path: '/docs/service' },
                { title: 'Socials', path: '/docs/socials' },
                { title: 'Testimonials slider', path: '/docs/testimonials-slider' },
                { title: 'Thumbnails List', path: '/docs/thumbnails-list' },
                { title: 'Toast', path: '/docs/toast' },
                { title: 'Youtube Video', path: '/docs/youtube-video' },
                { title: 'Weather Widget', path: '/docs/weather-widget' },
            ]} />
            <LinkItem title="Layout" links={[
                { title: 'Action Bar', path: '/docs/action-bar' },
                { title: 'BackgroundWrapper', path: '/docs/background-wrapper' },
                { title: 'Container', path: '/docs/container' },
                { title: 'Filter Wrapper', path: '/docs/filter-wrapper' },
                { title: 'Footer', path: '/docs/footer' },
                { title: 'Header', path: '/docs/header' },
                { title: 'ReactAux', path: '/docs/react-aux' },
            ]} />
            <LinkItem title="Utilities" links={[
                { title: 'Spacing', path: '/docs/utilities/spacing' },
            ]} />
            <LinkItem title="Demo Pages" links={[
                { title: 'Demo Pages', path: '/docs/demo-pages' },
            ]} />
        </div>
    </div>
}

export default Sidebar;