import React, { useState } from 'react';

import {
    Container, Row, Col, Hero, InfoBox, Button, Modal
} from '@studiouxp/abc-mallorca-portal-ds';
const pending = require('../../../images/icon-pending.svg');

const ManageReservationPage: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <>
            <Hero
                img="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/isaan-restaurant-40.jpg"
                title="Restaurant name"
                description="Restaurant address"
            />
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <Row>
                            <Col md="12">
                                <div className="d-flex justify-content-center mb-m">
                                    <img src={pending} alt="pending" />
                                </div>
                                <p className="sub-title text-center mb-s">You have requested a reservation!</p>
                                <h2 className="text-center mb-l">Restaurant will confirm shortly.</h2>
                                <InfoBox className="mb-l" title="Reservation Summary" textSize="normal">
                                    <strong>Adults:</strong> 6 <br />
                                    <strong>Baby 0-2y:</strong> 1 <br />
                                    <strong>Date:</strong> 7th November 2020 <br />
                                    <strong>Time:</strong> 18:00h <br />
                                    <strong>Menu:</strong> Pasta of the day <br />
                                    <strong>Options:</strong> Celebration
                                </InfoBox>
                            </Col>
                            <Col md="6">
                                <Button type="button" className="w-100 mb-s" appearance="secondary">take me there</Button>
                            </Col>
                            <Col md="6">
                                <Button type="button" className="w-100 mb-s" appearance="secondary">get a taxi</Button>
                            </Col>
                            <Col md="6">
                                <Button type="button" className="w-100 mb-s" appearance="secondary">call the restaurant</Button>
                            </Col>
                            <Col md="6">
                                <Button type="button" className="w-100 mb-s" appearance="secondary">e-mail the restaurant</Button>
                            </Col>
                            <Col md="6">
                                <Button type="button" className="w-100 mb-s" appearance="secondary">edit the reservation</Button>
                            </Col>
                            <Col md="6">
                                <Button 
                                  type="button" 
                                  className="w-100 mb-s" 
                                  appearance="secondary" 
                                  onClick={()=> setIsModalOpen(true)}
                                  >
                                      cancel the reservation
                                </Button>
                            </Col>
                            <Col md="12">
                                <Button type="button" className="w-100 mb-s">go to home</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Modal
                isOpen={isModalOpen}
                onCloseModal={() => { setIsModalOpen(false) }}
                isDismissible={true}
            >
                <div className="mt-s mr-s ml-s">
                    <p className="text-bold">Cancel reservation</p>
                    <p>Are you sure you want to cancel your reservation at ‘Restaurant’s name’?</p>
                    <div className='d-flex justify-content-end'>
                        <Button 
                          type="button" 
                          className="mb-s mr-s" 
                          appearance='secondary' 
                          onClick={()=> setIsModalOpen(false)}>
                              no
                        </Button>
                        <Button type="button" className="mb-s" appearance='secondary'>cancel reservation</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ManageReservationPage;
