import React from 'react';
import Highlight from 'react-highlight';

const HeaderPage: React.FC = () => {
    return (
        <div>
            <h1>Header</h1>
            <p>
                Example for Default and Inner page Header <a href='/components' target="_blank">here</a>
            </p>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Banner } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    const isInnerPage=false;
    return (
        <Header
        links={[{ title: 'About Mallorca', isActive: false, attrHref:"#", attrTitle:"About", attrTargetBlank:false, onClicked: () => console.log('about') }, { title: 'Events', isActive: true, attrHref:"#", attrTitle:"Events", attrTargetBlank:false, onClicked: () => console.log('events') },
        { title: 'Restaurants', isActive: false, attrHref:"#", attrTitle:"Restaurants", attrTargetBlank:false, onClicked: () => console.log('restaurant') }, { title: 'Hotels', isActive: false, attrHref:"#", attrTitle:"Hotels", attrTargetBlank:false, onClicked: () => console.log('hotel') },
        { title: 'Activities', isActive: false, attrHref:"#", attrTitle:"Activities", attrTargetBlank:false, onClicked: () => console.log('activities') }, { title: 'Services', isActive: false, attrHref:"#", attrTitle:"Services", attrTargetBlank:false, onClicked: () => console.log('services') }]}
        weatherInfo={{
            title:"Mallorca, Dec 10 2019:",
            link:{
                title:"14 °C",
                iconType: 'sunny'
            }
        }}
        logo={{  attrHref:"#", attrTitle:"AbcLogo", attrTargetBlank:false, onClicked: () => console.log('logo cliked new') }}
        search={{
            placeholder: "Type smth",
            onInputChange: (e: any) => console.log(e.target.value),
            onSearchSubmit: () => console.log('search submited')
        }}
        language={{ selectedLanguage: "en", onLanguageChange: (lan: string) => console.log(lan) }}
        policyLinks={[
            { title: 'Privacy Policy', link: { attrHref:"#", attrTitle:"Privacy", attrTargetBlank:false, onClicked: () => console.log('Privacy Policy') } },
            { title: 'Cookie Policy', link: { attrHref:"#", attrTitle:"Cookie", attrTargetBlank:false, onClicked: () => console.log('Cookie Policy') } },
            { title: 'Terms and Conditions', link: { attrHref:"#", attrTitle:"Terms", attrTargetBlank:false, onClicked: () => console.log('Terms and Conditions') } },
        ]}
        socialLinks={{
            facebookLink: { attrHref:"#", attrTitle:"facebook", attrTargetBlank:false, onClicked: () => console.log('clicked facebook') },
            instagramLink: { attrHref:"#", attrTitle:"instagram", attrTargetBlank:false,onClicked: () => console.log('clicked instagram') },
            twitterLink: { attrHref:"#", attrTitle:"twitter", attrTargetBlank:false, onClicked: () => console.log('clicked twitter') },
            googleLink: { attrHref:"#", attrTitle:"google", attrTargetBlank:false, onClicked: () => console.log('clicked google') },
            linkedinLink: { attrHref:"#", attrTitle:"linkedIn", attrTargetBlank:false, onClicked: () => console.log('clicked linkedIn') },
            pinterestLink: { attrHref:"#", attrTitle:"pinteres",attrTargetBlank:false, onClicked: () => console.log('clicked pinteres') },
            youtubeLink: { attrHref:"#", attrTitle:"faceyoutubebook", attrTargetBlank:false, onClicked: () => console.log('clicked youtube') },
        }}
        isInnerPage={isInnerPage}
        // Inner page props
        innerPageTitle={'Some inner Page title, Some inner Page title, Some inner Page title, Some inner Page title,'}
        // Set inner page custom Button
        // innerPageCustomBtn={{iconWhite:'newCollectionL', iconBlack:'newCollectionL', onClicked:()=> console.log('clicked custom btn')}}
        onBackClicked={() => console.log('back clicked')}

        // set in page nav id to scroll to.
        inPageNav= {[{ id: 'google', linkTitle: 'Test' }, { id: 'google2', linkTitle: 'Test2' }]}
    />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>links</td>
                        <td >Array of objects. Object with props:<br />
                            "title" : string,
                            <hr />
                            "isActive" : boolean,
                            <hr/>
                            "attrHref": string,
                            <hr/>
                            "attrTitle": string,
                            <hr/>
                            "attrTargetBlank": boolean, 
                            <hr/>
                            {'"onClicked": () => void'}<br />
                         </td>
                        <td className="text-center">-</td>
                        <td>Set menu links.</td>
                    </tr>
                    <tr>
                        <td>weatherInfo</td>
                        <td >Array of objects. Object with props:<br />
                            "title" : string,
                            <hr />
                            "link" : Object with props:<br/>
                                "attrHref": string,
                            <hr/>
                                "attrTitle": string,
                            <hr/>
                                "attrTargetBlank": boolean, 
                            <hr/>
                                {'"onClicked": () => void'}<br />
                            <hr/>
                                "iconType": 'sunny' || 'rainy' || 'cloudy' || 'partlyCloudy'
                         </td>
                        <td className="text-center">-</td>
                        <td>Set weather text.</td>
                    </tr>
                    <tr>
                        <td>logo</td>
                        <td>Object with: "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}</td>
                        <td className="text-center">-</td>
                        <td>Set logo link props.</td>
                    </tr>
                    <tr>
                        <td>search</td>
                        <td>Object with:"placeholder": string, "onInputChange": Function(you will receive the onChange event), "onSearchSubmit": Function</td>
                        <td className="text-center">-</td>
                        <td>Set search placeholder and callback functions for onChange and onSubmit.</td>
                    </tr>
                    <tr>
                        <td>isInnerPage</td>
                        <td className="text-center">boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets header state.</td>
                    </tr>
                    <tr>
                        <td>language</td>
                        <td>Object with:"selectedLanguage": {'string => "en"'} | "es" | "de", "onLanguageChange": Function(you will receive selected language string)</td>
                        <td className="text-center">-</td>
                        <td>Sets selected language and callback function onChange.</td>
                    </tr>
                    <tr>
                        <td>policyLinks</td>
                        <td >Array of objects. Object with props:<br />
                            "title" : string,
                            <hr />
                            "link" : Object with props:
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                         </td>
                        <td className="text-center">-</td>
                        <td>Sets policy links for mobile menu.</td>
                    </tr>
                    <tr>
                        <td>socialLinks</td>
                        <td >Object with props:<br />
                            "facebookLink" :  "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            <hr />
                            "instagramLink" :  "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            <hr/>
                            "twitterLink":  "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            <hr/>
                            "googleLink":  "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            <hr/>
                            "linkedinLink":  "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            <hr/>
                            "pinterestLink":  "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            <hr/>
                            "youtubeLink":  "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                         </td>
                        <td className="text-center">-</td>
                        <td>Sets set social links for mobile menu.</td>
                    </tr>
                    <tr>
                        <td>innerPageTitle</td>
                        <td>string </td>
                        <td className="text-center">-</td>
                        <td>Set inner page title.</td>
                    </tr>
                    <tr>
                        <td>innerPageCustomBtn</td>
                        <td>Object with: "iconWhite": string, "iconBlack": string, "onClicked": Function </td>
                        <td className="text-center">-</td>
                        <td>Set replace search button and set custom icon with callback onClick.</td>
                    </tr>
                    <tr>
                        <td>onBackClicked</td>
                        <td>Function </td>
                        <td className="text-center">-</td>
                        <td>Set callback function on Header inner page back button.</td>
                    </tr>
                    <tr>
                        <td>inPageNav</td>
                        <td>Array of Objects with: "id": string, "linkTitle": string </td>
                        <td className="text-center">-</td>
                        <td>Set navbar under header and links which scroll to sections of the page, don't forget to set the "id" on the ements you want to scroll to. <strong>Order objects in the array in the way "id" are ordered in page</strong>.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default HeaderPage;
