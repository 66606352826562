import React from 'react';

import {
    ReactAux, HeroSlider, Container, Row, Col, Icon, ArticleSummary, DisplayPost,
    RelatedArticlesSlider, HotelOverview, Button, FormControl, Input, Select,
    Datepicker, Textarea, InfoBox,
} from '@studiouxp/abc-mallorca-portal-ds';

const slides = [
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img09.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img03.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img04.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img06.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img08.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img10.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img012.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img13.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img02.jpg' }
        },
    },
    {
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-img01.jpg' }
        },
    }
];

const slidesArticle = [
    {
        text: "Discover the Serra de Tramuntana",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/11/sunrise-cap-formentor-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/11/sunrise-cap-formentor-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/11/sunrise-cap-formentor-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "The country guide to Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/02/country-guide-02-1-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/02/country-guide-02-1-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/02/country-guide-02-1-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "How to do luxury on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/04/luxury-mallorca-img03-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/04/luxury-mallorca-img03-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/04/luxury-mallorca-img03-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Luxury Med-style villa rental Sa Punta de S'Aguila",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Luxury Holiday Villas to rent on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/Cala-Marmassen-and-Camp-de-Mar-headland-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/Cala-Marmassen-and-Camp-de-Mar-headland-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/Cala-Marmassen-and-Camp-de-Mar-headland-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Deluxe Sa Terra Rotja villa with sea vistas",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
]

const VillaSonBalagueret: React.FC = () => {
    return (
        <ReactAux>
            <HeroSlider
                slides={slides}
                dots={true}
                autoplay={true}
                pauseOnHover={true}
                autoplaySpeed={4000}
                speed={300}
                initialSlide={0}
                noTextSlides={true}
            />

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <div className="mb-s">
                            <p className="small d-inline-flex align-items-center"><a href="/">Home</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex align-items-center"><a href="/">Accommodation</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex align-items-center"><a href="/">Holiday rentals</a><Icon icon="arrowRightS" /></p>
                            <p className="small d-inline-flex">Dreamy luxury at Villa Son Balagueret</p>
                        </div>

                        <ArticleSummary
                            title="Dreamy luxury at Villa Son Balagueret"
                            subTitle="A deluxe retreat on Richard Branson's famous estate"
                            viewsCount="2 922"
                            date="18.02.2019"
                            tags={[
                                { title: 'villas' },
                                { title: 'estate' },
                                { title: 'luxury' },
                            ]}
                            bullets={[
                                { title: 'Son Balagueret is a classic Moorish stone residence' },
                                { title: 'Things to do in/near Banyalbufar' },
                                { title: 'Banyalbufar by night' },
                            ]}
                        />

                        <HotelOverview
                            className="abc-negative-gutter"
                            language="en"
                            priceFrom={2558}
                            priceTo={3665}
                            numberOfBedrooms={4}
                            numberOfBathrooms={4}
                            numberOfPeople={8}
                            distanceFromAirport="34 km"
                            distanceFromBeach="1 km"
                            nearestTown="Banyalbufar"
                            additionalInfo={[
                                {
                                    title: 'Key Features',
                                    list: [
                                        'All drinks including wine, beer, spirits, soft drinks and spanish sparkling wine',
                                        'Daily continental breakfast',
                                        'Wireless internet',
                                        'Daily housekeeping and pool servicing',
                                        'Lunch and dinner 6 days a week tailored to your tastes',
                                    ]
                                },
                                {
                                    title: 'Included services',
                                    list: [
                                        'Fridge stocking',
                                        'Housekeeping',
                                        'Maid service',
                                        'Chef',
                                    ]
                                },
                                {
                                    title: 'On request services',
                                    list: [
                                        'Massage',
                                        'Bike hire',
                                        'Water sports',
                                        'Transfers',
                                        'Beauty',
                                        'Car rental',
                                        'Boat charter',
                                        'Packing service',
                                        'Yoga',
                                        'Personal training',
                                    ]
                                }
                            ]}
                        />

                        <Button className="mb-l w-100">Send your Enquiry now</Button>

                        <h2>Rental villa location</h2>
                        <p>The three <strong>luxurious properties</strong> that comprise the <strong>estate</strong> of <a href="/">Richard Branson</a>‘s&nbsp;Son Bunyola in <a href="/">Banyalbufar</a> have some of the most <strong>magnificent views</strong> of the <strong>Mediterranean</strong> in the <a href="/">Southwest of Mallorca</a>. Located with uninterrupted vistas of the sea and a private beach,&nbsp;<strong>Son Balagueret</strong>&nbsp;is a historic stone <em>casa</em> dating back to the 13th century. The terraced vineyards and olive groves surrounding the villas are part of the <a href="/">Tramuntana</a>&nbsp;mountains, which are protected as a <strong>UNESCO World Heritage Site</strong>. If luxury for you is a peaceful immersion in nature with world class deluxe amenities, <strong>Son Balagueret</strong> is a dreamy family retreat.</p>
                        <h2>Overview of the villa</h2>
                        <p>Son Balagueret is a classic Moorish stone residence with <strong>a beautiful cobblestone courtyard,</strong> a <strong>historic&nbsp;<em>atalaya</em></strong>&nbsp;or <strong>fortress tower</strong>, and restored architectural details. It is equipped with all the<strong> luxurious amenities</strong> to transition you quickly to the modern comforts and conveniences you’d expect from such&nbsp;<strong>five-star accommodation</strong>. The property is fully serviced with staff, including<strong> housekeeping</strong> and&nbsp;<strong>a chef</strong> who prepares three meals daily according to your tastes. The Mediterranean Sea sparkles below and seamlessly meets the expansive blue sky that have long been attracting artistic souls to the region. The property includes <strong>a large swimming pool</strong>, direct access to<strong>&nbsp;hiking trails</strong> and beautiful succulent gardens.</p>
                        <h2>Villa accommodation</h2>
                        <img className="abc-fit-image mb-m" src="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-salon-img01.jpg" alt="salon" />
                        <p>Recently refurbished in classic continental style, the <strong>four air-conditioned bedrooms</strong> are private and located between four floors, each with <strong>breathtaking views</strong> and<strong> marble ensuite bathrooms</strong>. A separate <strong>bedroom suite</strong> is located off the leafy traditional courtyard, while <strong>two bedrooms</strong> are in the historic fortress tower, and a fourth is located on the lower level. Every room is outfitted with <strong>luxury amenities</strong> including portable Bose mini speakers, bathrobes, Bamford toiletries, and USB connections in the headboards. Each room is decorated with <strong>original works of art</strong> from <strong>Richard Branson</strong>‘s personal collection and a carefully curated selection of local artists.</p>
                        <h3>Lower Level</h3>
                        <p>On the property’s lower level is a beautiful <strong>private bedroom</strong> with <strong>ensuite bathroom</strong> that is private and leads to the ground floor.</p>
                        <h3>Ground Floor</h3>
                        <p>Entering into the ground floor from the courtyard, the shared common areas of the <strong>kitchen, dining room</strong> and<strong> living room</strong> are built around the courtyard, creating a seamless transition between the interior and the <strong>leafy shaded garden</strong>. Families can dine al fresco in the<strong> terrace overlooking the sea,</strong> or in the lovely dining room which seats six. The chef’s kitchen is equipped with modern appliances and three meals a day are created based on your culinary preferences by <strong>a personal chef.</strong> A bedroom located on this floor is perfect for those who cannot take the stairs, and has an ensuite bathroom. A guest bathroom and <strong>two intimate living lounges</strong> are decorated with colourful artwork and comfortable sofas.</p>
                        <h3>Upper Floor</h3>
                        <p>On the upper level is a<strong> master bedroom with ensuite bathroom</strong>. In the tower, there is an intimate television snug, and then up a winding flight of stairs you will find another bedroom with an <strong>ensuite bathroom</strong>.</p>
                        <h2>Exterior facilities</h2>
                        <img className="abc-fit-image mb-m" src="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/son-balagueret-piscina-exterior-img02.jpg" alt="exterior" />
                        <p>The grand terrace surrounds the villa, and has outdoor lounges and portico dining areas with breathtaking views of the sea. A dramatic path leads to the <strong>large pool</strong> with<strong> chaise longues</strong> and an <strong>outdoor kitchen</strong> where your <strong>personal chef</strong> can serve lunch, poolside.</p>
                        <h3>Laundry Room</h3>
                        <p>On the terrace overlooking the expansive sea, the plush couches in the <strong>covered outdoor lounge</strong> offer the perfect place to sip chilled cava after a day spent by the pool or on your private beach by the sea. The pool area has an <strong>outdoor kitchen</strong>.</p>
                        <h3>Good to know…</h3>
                        <p>Son Balaguret is 31 kms from the airport. The private beach is a steep climb down to the sea. Spa services and itineraries are available upon request.</p>
                        <h2>Things to do in/near Banyalbufar</h2>
                        <p>Do you love to discover local culinary customs? Hike in the mountains? Swim in sea coves? If you decide to leave the house for an adventure, there is plenty to explore in Banyalbufar on&nbsp;<a href="/">Mallorca</a>.</p>

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Banyalbufar"
                            link={{ attrHref: '/' }}
                            description="Banyalbufar is known for its hundreds of steep terraces dropping to the sea, home of the Malvasia grape and laidback nature."
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/10/banyalbufar-5.jpg',
                            }} />

                        <h2>By day</h2>
                        <p>Robert Graves wrote ‘Good-Bye to All That’ and settled in nearby&nbsp;<strong>Deià</strong>.&nbsp;Go exploring the town, and you will see why. If you love Gothic architecture, close by is the ‘most beautiful town in Mallorca’,&nbsp;<strong>Fornalutx</strong>.</p>

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Deià"
                            link={{ attrHref: '/' }}
                            description="Want to know where artists and media moguls gather in Mallorca? Read more with abcMallorca to find out what makes the mountain village of Deià so special."
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/03/deia-26-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/03/deia-26-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/03/deia-26-300x159.jpg 300w'
                            }} />

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Fornalutx"
                            link={{ attrHref: '/' }}
                            description="Known as the ‘Prettiest village in Spain’, with narrow, cobbled streets it is certainly worth a visit. Four hotels and a few cafes, it is a quiet spot in the Tramuntana and close to Sóller."
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/08/fornalutx-31-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/08/fornalutx-31-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/08/fornalutx-31-300x159.jpg 300w'
                            }} />

                        <h2>By night</h2>
                        <p>Within a few minutes of the estate you will find excellent local gastronomy, from <a href="/">Michelin star restaurants</a> to <strong>small family run tapas bars</strong>, so book a table in one of the wonderful restaurants in the region.</p>

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="1661 Cuina de Banyalbufar"
                            link={{ attrHref: '/' }}
                            description="This is an outstanding family restaurant in the heart of the beautiful village of Banyalbufar. When you eat here you will remember it for years!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2013/05/1661-Cuina-de-Banyalbufar_16-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2013/05/1661-Cuina-de-Banyalbufar_16-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2013/05/1661-Cuina-de-Banyalbufar_16-300x159.jpg 300w'
                            }} />

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Best Restaurants in the Northwest of Mallorca"
                            link={{ attrHref: '/' }}
                            description="This region offers hotel restaurants open to the public, lovely eateries at the beach and others in the beautiful mountains. Have a look at our guide!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/can-xorc-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/can-xorc-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/can-xorc-300x161.jpg 300w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/can-xorc-610x327.jpg 610w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/can-xorc.jpg 620w'
                            }} />

                        <h2>More rental villas in Son Bunyola</h2>

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Luxury Med-style villa rental Sa Punta de S’Aguila"
                            link={{ attrHref: '/' }}
                            description="Part of Richard Branson’s famous Virgin Limited Edition exclusive properties, Sa Punta de S’Aguila is Branson’s personal retreat in Mallorca. Rent it here!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-300x159.jpg 300w'
                            }} />

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Deluxe Sa Terra Rotja villa with sea vistas"
                            link={{ attrHref: '/' }}
                            description="For a rural retreat to rent on Mallorca with private beach, spa services, personal chef and five-star amenities, the dreamy Sa Terra Rotja villa has it all."
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-300x159.jpg 300w'
                            }} />

                        <h2>More villas to rent in Mallorca</h2>

                        <DisplayPost
                            className="abc-negative-gutter"
                            title="Luxury Holiday Villas to rent on Mallorca"
                            link={{ attrHref: '/' }}
                            description="Searching for the ultimate in luxury holiday villa rentals on Mallorca? Here is a hand-picked selection of the best available this year. Your every wish fulfilled!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/Cala-Marmassen-and-Camp-de-Mar-headland-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/Cala-Marmassen-and-Camp-de-Mar-headland-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/Cala-Marmassen-and-Camp-de-Mar-headland-300x159.jpg 300w'
                            }}
                            isMasterPost
                            buttonLink={{ title: 'book now' }} />

                        <p className="sub-title">Send your Enquiry now!</p>
                        <p>To book your vacation at <strong>Villa Son Balagueret in Banyalbufar</strong> call <a href="tel:+34 971 708 888">+34 971 708 888</a>.</p>

                        <div className="abc-negative-gutter mb-l abc-basic-border-wrapper">

                            <div>
                                <img className="mr-s" style={{ float: 'left' }} src="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/01/Villa-Rentals.png" alt="Villa-Rentals" />
                                <p className="sub-title">Send your enquiry about this villa now!</p>
                                <p>Please fill in the form below and we will get back to you shortly with more information.</p>
                                <p>If this villa is occupied we will be delighted to provide you with more options fitting your requirements.</p>
                            </div>

                            <div style={{ clear: 'both' }} />

                            <Row>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="first-name"
                                        label="First name"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="first-name" type="text" name="first-name" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="last-name"
                                        label="Last name"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="last-name" type="text" name="last-name" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="email"
                                        label="E-mail"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="email" type="email" name="email" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="telephone"
                                        label="Telephone"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="telephone" type="phote" name="telephone" />
                                    </FormControl>
                                </Col>
                                <Col md="4">
                                    <FormControl
                                        htmlFor="number-adults"
                                        label="Number of Adults"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="number-adults" type="number" name="number-adults" />
                                    </FormControl>
                                </Col>
                                <Col md="4">
                                    <FormControl
                                        htmlFor="children1"
                                        label="Children (0-2 years)"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="children1" type="number" name="children1" />
                                    </FormControl>
                                </Col>
                                <Col md="4">
                                    <FormControl
                                        htmlFor="children2"
                                        label="Children (2-16 years)"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="children2" type="number" name="children2" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="arrival-date"
                                        label="Arrival date"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Datepicker id="arrival-date" format="" fromYear={new Date().getFullYear()} toYear={new Date().getFullYear() + 2} />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="departure-date"
                                        label="Departure date"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Datepicker id="departure-date" format="" fromYear={new Date().getFullYear()} toYear={new Date().getFullYear() + 2} />
                                    </FormControl>
                                </Col>
                                <Col sm="12">
                                    <FormControl
                                        htmlFor="price-range"
                                        label="Price range"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Select
                                            id="price-range"
                                            options={[
                                                { value: 'Up to 10,000€ per week', text: 'Up to 10,000€ per week' },
                                                { value: '10-15,000€ per week', text: '10-15,000€ per week' },
                                                { value: '15-20,000€ per week', text: '15-20,000€ per week' },
                                                { value: '20-25,000€ per week', text: '20-25,000€ per week' },
                                                { value: 'More than 25,000€ per week', text: 'More than 25,000€ per week' },
                                            ]}
                                            placeholder="Please Select"
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm="12">
                                    <FormControl
                                        htmlFor="special-requests"
                                        label="Special requests"
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Textarea id="special-requests" rows={4} />
                                    </FormControl>
                                </Col>
                            </Row>

                            <Button className="w-100" type="submit">Send enquiry</Button>
                        </div>

                        <p className="small"><strong>Before sending the form, you must read the following basic information about data protection:</strong> The party responsible for processing data is ABC-KNOWLEDGE COMPANY S.L. (abcMallorca). Your data will be processed to deal with your application and in order to comply with our legal obligations. The legitimacy of processing the data is based on managing relations with our users and legal obligations. Data will not be passed on to third parties, unless there is a legal obligation to do so, or in order to deal with your application properly. You are entitled to access, rectify and delete your data, along with other rights, as listed in the <a href="/">privacy policy</a> published on this Web site.</p>
                        <InfoBox className="abc-negative-gutter mb-l">
                            <strong>Disclaimer:</strong> This listing is accurate and the description is given to the best of our knowledge and familiarity with the property and surrounding area. Our listing is to be used as a general guide to the property as we understand it to be, but we cannot guarantee that the facts in regard to the property remain the same after publication. The photographs represent the property and show the features and amenities of the property at the time of writing the description for this website, with the understanding that the villa may have undergone changes or been modified since publication. If you need a more specific information or clarifications before choosing this villa, please let us know, we are happy to answer any questions you may have.
                        </InfoBox>

                    </Col>
                </Row>
            </Container>

            <RelatedArticlesSlider
                title="You might also like"
                slides={slidesArticle}
                dots={true}
            />

        </ReactAux>
    );
}

export default VillaSonBalagueret;
