import React from 'react';

import {
    DisplayPost, Container, Row, Col, HeroSlider,
    HomeSlider, Collections, InfoBox, DisplayCategory,
    BackgroundWrapper, Banner, YoutubeVideo,
} from '@studiouxp/abc-mallorca-portal-ds';

const mainSlider = [
    {
        text: "Spring Editon abcMallorca 2020",
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/08/cala-boquer-pollensa.jpg' } },
    },
    {
        text: "Restaurante Fabiola hosts abcMallorca Business Lunch",
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/mar-de-nudos-featured.jpg' } },
    },
    {
        text: "Mallorca in March",
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/10/carrosa-hotel-mallorca-rural-arta-img01.jpg" } },
    },
    {
        text: "Spring Things: Our Top 16",
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/07/portals-nous.jpg" } },
    },
    {
        text: "New Opening: Sadhana Works in Bendinat",
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/08/cala-mondrago-drone-01.jpg" } },
    },
    {
        text: "New Opening: Sadhana Works in Bendinat",
        image: { imagePath: { src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/calo-des-moro-beach01.jpg" } },
    },
]

const homeSliders = [
    [
        {
            text: "Palma",
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/palma.jpg' } },
        },
        {
            text: "Central",
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/central.jpg' } },
        },
        {
            text: "Southwest",
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Southwest.jpg' } },
        },
        {
            text: "Southeast",
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Southeast.jpg' } },
        },
        {
            text: "Northwest",
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Northwest.jpg' } },
        },
        {
            text: "Northeas",
            image: {
                imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Northeast.jpg' },
                imageLink: { attrHref: 'caimari' }
            }
        }
    ],
    [
        // {
        //     videoCode: 'Y7mVDUvB8lY?rel=0&enablejsapi=1&autoplay=1&mute=1'
        // },
        {
            text: "‘Fira del Ram’ in Palma",
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/fira-ram-palma-02.jpg' } },
        },
        {
            text: "Day of the Balearic Islands",
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/balearics-day.jpg' } },
        },
        {
            text: "Fira del Fang",
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/fira-del-fang.jpg' } },
        },
        {
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/fira-ram-palma-04.jpg' } },
        },
    ],
    [
        {
            text: 'Beachfront  Hotels',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2013/09/Centinelas-2.jpg' } },
        },
        {
            text: 'Family friendly Hotels',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/07/Cabau-Aquasol-Habitacion-Rojo-Vistas.jpg' } },
        },
        {
            text: 'Mallorca’s finest hotel hideaways',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/08/can-simoneta-hotel-01.jpg' } },
        },
        {
            text: 'Top Mallorca Hotels 2020',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/portals-hills-boutique-hotel-img01.jpg' } },
        },
    ],
    [
        {
            text: 'By the sea Restaurants',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/flanigan-restaurant-3.jpg' } },
        },
        {
            text: 'Hidden gems',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/06/pomodOro-restaurant-32.jpg' } },
        },
        {
            text: 'Restaurant Marc Fosh Palma',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2013/05/marc-fosh-5.jpg' } },
        },
        {
            text: 'Melassa Restaurant',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/06/seaview-restaurants-melassa.jpg' } },
        },
    ],
    [
        {
            text: 'Arts & Culture',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/07/AhoyGallery05-Kopie.jpg' } },
        },
        {
            text: 'Beach Clubs',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/06/asaona-restaurant-30-1.jpg' } },
        },
        {
            text: 'Mallorca Sunshine Radio',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/09/Screen-Shot-2015-09-11-at-12.48.46-620x330_c.png' } },
        },
        {
            text: 'Kewenig Gallery',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/11/kewenig-galeria-img001.jpg' } },
        },
    ],
    [
        {
            text: 'Home & Decor',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/30.png' } },
        },
        {
            text: 'Interior Design',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/4.png' } },
        },
        {
            text: 'Emergency Services',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/09/emergency-services-mallorca-02.jpg' } },
        },
        {
            text: 'Family health',
            image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/09/family-health.jpg' } },
        },
    ],
]

const homeCollections = [
    [
        {
            collection: {
                title: 'Cap de Formentor',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/01/cap-formentor-img01-955x505_c.jpg',
            },
            link: {
                attrHref: '/components',
                onClicked: () => console.log('link clicked')
            }
        },
        {
            collection: {
                title: 'Santanyí',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/01/Santanyi-9.jpg',
            }
        },
        {
            action: { title: 'Explore', content: '+168 more' },
            isAction: true,
        },
        {
            collection: {
                title: 'Port d\'Andrtax',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/01/port-andrtax-24.jpg',
            },
        }
    ],
    [
        {
            collection: {
                title: 'Sant Francesc Hotel',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01.jpg',
            },
            link: {
                attrHref: '/components',
                onClicked: () => console.log('link clicked')
            }
        },
        {
            collection: {
                title: 'Palacio Can Marques',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/Eden-955x505_c.jpg',
            }
        },
        {
            action: { title: 'Explore', content: '+168 more' },
            isAction: true,
        },
        {
            collection: {
                title: 'Calatrava Boutique Hotel',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/Suite-completa.jpg',
            },
        }
    ],
    [
        {
            collection: {
                title: 'La Vieja Restaurant',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/06/la-vieja-restaurant-46.jpg',
            },
            link: {
                attrHref: '/components',
                onClicked: () => console.log('link clicked')
            }
        },
        {
            collection: {
                title: 'La Fabrique Restaurant',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/06/smack-restaurant-2.jpg',
            }
        },
        {
            action: { title: 'Explore', content: '+168 more' },
            isAction: true,
        },
        {
            collection: {
                title: 'Mercat 1930 Restaurant',
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/06/mercat-1930-restaurant-palma.jpg',
            },
        }
    ],
    [
        {
            collection: {
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/04/Rockclimbing-sports-mallorca-01.jpg',
            },
            link: {
                attrHref: '/components',
                onClicked: () => console.log('link clicked')
            }
        },
        {
            collection: {
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/04/sports-mallorca-03.jpg',
            }
        },
        {
            action: { title: 'Explore', content: '+168 more' },
            isAction: true,
        },
        {
            collection: {
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/04/sports-mallorca-02.jpg',
            },
        }
    ],
    [
        {
            collection: {
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/09/emergency-services-mallorca-02.jpg',
            },
            link: {
                attrHref: '/components',
                onClicked: () => console.log('link clicked')
            }
        },
        {
            collection: {
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/08/emergency-services-mallorca.jpg',
            }
        },
        {
            action: { title: 'Explore', content: '+168 more' },
            isAction: true,
        },
        {
            collection: {
                img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/09/family-health.jpg',
            },
        }
    ]
]

const homeCollectionsTitles = [
    'Places', 'Hotels', 'Restaurants', 'Activities', 'Services'
]

const homeSlidersTitles = [
    'Regions', 'Events', 'Hotels', 'Restaurants', 'Activities', 'Services'
]

const Home: React.FC = () => {
    return (
        <>
            <HeroSlider
                slides={mainSlider}
                dots={true}
                autoplay={false}
                pauseOnHover={true}
                autoplaySpeed={3000}
                speed={300}
                initialSlide={0}
                infinite={false}
            />

            <Container>
                <Row>
                    <Col>
                        <Banner
                            className="mb-l"
                            url={{ onClicked: () => alert('Magazine!') }}
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg'
                            }}
                            sponsoredText="SPONSORED"
                        />
                    </Col>
                </Row>
            </Container>

            {
                homeSliders.map((s, i) => {
                    return (
                        <HomeSlider
                            key={i}
                            slides={s}
                            dots={true}
                            autoplay={false}
                            pauseOnHover={true}
                            autoplaySpeed={3000}
                            speed={300}
                            initialSlide={0}
                            infinite={false}
                            showMultipleSlides={true}
                            title={homeSlidersTitles[i]}
                            isBackground={i % 2 === 0}
                        />
                    )
                })
            }

            <BackgroundWrapper className="mb-l pb-0">
                {
                    homeCollections.map((c, i) => {
                        return <Collections key={i} className="abc-negative-gutter" collections={c} title={homeCollectionsTitles[i]} />;
                    })
                }
            </BackgroundWrapper>

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <DisplayCategory
                            className="abc-negative-gutter"
                            category={{
                                title: 'About Mallorca'
                            }}
                            articles={[
                                {
                                    title: 'Santa Ponsa',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/03/Santaponsa-004.jpg',

                                    },
                                    description: 'The relaxed setting has beautiful views and the food is fresh and healthy Mediterranean cuisine at this five-star hotel restaurant in the north of Mallorca. ',
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Maintain a Healthy Business in Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/03/Santaponsa-003.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Top 10 tips for relocating to Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/03/Santaponsa-003.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Making the most of your rental property',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2012/03/Santaponsa-003.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                            ]} />
                        <DisplayCategory
                            className="abc-negative-gutter"
                            category={{
                                title: 'Hotels'
                            }}
                            articles={[
                                {
                                    title: 'Top Mallorca Hotels 2020',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-430x230.jpg',
                                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-300x159.jpg 300w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-620x330_c.jpg 620w'
                                    },
                                    description: 'Mallorca offers visitors a vast choice of hotels and accommodation, whether you are here for a beach holiday, cycling trip or city break in Palma.',
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: '19 luxury hotels with great restaurants on Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/02/astir-restaurant-14-80x80.jpg',
                                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/02/astir-restaurant-14-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/02/astir-restaurant-14-150x150.jpg 150w'
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Best Boutique Hotels on Mallorca 2020',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-80x80.jpg',
                                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-150x150.jpg 150w'
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Top 21 most romantic hotels on Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Terraza-Maricel-01-80x80.jpg',
                                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Terraza-Maricel-01-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Terraza-Maricel-01-150x150.jpg 150w'
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                            ]} />

                        <DisplayCategory
                            className="abc-negative-gutter"
                            category={{
                                title: 'Restaurants',
                                link: { attrHref: '#', attrTargetBlank: true }
                            }}
                            articles={[
                                {
                                    title: 'Best Restaurants in the Southwest of Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg',
                                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-300x159.jpg 300w'
                                    },
                                    description: 'The relaxed setting has beautiful views and the food is fresh and healthy Mediterranean cuisine at this five-star hotel restaurant in the north of Mallorca. ',
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Mallorca’s new restaurant openings 2019',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg',
                                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-150x150.jpg 150w'
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Mallorca’s most romantic restaurants',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg',
                                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-150x150.jpg 150w'
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Wine & Gourmet',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg',
                                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-150x150.jpg 150w'
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                }
                            ]} />

                        <DisplayCategory
                            className="abc-negative-gutter"
                            category={{
                                title: 'Activities',
                                link: { attrHref: '#', attrTargetBlank: true }
                            }}
                            articles={[
                                {
                                    title: 'Health and Wellness Guide to Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01.jpg',
                                    },
                                    description: 'The relaxed setting has beautiful views and the food is fresh and healthy Mediterranean cuisine at this five-star hotel restaurant in the north of Mallorca. ',
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Yachting on Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Centinelas-2.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Best Sightseeing on Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Centinelas-2.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Best Restaurants in the Southwest of Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Centinelas-2.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                }
                            ]} />

                        <DisplayCategory
                            className="abc-negative-gutter"
                            category={{
                                title: 'Services'
                            }}
                            articles={[
                                {
                                    title: 'Setting up a Business in Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/01/tax-planning-mallorca-2019-img01-430x330.jpg'
                                    },
                                    description: 'The relaxed setting has beautiful views and the food is fresh and healthy Mediterranean cuisine at this five-star hotel restaurant in the north of Mallorca. ',
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Emergency Services in Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/09/emergency-services-mallorca-02.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Alphatrad Spain',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/08/emergency-services-mallorca.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                                {
                                    title: 'Top 21 most romantic hotels on Mallorca',
                                    image: {
                                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/08/emergency-services-mallorca.jpg',
                                    },
                                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                },
                            ]} />

                        <DisplayPost
                            className="abc-negative-gutter mb-m"
                            title="Top 19 beachfront luxury hotels on Mallorca"
                            link={{ attrHref: '/docs' }}
                            description="Stay at one of the 14 unique rooms in this extraordinary adults-only hotel in Lloseta, Serra de Tramuntana. Enjoy the beautiful scenery, homemade food and relaxing spa. Book NOW!"
                            image={{
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg',
                                srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-300x159.jpg 300w'
                            }}
                            categoryTitle="Trends"
                            buttonLink={{ title: 'book now', attrHref: '#', onClicked: () => alert('Is clicked!') }} />

                        <p className='text-center mb-l'><a href='/'>More about Mallorca</a></p>
                        <InfoBox className="abc-negative-gutter mb-l" noBackground>
                            <strong>Welcome to the beautiful island of Mallorca</strong>
                            Mallorca (or Majorca) is the largest of Spain’s Balearic Islands, with a long and fascinating history. Outside Palma, Mallorca’s capital city, highlights include Valldemossa, Serra de Tramuntana and Sóller. In the north, Alcúdia has Roman remains, Cap Formentor offers breathtaking views, and there’s the attractive town and port of Pollensa. In the southwest, visit Santa Ponsa, Andratx town and port, and the luxury marina of Puerto Portals. East coast resorts include Cala d’Or and the tranquil former fishing village of Porto Colom. The Mediterranean climate makes Mallorca a great destination for different types of holiday throughout the year and guides and tourist information are available on this site.
                        </InfoBox>
                    </Col>
                </Row>
            </Container>

            <BackgroundWrapper className="mb-0 abc-pre-footer-pb" appearance="primary">
                <Banner
                    className="mb-l abc-negative-gutter"
                    title="Read our magazine"
                    url={{ onClicked: () => alert('Magazine!') }}
                    image={{
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/magazine-online_whatson_2020_EN.jpg',
                    }}
                    buttonText="read now"
                />
                <Banner
                    className="mb-l abc-negative-gutter"
                    title="Videos"
                    url={{ onClicked: () => alert('Magazine!') }}
                    video={<YoutubeVideo
                        videoUrl="https://www.youtube.com/embed/6qErv1uzC-U" />}
                />
                <Banner
                    className="mb-l abc-negative-gutter"
                    title="At your service"
                    url={{ onClicked: () => alert('At your service!') }}
                    image={{
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/knox-design-banner-2020.gif',
                    }}
                />
                <Banner
                    className="mb-l abc-negative-gutter"
                    title=""
                    url={{ onClicked: () => alert('At your service 2!') }}
                    image={{
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/blevins-franks-banner-2019-12.jpg',
                    }}
                />
            </BackgroundWrapper>
        </>
    )
}

export default Home
