import React from 'react';
import Highlight from 'react-highlight';

import { DisplayCategory } from '@studiouxp/abc-mallorca-portal-ds';

const DisplayCategoryPage: React.FC = () => {
    return (
        <div>
            <h1>Display Category</h1>

            <DisplayCategory
                category={{
                    title: 'Restaurant News',
                    link: { attrHref: '#', attrTargetBlank: true }
                }}
                articles={[
                    {
                        title: 'Foodie heaven at Saranna Café & Gastrobar',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg',
                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-300x159.jpg 300w'
                        },
                        description: 'Saranna Café & Gastrobar and its delicious home-made cuisine is making waves, from breakfast and brunch to incredible burgers!',
                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                    },
                    {
                        title: 'Michelin-star greatness at VORO',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg',
                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-150x150.jpg 150w'
                        },
                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                    },
                    {
                        title: 'Seafood with sea views at newly opened Salicornia',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg',
                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-150x150.jpg 150w'
                        },
                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                    }
                ]} />
            
            <DisplayCategory
                category={{
                    title: 'DisplayCategory with background',
                    link: { attrHref: '#', attrTargetBlank: true }
                }}
                articles={[
                    {
                        title: 'Foodie heaven at Saranna Café & Gastrobar',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg',
                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-300x159.jpg 300w'
                        },
                        description: 'Saranna Café & Gastrobar and its delicious home-made cuisine is making waves, from breakfast and brunch to incredible burgers!',
                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                    },
                    {
                        title: 'Michelin-star greatness at VORO',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg',
                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-150x150.jpg 150w'
                        },
                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                    },
                    {
                        title: 'Seafood with sea views at newly opened Salicornia',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg',
                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-150x150.jpg 150w'
                        },
                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                    }
                ]}
                hasBackground
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { DisplayCategory } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <DisplayCategory
            category={{
                title: 'Restaurant News',
                link: { attrHref: '#', attrTargetBlank: true }
            }}
            articles={[
                {
                    title: 'Foodie heaven at Saranna Café & Gastrobar',
                    image: {
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg',
                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-300x159.jpg 300w'
                    },
                    description: 'Saranna Café & Gastrobar and its delicious home-made cuisine is making waves, from breakfast and brunch to incredible burgers!',
                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                },
                {
                    title: 'Michelin-star greatness at VORO',
                    image: {
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg',
                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-150x150.jpg 150w'
                    },
                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                },
                {
                    title: 'Seafood with sea views at newly opened Salicornia',
                    image: {
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg',
                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-150x150.jpg 150w'
                    },
                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                }
            ]} />
        
        <DisplayCategory
            category={{
                title: 'DisplayCategory with background',
                link: { attrHref: '#', attrTargetBlank: true }
            }}
            articles={[
                {
                    title: 'Foodie heaven at Saranna Café & Gastrobar',
                    image: {
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg',
                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-300x159.jpg 300w'
                    },
                    description: 'Saranna Café & Gastrobar and its delicious home-made cuisine is making waves, from breakfast and brunch to incredible burgers!',
                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                },
                {
                    title: 'Michelin-star greatness at VORO',
                    image: {
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg',
                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-150x150.jpg 150w'
                    },
                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                },
                {
                    title: 'Seafood with sea views at newly opened Salicornia',
                    image: {
                        src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg',
                        srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-150x150.jpg 150w'
                    },
                    link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                }
            ]}
            hasBackground
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>category</td>
                        <td className="text-center">
                            Object with props: <br />
                            "title": string, "link": object with props: "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the title and url for him.</td>
                    </tr>
                    <tr>
                        <td>articles</td>
                        <td className="text-center">
                            Array of objects.<br />
                            Object must have props:<br />
                            "title": string, "image": object with props: "src": string, "srcSet": string, "description": string, "link": object with props: "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the articles. First of the array is display on left.</td>
                    </tr>
                    <tr>
                        <td>hasBackground</td>
                        <td className="text-center">boolean</td>
                        <td className="text-center">false</td>
                        <td>Defines if the DisplayCategory element has a background color.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DisplayCategoryPage;
