import React, { useState } from 'react';
import Highlight from 'react-highlight';

import { ArticleSummary } from '@studiouxp/abc-mallorca-portal-ds';

const ArticleSummaryPage: React.FC = () => {

    const [select1, setSelect1] = useState(false);
    const [select2, setSelect2] = useState(true);

    return (
        <div>
            <h1>Article Summary</h1>

            <ArticleSummary
                id="article-summary"
                title="Palma de Mallorca"
                subTitle="Your ultimate guide to Mallorca's capital city. What to do, see, shop & love in Palma"
                viewsCount="11 243"
                date="01.03.2020"
                tags={[
                    { title: 'nightlife' },
                    { title: 'cycling' },
                    { title: 'summer' },
                ]}
                bookBtn={{ title: 'Book', onClicked: () => console.log('bookBtn') }}
                addToPlanBtn={{ title: 'Plan', isSelected: select1, onClicked: () => { setSelect1(!select1); console.log('addToPlanBtn') } }}
                addToFavouritesBtn={{ title: 'Favourites', isSelected: select2, onClicked: () => { setSelect2(!select2); console.log('addToFavouritesBtn') } }}
                shareBtn={{ title: 'Share', onClicked: () => console.log('shareBtn') }}
                bulletTitle="How can we help?"
                bullets={[
                    { title: 'Your ultimate guide to Mallorca’s capital city. ' },
                    { title: 'A 10th century Muslim fortress rebuilt in the Gothic style by the Christians' },
                    { title: 'Discover Santa Marina!', url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') } },
                ]}
            />

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { ArticleSummary } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    const [select1, setSelect1] = useState(false);
    const [select2, setSelect2] = useState(true);

    return (
        <ArticleSummary
            id="article-summary"
            title="Palma de Mallorca"
            subTitle="Your ultimate guide to Mallorca's capital city. What to do, see, shop & love in Palma"
            viewsCount="11 243"
            date="01.03.2020"
            tags={[
                { title: 'nightlife' },
                { title: 'cycling' },
                { title: 'summer' },
            ]}
            bookBtn={{ title: 'Book', onClicked: () => console.log('bookBtn') }}
            addToPlanBtn={{ title: 'Plan', isSelected: select1, onClicked: () => { setSelect1(!select1); console.log('addToPlanBtn') } }}
            addToFavouritesBtn={{ title: 'Favourites', isSelected: select2, onClicked: () => { setSelect2(!select2); console.log('addToFavouritesBtn') } }}
            shareBtn={{ title: 'Share', onClicked: () => console.log('shareBtn') }}
            bulletTitle="How can we help?"
            bullets={[
                { title: 'Your ultimate guide to Mallorca’s capital city. ' },
                { title: 'A 10th century Muslim fortress rebuilt in the Gothic style by the Christians' },
                { title: 'Discover Santa Marina!', url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') } },
            ]}
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the id of the container div</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>subTitle</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the subTitle.</td>
                    </tr>
                    <tr>
                        <td>viewsCount</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the text for viewsCount.</td>
                    </tr>
                    <tr>
                        <td>date</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the text for date.</td>
                    </tr>
                    <tr>
                        <td>tags</td>
                        <td className="text-center">
                            Array of objects with props:<br />
                            "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the tags.</td>
                    </tr>
                    <tr>
                        <td>bookBtn</td>
                        <td className="text-center">
                            Object with props:<br />
                            "onClicked": void
                        </td>
                        <td className="text-center">-</td>
                        <td>Set Book button.</td>
                    </tr>
                    <tr>
                        <td>addToPlanBtn</td>
                        <td className="text-center">
                            Object with props:<br />
                            "isSelected": boolean,
                            "onClicked": void
                        </td>
                        <td className="text-center">-</td>
                        <td>Set Add to plan button.</td>
                    </tr>
                    <tr>
                        <td>addToFavouritesBtn</td>
                        <td className="text-center">
                            Object with props:<br />
                            "isSelected": boolean,
                            "onClicked": void
                        </td>
                        <td className="text-center">-</td>
                        <td>Set Add to favourites button.</td>
                    </tr>
                    <tr>
                        <td>shareBtn</td>
                        <td className="text-center">
                            Object with props:<br />
                            "onClicked": void
                        </td>
                        <td className="text-center">-</td>
                        <td>Set Share button.</td>
                    </tr>
                    <tr>
                        <td>bulletTitle</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the bulletTitle.</td>
                    </tr>
                    <tr>
                        <td>bullets</td>
                        <td className="text-center">
                            Array of objects with props:<br />
                            "title": string <hr />
                            "url": object with props : "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the bullets.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ArticleSummaryPage;
