import React from 'react';
import Highlight from 'react-highlight';

import { Toast, Button } from '@studiouxp/abc-mallorca-portal-ds';

const toastSuccess = () => Toast.success('Success message here!', {
    icon: true
});

const toastSuccessWithIconContentAction = () => Toast.success('Success message here!',
    {
        icon: true,
        action: {
            text: 'retry',
            onClicked: () => console.log('clicked reload success')
        }
    });
const toastSuccessWithLongText = () => Toast.success('Long message that dissapeas automatically and has a button link.');
const toastSuccessWithLongTextAction = () => Toast.success('Long message that dissapeas automatically and has a button link.', {
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload success')
    }
});

const toastSuccessWithProps = () => Toast.success('Long message that do not dissapeas automatically and has a button link.', {
    autoClose: false,
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload success')
    }
});
const toastDanger = () => Toast.danger('Danger message here!', {
    icon: true
});

const toastDangerWithProps = () => Toast.danger('Danger message here!', {
    autoClose: false,
    icon: true,
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload danger')
    }
});

const toastWarning = () => Toast.warning('Warning message here!', {
    icon: true
});

const toastWarningWithProps = () => Toast.warning('Warning message here!', {
    autoClose: false,
    icon: true,
});

const toastInfo = () => Toast.info('Info message here!', {
    icon: true
});

const toastInfoWithProps = () => Toast.info('Long message that do not dissapeas automatically and has a button link.', {
    autoClose: false,
});


const ToastPage: React.FC = () => {
    return (
        <div>
            <h1>Toast</h1>

            <h2>Description</h2>
            <p>
                Utilizing <a href="https://fkhadra.github.io/react-toastify/introduction/" >React Tostify</a> lib.
            </p>

            <h3>Toast types</h3>
            <p>
                There are 4 types of Toasts: "success", "danger", "warning" and "info".
                 <br /> Click on the buttons to show the toast example.
		    </p>

            <h3>Design Guidelines</h3>
            <p>
                <strong>Use disappearing toasts</strong> for indication that an action has been completed <br/>
                Example: Added to Plan; Added to favourites <br/>
                <strong>Use toasts with CTA</strong> when user might need to act - mostly on an action that could not be completed<br/>
                <strong>Use toast with icon</strong> when the text is short<br/>
                Example: Success toast with message ‘Added to Plan.’<br/>
                <strong>Avoid using all elements at once - icon, close button, long text and CTA</strong>
            </p>

            <div>
                <h3>Default with small content</h3>
                <div className="mb-m">
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccess}>Success toast</Button>
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastDanger}>Danger toast</Button>
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastWarning}>Warning toast</Button>
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastInfo}>Info toast</Button>
                </div>

                <h3>With content, icon and action</h3>
                <div className="mb-m">
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithIconContentAction}>Success toast with icon, content, action</Button>
                </div>

                <h3>With long text</h3>
                <div className="mb-m">
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithLongText}>Success toast with long text</Button>
                </div>
                <h3>With long text and action</h3>
                <div className="mb-m">
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithLongTextAction}>Success toast with long text and action</Button>
                </div>
                <h3>Without auto close</h3>
                <div>
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithProps}>Success toast with no autoClose</Button>
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastDangerWithProps}>Danger toast with no autoClose</Button>
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastWarningWithProps}>Warning toast with no autoClose</Button>
                    <Button className="mr-s mb-s" appearance='secondary' onClick={toastInfoWithProps}>Info toast with no autoClose</Button>
                </div>

                <h3>Check how Toasts are positioning when there is action bar <a href="/components">here</a></h3>
            </div>



            <Highlight className="React">
                {
                    `
import React from 'react';
import { Toast, Button } from '@studiouxp/abc-mallorca-portal-ds';

const toastSuccess = () => Toast.success('Success message here!', {
    icon: true
});

const toastSuccessWithIconContentAction = () => Toast.success('Success message here!',
    {
        icon: true,
        action: {
            text: 'retry',
            onClicked: () => console.log('clicked reload success')
        }
    });
const toastSuccessWithLongText = () => Toast.success('Long message that dissapeas automatically and has a button link.');
const toastSuccessWithLongTextAction = () => Toast.success('Long message that dissapeas automatically and has a button link.', {
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload success')
    }
});

const toastSuccessWithProps = () => Toast.success('Long message that do not dissapeas automatically and has a button link.', {
    autoClose: false,
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload success')
    }
});
const toastDanger = () => Toast.danger('Danger message here!', {
    icon: true
});

const toastDangerWithProps = () => Toast.danger('Danger message here!', {
    autoClose: false,
    icon: true,
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload danger')
    }
});

const toastWarning = () => Toast.warning('Warning message here!', {
    icon: true
});

const toastWarningWithProps = () => Toast.warning('Warning message here!', {
    autoClose: false,
    icon: true,
});

const toastInfo = () => Toast.info('Info message here!', {
    icon: true
});

const toastInfoWithProps = () => Toast.info('Long message that do not dissapeas automatically and has a button link.', {
    autoClose: false,
});



const Example: React.FC = (props) => {
    return (
          //Default with small content
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccess}>Success toast</Button>
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastDanger}>Danger toast</Button>
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastWarning}>Warning toast</Button>
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastInfo}>Info toast</Button>

          //With content, icon and action
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithIconContentAction}>Success toast with icon, content, action</Button>

          //With long text
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithLongText}>Success toast with long text</Button>

          //With long text and action
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithLongTextAction}>Success toast with long text and action</Button>

         //Without auto close
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithProps}>Success toast with no autoClose</Button>
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastDangerWithProps}>Danger toast with no autoClose</Button>
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastWarningWithProps}>Warning toast with no autoClose</Button>
            <Button className="mr-s mb-s" appearance='secondary' onClick={toastInfoWithProps}>Info toast with no autoClose</Button>
    );
}

export default Example;
    `}
            </Highlight>



            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Methods</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Props</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>configure</td>
                        <td>options</td>
                        <td>Initialize toasts. Should be called once. Preferably, at the root of the app.</td>
                    </tr>
                    <tr>
                        <td>success</td>
                        <td>content, options</td>
                        <td>Display a success toast.</td>
                    </tr>
                    <tr>
                        <td>danger</td>
                        <td>content, options</td>
                        <td>Display a danger toast.</td>
                    </tr>
                    <tr>
                        <td>warning</td>
                        <td>content, options</td>
                        <td>Display a warning toast.</td>
                    </tr>
                </tbody>
            </table>

            <table className="doc-table mb-m">
                <thead>
                    <tr>
                        <th colSpan={4}>Methods Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Parameter</strong>
                        </td>
                        <td>
                            <strong>Type</strong>
                        </td>
                        <td>
                            <strong>Required</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>content</td>
                        <td>string</td>
                        <td>Yes</td>
                        <td>Element that will be displayed.</td>
                    </tr>
                    <tr>
                        <td>options</td>
                        <td>object</td>
                        <td>No</td>
                        <td><a href="#optionProps">Options list.</a></td>
                    </tr>
                </tbody>
            </table>

            <table className="doc-table mb-m" id="optionProps">
                <thead>
                    <tr>
                        <th colSpan={4}>Option Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets the icon before the content of the toast.</td>
                    </tr>
                    <tr>
                        <td>action</td>
                        <td>
                            Object with props:<br />
                            "text": string,<br />
                            "onClicked": function
                        </td>
                        <td className='text-center'>-</td>
                        <td>Sets the action button text and onClick function.</td>
                    </tr>
                    <tr>
                        <td>autoClose</td>
                        <td>boolean || number</td>
                        <td>5000</td>
                        <td>Sets time for auto closing the Tooltip.</td>
                    </tr>
                    <tr>
                        <td>hideProgressBar</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Hide progress bar.</td>
                    </tr>
                    <tr>
                        <td>closeOnClick</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>Close Toast when click on it.</td>
                    </tr>
                    <tr>
                        <td>pauseOnHover</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>Pause auto closing Toast on hover.</td>
                    </tr>
                    <tr>
                        <td>draggable</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>Close Toast when drag it.</td>
                    </tr>
                    <tr>
                        <td>position</td>
                        <td> "top-left", "top-right", "top-center" , "bottom-left", "bottom-right", "bottom-center"</td>
                        <td>bottom-center</td>
                        <td><strong>By design the position of the Toast is preset, do not change the default position.</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ToastPage;
