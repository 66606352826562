import React from 'react';

import {
    Container, Row, Col, FormControl, Input, Datepicker, 
    BackgroundWrapper,Button
} from '@studiouxp/abc-mallorca-portal-ds';

const EditProfile: React.FC = () => {

    return (
        <>
            <BackgroundWrapper>
                <h1 className="mb-0">Edit Profile</h1>
            </BackgroundWrapper>
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <form>
                            <Row>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="first-name"
                                        label="First name"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="first-name" type="text" name="first-name" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="last-name"
                                        label="Last name"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="last-name" type="text" name="last-name" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="email"
                                        label="E-mail"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="email" type="email" name="email" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="telephone"
                                        label="Telephone"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="telephone" type="phote" name="telephone" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="arrival-date"
                                        label="Date of birth"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Datepicker id="date-of-birth" inputProps={{ name: "data-of-birth" }} format="" fromYear={new Date().getFullYear()} toYear={new Date().getFullYear() + 2} />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <FormControl
                                        htmlFor="password"
                                        label="Password"
                                        isRequired
                                        isValid={true}
                                        validationMessage="This field is required">
                                        <Input id="password" type="password" name="password" />
                                    </FormControl>
                                </Col>
                                <Col md="6">
                                    <Button type="submit" className="w-100 mb-s">save changes</Button>
                                </Col>
                                <Col md="6">
                                    <Button type="button" className="w-100 mb-s" appearance="secondary">cancel</Button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default EditProfile;
