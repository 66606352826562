import React from 'react';

import {
    Row, Col, Container, Icon, Button, OpeningHours, DisplayPost, Footer,
    FormControl, Input, Textarea, Select, RadioButton, Checkbox, Datepicker,
    GoogleMapAddress, ContactDetails, DisplayCategory, HeroSlider, YoutubeVideo, RelatedArticlesSlider,
    Banner, InfoBox, EventPreview, HomeSlider, ThumbnailsList, HotelOverview, FilterWrapper, Header,
    ActionBar, Socials, Toggle, ArticleSummary, BackgroundWrapper, Modal, Cookies, Collections, SelectCards,
    ChoiceButtons, Hero, LinkBox, WeatherWidget, Toast, TestimonialsSlider, Service
} from '@studiouxp/abc-mallorca-portal-ds';

const LogoPrimary = require('../../images/logo-primary.svg');

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const slides = [
    {
        text: "Test video 1",
        videoCode: '0p-vXwu0xZk?rel=0&enablejsapi=1&autoplay=1&mute=1',
    },
    {
        text: "",
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_platos-56.jpg' } },
    },
    {
        text: "Test 3",
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/el-pesquero-restaurant-exterior-955x505_c.jpg' } },
    },
    {
        text: "Test 4",
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/SALMON-copia.jpg' } },
    },
    {
        text: "Test 5",
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_interior_25-955x505_c.jpg' } },
    },
    {
        text: "Test 6",
        image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/el-pesquero-restaurant-exterior-evening.jpg' } },
    },
    {
        text: "Test 7",
        image: {
            imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_platos-33.jpg' },
            imageLink: { attrHref: 'test' }
        }
    }
];

const collections = [
    {
        collection: {
            title: 'Plan my trip',
            content: '6 articles',
            img: 'https://www.abc-mallorca.es/wp-content/uploads/2018/09/Hotel.jpg',
        },
        link: {
            attrHref: '/components',
            onClicked: () => console.log('link clicked')
        }
    },
    {
        collection: {
            title: 'Hottest restaurants',
            content: '3 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/isaan-restaurant-40-430x330.jpg',
        }
    },
    {
        collection: {
            title: 'Favourites',
            content: '10 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/08/fosh-lab-01-430x330.jpg',
        },
    },
    {
        collection: {
            title: 'Favourite museums',
            content: '3 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2017/11/joan-miro-visit-museum-mallorca-img03.jpg',
        },
    },
    {
        collection: {
            title: 'To read later',
            content: '0 articles',
        },
    },
    {
        collection: {
            title: 'Valentine\'s place',
            content: '2 articles',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/11/valentine-day-february.jpg',
        },
    },
    {
        action: { title: 'Add new', icon: 'newCollectionWhiteL' },
        isAction: true,
    },
];

const collectionsTitle = [
    {
        collection: {
            title: 'Cap de Formentor',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/01/cap-formentor-img01-955x505_c.jpg',
        },
        link: {
            attrHref: '/components',
            onClicked: () => console.log('link clicked')
        }
    },
    {
        collection: {
            title: 'Santanyí',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/01/Santanyi-9.jpg',
        }
    },
    {
        action: { title: 'Explore', content: '+168 more' },
        isAction: true,
    },
    {
        collection: {
            title: 'Port d\'Andrtax',
            img: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/01/port-andrtax-24.jpg',
        },
    }
];

const slidesArticle = [
    {
        text: "Best spa and wellness hotels on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Top 19 beachfront luxury hotels on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Best Boutique Hotels on Mallorca 2020",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Luxury Med-style villa rental Sa Punta de S'Aguila",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
    {
        text: "Deluxe Sa Terra Rotja villa with sea vistas",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg',
            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-300x159.jpg 300w'
        },
        url: { attrHref: '#' },
    },
];

const testimonialsArticle = [
    {
        title: "Andrea Pussin",
        subTitle: "Organic Studio",
        text: "One of the key factor of the success in securing new clients for Organic Studio from abcMallorca was them being able to provide high quality, perfectly styled images of projects completed which could be featured online and in the magazine. One of the key factor of the success in securing new clients for Organic Studio from abcMallorca was them being able to provide high quality, perfectly styled images of projects completed which could be featured online and in the magazine.",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/park-hiatt-hotel-spa-img01-430x230.jpg',
        },
        url: {
            attrHref: '#' 
        },
    },
    {
        text: "Top 19 beachfront luxury hotels on Mallorca",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/melbeach-hotel-05-430x230.jpg',
        }
    },
    {
        text: "Best Boutique Hotels on Mallorca 2020",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-430x230.jpg',
        },
    },
    {
        text: "Luxury Med-style villa rental Sa Punta de S'Aguila",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-punta-property-img06-430x230.jpg',
        },
    },
    {
        text: "Deluxe Sa Terra Rotja villa with sea vistas",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/05/sa-terra-rotja-property-img03-430x230.jpg',
        },
    },
]

const toogleHeaderBtnText = [
    'SHOW INNER PAGE HEADER',
    'SHOW  DEFAULT HEADER'
];

const selectCards = [
    {
        title: "Pasta of the day - 12.99 Euro",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/Sandro-22-1.jpg',
            srcSet: ''
        },
        description: "The relaxed setting has beautiful views and the food is fresh and healthy Mediterranean cuisine at this five-star hotel restaurant in the north of Mallorca.",
        isSelected: true,
        checkBoxTitle: "Select",
        descLessBtnText: "See less",
        descMoreBtnText: "Read more",
        footer :{
            icon: 'priceSecondaryS',
            text: 'Price: 9 to 12 euro'
        }
    },
    {
        title: "Burger - 9.90 Euro",
        image: {
            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01.jpg',
            srcSet: ''
        },
        description: "The relaxed setting has beautiful views and the food is fresh and healthy Mediterranean cuisine at this five-star hotel restaurant in the north of Mallorca.",
        isSelected: false,
        checkBoxTitle: "Select",
        descLessBtnText: "See less",
        descMoreBtnText: "Read more",
    }
];

const actionBtnTextTypes = {
    'Plan': [
        {
            title: 'My Trip',
            icon: 'myTripWhiteM',
            onCliked: () => console.log('my trip'),
        },
        {
            title: 'Hotel',
            icon: 'hotelsWhiteM',
            onCliked: () => console.log('hotel'),
        },
        {
            title: 'Restaurant',
            icon: 'restaurantsWhiteM',
            onCliked: () => console.log('restaurant'),
        },
        {
            title: 'Activity',
            icon: 'activitiesWhiteM',
            onCliked: () => console.log('activity'),
        },
        {
            title: 'Service',
            icon: 'servicesWhiteM',
            onCliked: () => console.log('service'),
        }
    ],
    'Sort': [
        {
            title: 'by Name',
            icon: 'byNameWhiteM',
            onCliked: () => console.log('by Name'),
        },
        {
            title: 'by Date',
            icon: 'calendarWhiteM',
            onCliked: () => console.log('by Date'),
        },
        {
            title: 'by Distance',
            icon: 'locationWhiteM',
            onCliked: () => console.log('by Distance'),
        }
    ],
    'Book': [
        {
            title: 'Book',
            icon: 'bookWhiteM',
            onCliked: () => console.log('book'),
        },
        {
            title: 'Itineraries',
            icon: 'itinerariesWhiteM',
            onCliked: () => console.log('Itineraries'),
        },
        {
            title: 'Favourites',
            icon: 'favouritesWhiteM',
            onCliked: () => console.log('favourites'),
        },
        {
            title: 'Share',
            icon: 'shareWhiteM',
            onCliked: () => console.log('share'),
        },
    ]
}


const choiceButtons = [
    {
        value: 1,
        title: '1',
    },
    {
        value: 2,
        checked: true
    },
    {
        value: 3,
    },
    {
        value: 4,
    },
    {
        value: 5,
    },
    {
        value: 6,
    },
    {
        value: 7,
    },
    {
        value: 8,
    }
];



const choiceButtonsSecond = [
    {
        value: "09:00",
        // disabled:true,
    },
    {
        value: "10:30",
    },
    {
        value: "11:15",
    },
    {
        value: "14:45",
    },
    {
        value: "18:22",
    },
    {
        value: "19:45",
    },
    {
        value: "22:30",
    },
    {
        value: "23:45",
    }
];

const updateCheckedChoices = (array: [], targetValue: any) => {
    return array.map((el: any) => {
        if (el.value.toString() === targetValue) {
            el['checked'] = true;
        } else {
            delete el.checked;
        }
        return el;
    });
}

// Demo toasts
const toastSuccess = () => Toast.success('Success message here!', {
    icon: true
});

const toastSuccessWithIconContentAction = () => Toast.success('Success message here!',
    {
        icon: true,
        action: {
            text: 'retry',
            onClicked: () => console.log('clicked reload success')
        }
    });
const toastSuccessWithLongText = () => Toast.success('Long message that dissapeas automatically and has a button link.');
const toastSuccessWithLongTextAction = () => Toast.success('Long message that dissapeas automatically and has a button link.', {
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload success')
    }
});

const toastSuccessWithProps = () => Toast.success('Long message that do not dissapeas automatically and has a button link.', {
    autoClose: false,
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload success')
    }
});
const toastDanger = () => Toast.danger('Danger message here!', {
    icon: true
});

const toastDangerWithProps = () => Toast.danger('Danger message here!', {
    autoClose: false,
    icon: true,
    action: {
        text: 'retry',
        onClicked: () => console.log('clicked reload danger')
    }
});

const toastWarning = () => Toast.warning('Warning message here!', {
    icon: true
});

const toastWarningWithProps = () => Toast.warning('Warning message here!', {
    autoClose: false,
    icon: true,
});

const toastInfo = () => Toast.info('Info message here!', {
    icon: true
});

const toastInfoWithProps = () => Toast.info('Long message that do not dissapeas automatically and has a button link.', {
    autoClose: false,
});


interface ComponentsProps extends React.HtmlHTMLAttributes<HTMLDivElement> { }
type ComponentsState = {
    isInnerPage: boolean,
    btnTitle: string,
    actionBtnPlan: boolean,
    actionBtnSort: boolean,
    actionBtnBook: boolean,
    isModalOpen: boolean,
    isSecondModalOpen: boolean,
    cookiesAgree: boolean,
    fromToValueSecond: any,
    inlineDatepickerValue: any
    choiceButtons: any,
    choiceButtonsSecond: any
    selectCards: any
};
class Components extends React.Component<ComponentsProps, ComponentsState>  {
    constructor(props: any) {
        super(props);
        this.state = {
            isInnerPage: false,
            btnTitle: toogleHeaderBtnText[0],
            actionBtnPlan: false,
            actionBtnSort: false,
            actionBtnBook: true,
            isModalOpen: false,
            isSecondModalOpen: false,
            cookiesAgree: false,
            fromToValueSecond: {
                from: undefined,
                to: undefined
            },
            inlineDatepickerValue: undefined,
            choiceButtons: choiceButtons,
            choiceButtonsSecond: choiceButtonsSecond,
            selectCards: selectCards
        };
        this.toogleHeader = this.toogleHeader.bind(this);
        this.toogleActionBtns = this.toogleActionBtns.bind(this);
        this.onToggleSlectCards = this.onToggleSlectCards.bind(this);
    }

    toogleHeader() {
        this.setState({
            isInnerPage: !this.state.isInnerPage,
            btnTitle: this.state.btnTitle === toogleHeaderBtnText[0] ? toogleHeaderBtnText[1] : toogleHeaderBtnText[0]
        })
    }

    onToggleSlectCards(index: any) {
        const copySelectCards = [...this.state.selectCards];
        copySelectCards[index].isSelected = !copySelectCards[index].isSelected
        this.setState({
            selectCards: copySelectCards
        })
    }

    toogleActionBtns(btnActions: any) {
        const btnActionsTypes = {
            actionBtnPlan: false,
            actionBtnSort: false,
            actionBtnBook: false
        } as any;

        for (const key in btnActionsTypes) {
            if (key === btnActions) {
                btnActionsTypes[key] = true;
            }
        }

        this.setState({
            ...btnActionsTypes
        })
    }

    onChangeChoiceButtons = (e: any) => {
        let updatedChoices = {};
        if (e.target.name === 'choice-adult') {
            const choiceButtons = updateCheckedChoices(this.state.choiceButtons, e.target.value);
            updatedChoices = { choiceButtons: choiceButtons };
        } else if (e.target.name === 'choice-adult-2') {
            const choiceButtonsSecond = updateCheckedChoices(this.state.choiceButtonsSecond, e.target.value);
            updatedChoices = { choiceButtonsSecond: choiceButtonsSecond };
        }

        this.setState({
            ...updatedChoices
        })
    }

    render() {
        const { isInnerPage, btnTitle, fromToValueSecond, inlineDatepickerValue } = this.state;
        return (
            <div>
                <Header
                    links={[{ title: 'About Mallorca', isActive: false, attrHref: "#", attrTitle: "About", attrTargetBlank: false, onClicked: () => console.log('about') }, { title: 'Events', isActive: true, attrHref: "#", attrTitle: "Events", attrTargetBlank: false, onClicked: () => console.log('events') },
                    { title: 'Restaurants', isActive: false, attrHref: "#", attrTitle: "Restaurants", attrTargetBlank: false, onClicked: () => console.log('restaurant') }, { title: 'Hotels', isActive: false, attrHref: "#", attrTitle: "Hotels", attrTargetBlank: false, onClicked: () => console.log('hotel') },
                    { title: 'Activities', isActive: false, attrHref: "#", attrTitle: "Activities", attrTargetBlank: false, onClicked: () => console.log('activities') }, { title: 'Services', isActive: false, attrHref: "#", attrTitle: "Services", attrTargetBlank: false, onClicked: () => console.log('services') }]}
                    weatherInfo={{
                        title: "Mallorca, Dec 10 2019:",
                        link: {
                            title: "14 °C",
                            iconType: 'sunny'
                        }
                    }}
                    logo={{ attrHref: "#", attrTitle: "AbcLogo", attrTargetBlank: false, onClicked: () => console.log('logo cliked new') }}
                    search={isInnerPage ? undefined : {
                        placeholder: "Type smth",
                        onInputChange: (e: any) => console.log(e.target.value),
                        onSearchSubmit: () => console.log('search submited')
                    }}
                    language={{ selectedLanguage: "en", onLanguageChange: (lan: string) => console.log(`language changed to ${lan}`) }}
                    policyLinks={[
                        { title: 'Privacy Policy', link: { attrHref: "#", attrTitle: "Privacy", attrTargetBlank: false, onClicked: () => console.log('Privacy Policy') } },
                        { title: 'Cookie Policy', link: { attrHref: "#", attrTitle: "Cookie", attrTargetBlank: false, onClicked: () => console.log('Cookie Policy') } },
                        { title: 'Terms and Conditions', link: { attrHref: "#", attrTitle: "Terms", attrTargetBlank: false, onClicked: () => console.log('Terms and Conditions') } },
                    ]}
                    socialLinks={{
                        facebookLink: { attrHref: "#", attrTitle: "facebook", attrTargetBlank: false, onClicked: () => console.log('clicked facebook') },
                        instagramLink: { attrHref: "#", attrTitle: "instagram", attrTargetBlank: false, onClicked: () => console.log('clicked instagram') },
                        twitterLink: { attrHref: "#", attrTitle: "twitter", attrTargetBlank: false, onClicked: () => console.log('clicked twitter') },
                        googleLink: { attrHref: "#", attrTitle: "google", attrTargetBlank: false, onClicked: () => console.log('clicked google') },
                        linkedinLink: { attrHref: "#", attrTitle: "linkedIn", attrTargetBlank: false, onClicked: () => console.log('clicked linkedIn') },
                        pinterestLink: { attrHref: "#", attrTitle: "pinteres", attrTargetBlank: false, onClicked: () => console.log('clicked pinteres') },
                        youtubeLink: { attrHref: "#", attrTitle: "faceyoutubebook", attrTargetBlank: false, onClicked: () => console.log('clicked youtube') },
                    }}
                    isInnerPage={isInnerPage}
                    // Inner page props
                    innerPageTitle={'Some inner Page title, Some inner Page title, Some inner Page title, Some inner Page title,'}
                    // Set inner page custom Button
                    // innerPageCustomBtn={{iconWhite:'newCollectionL', iconBlack:'newCollectionL', onClicked:()=> console.log('clicked custom btn')}}
                    onBackClicked={() => console.log('back clicked')}
                    
                    // set in page nav id to scroll to.
                    inPageNav= {[{ id: 'google', linkTitle: 'Test' }, { id: 'google2', linkTitle: 'Test2' }]}
                />

                <Button className="mt-l ml-l mb-l" onClick={this.toogleHeader}>{btnTitle}</Button>
                {/* Demo toasts */}

                <div className="ml-l">
                    <h2>Toasts</h2>
                    <h3>Default with small content</h3>
                    <div className="mb-m">
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccess}>Success toast</Button>
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastDanger}>Danger toast</Button>
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastWarning}>Warning toast</Button>
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastInfo}>Info toast</Button>
                    </div>

                    <h3>With content, icon and action</h3>
                    <div className="mb-m">
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithIconContentAction}>Success toast with icon, content, action</Button>
                    </div>

                    <h3>With long text</h3>
                    <div className="mb-m">
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithLongText}>Success toast with long text</Button>
                    </div>
                    <h3>With long text and action</h3>
                    <div className="mb-m">
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithLongTextAction}>Success toast with long text and action</Button>
                    </div>
                    <h3>Without auto close</h3>
                    <div>
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastSuccessWithProps}>Success toast with no autoClose</Button>
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastDangerWithProps}>Danger toast with no autoClose</Button>
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastWarningWithProps}>Warning toast with no autoClose</Button>
                        <Button className="mr-s mb-s" appearance='secondary' onClick={toastInfoWithProps}>Info toast with no autoClose</Button>
                    </div>
                </div>
                <div>
                    <Button className="mt-l ml-l mb-l" onClick={() => this.toogleActionBtns('actionBtnPlan')}>Action btn Plan</Button>
                    <Button className="mt-l ml-l mb-l" onClick={() => this.toogleActionBtns('actionBtnSort')}>Action btn Sort</Button>
                    <Button className="mt-l ml-l mb-l" onClick={() => this.toogleActionBtns('actionBtnBook')}>Action btn Book</Button>
                </div>
                <Container>
                    <h1>H1 Merriweather</h1>
                    <p className="sub-title">sub-title From luxury boutique to country fincas check out Mallorca's best 4- and 5-star hotels</p>
                    <h2>H2 From luxury boutique to country fincas check out Mallorca's best 4- and 5-star hotels</h2>
                    <h3>H3 From luxury boutique to country fincas check out Mallorca's best 4- and 5-star hotels</h3>
                    <p>P Picking just the right hotel for your stay on Mallorca can make or break your holiday.</p>
                    <p className="small">{'<p className="small">'} Picking just the right hotel for your stay on Mallorca can make or break your holiday.</p>
                    <a href="https://www.abc-mallorca.com/">Link</a>
                    <br />
                    <p className="small"><a href="https://www.abc-mallorca.com/">Link</a></p>
                    <h2>Modal</h2>
                    <Button className="mt-l ml-l mb-l" onClick={() => this.setState({ isModalOpen: !this.state.isModalOpen })}>Modal</Button>
                    <Modal
                        isOpen={this.state.isModalOpen}
                        onCloseModal={() => { this.setState({ isModalOpen: false }) }}
                        isDismissible={true}
                    >
                        <Container>
                            <Row>
                                <Col lg={{ size: 8, offset: 2 }}>
                                    <div className="d-flex justify-content-center mt-s mb-m">
                                        <img src={LogoPrimary} alt="logo" />
                                    </div>
                                    <h2 className="text-center">Welcome back!</h2>
                                    <FormControl
                                        htmlFor="email"
                                        label="E-mail"
                                        isRequired
                                        isValid
                                    >
                                        <Input id="email" type="email" name="email" />
                                    </FormControl>

                                    <FormControl
                                        htmlFor="password"
                                        label="Password"
                                        isRequired
                                        isValid
                                    >
                                        <Input id="password" type="password" name="password" />
                                    </FormControl>
                                    <p>
                                        <a href="/">Forgot your password?</a>
                                    </p>
                                    <Button className="w-100 mb-m">Log in</Button>
                                    <p className="text-center"><span className="abc-word-separator">Or</span></p>
                                    <a href="/"><Button appearance="secondary" className="w-100 mb-s">Log in with Facebook</Button></a>
                                    <a href="/"><Button appearance="secondary" className="w-100 mb-s">Log in with Google</Button></a>
                                    <a href="/"><Button appearance="secondary" className="w-100 mb-m">Log in with Apple ID</Button></a>
                                    <a href="/">Don’t have an account? <br /> Sign up now!</a>
                                </Col>
                            </Row>
                        </Container>
                    </Modal>

                    <Button className="mt-l ml-l mb-l" onClick={() => this.setState({ isSecondModalOpen: !this.state.isSecondModalOpen })}>Modal 2</Button>
                    <Modal
                        isOpen={this.state.isSecondModalOpen}
                        onCloseModal={() => { this.setState({ isSecondModalOpen: false }) }}
                    >
                        <Container>
                            <h2>Modal 2 title</h2>
                            <p className="small">
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                        </p>
                            <p className="small">
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                        </p>
                        </Container>
                    </Modal>
                </Container>

                <FilterWrapper >
                    <form
                        className="wrap"
                    >
                        <FormControl
                            htmlFor="regions"
                            isValid
                            validationMessage="This field is not valid"
                            className="abc-flex-5"
                        >
                            <Select
                                id="regions"
                                options={[
                                    { value: 'chocolate', text: 'Chocolate' },
                                    { value: 'strawberry', text: 'Strawberry' },
                                    { value: 'vanilla', text: 'Vanilla' }]}
                                placeholder="All regions"
                            />
                        </FormControl>
                        <FormControl
                            htmlFor="cuisines"
                            isValid
                            validationMessage="This field is not valid"
                            className="abc-flex-10"
                        >
                            <Select
                                id="cuisines"
                                options={[
                                    { value: 'chocolate', text: 'Chocolate' },
                                    { value: 'strawberry', text: 'Strawberry' },
                                    { value: 'vanilla', text: 'Vanilla' }]}
                                placeholder="All Cuisines"
                            />
                        </FormControl>
                        <FormControl
                            htmlFor="categories"
                            isValid
                            validationMessage="This field is not valid"
                            className="abc-flex-10"
                        >
                            <Select
                                id="categories"
                                options={[
                                    { value: 'chocolate', text: 'Chocolate' },
                                    { value: 'strawberry', text: 'Strawberry' },
                                    { value: 'vanilla', text: 'Vanilla' }]}
                                placeholder="All categories"
                            />
                        </FormControl>
                        <div className="break"></div>
                        <FormControl
                            htmlFor="keyword"
                            isValid
                            validationMessage="This field is not valid"
                        >
                            <Input id="keyword" type="text" name="keyword" placeholder="Keyword" />
                        </FormControl>

                        <FormControl
                            htmlFor="keyword2"
                            isValid
                            validationMessage="This field is not valid"
                        >
                            <Input id="keyword2" type="text" name="keyword2" placeholder="Keyword 2" />
                        </FormControl>

                        <FormControl
                            htmlFor="keyword3"
                            isValid
                            validationMessage="This field is not valid"
                        >
                            <Input id="keyword3" type="text" name="keyword3" placeholder="Keyword 3" />
                        </FormControl>
                        <div className="break"></div>
                        <FormControl
                            htmlFor="keyword4"
                            isValid
                            validationMessage="This field is not valid"
                        >
                            <Input id="keyword4" type="text" name="keyword4" placeholder="Keyword 4" />
                        </FormControl>
                        <Button >search</Button>
                        <div className="break"></div>
                    </form>
                </FilterWrapper>

                <div id="google">
                    <h2 >Hero Slider</h2>
                </div>
            
                <HeroSlider
                    slides={slides}
                    dots={true}
                    autoplay={false}
                    pauseOnHover={true}
                    autoplaySpeed={3000}
                    speed={300}
                    initialSlide={0}
                    infinite={true}
                    className="test"
                />
                <h2>Related Articles Slider</h2>
                <RelatedArticlesSlider
                    title={'Recommended for you'}
                    slides={slidesArticle}
                    dots={true}
                />

                <RelatedArticlesSlider
                    title={'Recommended for you with background'}
                    slides={slidesArticle}
                    dots={true}
                    autoplay={false}
                    isBackground={true}
                />
                <h2>Home Slider</h2>
                <HomeSlider
                    slides={slides}
                    dots={true}
                    autoplay={false}
                    pauseOnHover={true}
                    autoplaySpeed={3000}
                    speed={300}
                    initialSlide={0}
                    infinite={false}
                    className="test"
                />

                <h2>Testimonials Slider</h2>

                <TestimonialsSlider
                    title={'What our clients say? Testoimonials'}
                    slides={testimonialsArticle}
                    dots={true}
                />

                <Icon icon="calendarS" />
                <Icon icon="bedM" />
                <Icon icon="burgerL" />
                <Icon icon="youtubePrimaryL" />

                <Container style={{ backgroundColor: 'lightgray' }}>
                    <h3>Container</h3>
                    <Row>
                        <Col lg="3"><p style={{ backgroundColor: 'lightcyan' }}>Col lg 3</p></Col>
                        <Col lg="3"><p style={{ backgroundColor: 'lightcyan' }}>Col lg 3</p></Col>
                        <Col lg="6"><p style={{ backgroundColor: 'lightcyan' }}>Col lg 6</p></Col>
                    </Row>
                </Container>
                <hr />
                <div style={{ padding: '10px' }}>
                    <Button>primary button</Button><br /><br />
                    <Button disabled>primary button disabled</Button><br /><br />
                    <Button appearance="secondary">secondary button</Button><br /><br />
                    <Button appearance="secondary" disabled>secondary button disabled</Button><br /><br />
                </div>

                <BackgroundWrapper>
                    <h2 className="mb-0">test</h2>
                </BackgroundWrapper>

                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <FormControl
                                htmlFor="input1"
                                label="Input normal"
                                isRequired
                                isValid
                                validationMessage="This field is not valid">
                                <Input id="input1" type="text" name="name" />
                            </FormControl>
                            <FormControl
                                htmlFor="textarea1"
                                label="Textarea"
                                validationMessage="This field is not valid">
                                <Textarea id="textarea1" />
                            </FormControl>
                            <FormControl
                                htmlFor="select1"
                                label="Select"
                                validationMessage="This field is not valid">
                                <Select
                                    id="select1"
                                    options={[
                                        { value: 'chocolate', text: 'Chocolate' },
                                        { value: 'strawberry', text: 'Strawberry' },
                                        { value: 'vanilla', text: 'Vanilla' }]}
                                    placeholder="Select..."
                                />
                            </FormControl>
                            <FormControl
                                htmlFor="radio1"
                                label="Radio Buttons"
                                validationMessage="This field is not valid">
                                <RadioButton name="agree" value="yes">Yes</RadioButton>
                                <RadioButton name="agree" value="no">No</RadioButton>
                                <RadioButton disabled name="disabled" value="notSure">Not sure</RadioButton>
                                <RadioButton disabled checked name="disabled" value="Other">Other</RadioButton>
                            </FormControl>
                            <FormControl
                                htmlFor="checkbox1"
                                label="Checkboxes"
                                validationMessage="This field is not valid">
                                <Checkbox name="dessert" value={'chocolate'} onChange={() => { }}> Chocolate </Checkbox>
                                <Checkbox name="dessertSecond" value={'banana'} onChange={() => { }}> Banana </Checkbox>
                                <Checkbox disabled name="dessertThird" value={'cake'} onChange={() => { }}> Cake </Checkbox>
                                <Checkbox disabled checked name="dessertFourth" value={'pancake'} onChange={() => { }}> Pancake </Checkbox>
                            </FormControl>
                            <FormControl
                                htmlFor="datepicker1"
                                label="Datepicker"
                                validationMessage="This field is not valid">
                                <Datepicker id="datepicker1" format="" />
                            </FormControl>
                            <FormControl
                                htmlFor='toogle'
                                label='Toggle'
                            >
                                <Toggle name="radioToogle" value={'radio'} onChange={() => { }}> Radio </Toggle>
                            </FormControl>
                            <FormControl
                                htmlFor='toogle'
                                label='Toggle'
                            >
                                <Toggle disabled name="acToggle" value={'ac'} onChange={() => { }}> Air Conditioner </Toggle>
                            </FormControl>
                            <FormControl
                                htmlFor='toogle'
                                label='Toggle'
                            >
                                <Toggle disabled checked name="doorToggle" value={'door'} onChange={() => { }}> Door </Toggle>
                            </FormControl>

                            <div className="mb-m">
                                <h3>Inline Datepicker</h3>
                                <Datepicker
                                    type='inline'
                                    id="datepicker7"
                                    selectedDays={[inlineDatepickerValue] as any}
                                    onDayClick={(date: any) => this.setState({ inlineDatepickerValue: date })}
                                    className="mb-s"
                                />
                                <h3>Inline Range Datepicker</h3>
                                <Datepicker
                                    type='inline-range'
                                    id="datepicker9"
                                    selectedDays={[fromToValueSecond.from, { from: fromToValueSecond.from, to: fromToValueSecond.to }] as any}
                                    daysRangeFromTo={fromToValueSecond}
                                    onSelectDaysRange={(range: any) => this.setState({ fromToValueSecond: range })}
                                    fromYear={2019}
                                    toYear={2021}
                                />
                                <InfoBox className="mt-s" textSize="normal">
                                    <span className="text-bold">From:</span> {fromToValueSecond.from && `${fromToValueSecond.from.getDate()}th ${monthNames[fromToValueSecond.from.getMonth()]} ${fromToValueSecond.from.getFullYear()}`}<br />
                                    <span className="text-bold">To:</span> {fromToValueSecond.to && `${fromToValueSecond.to.getDate()}th ${monthNames[fromToValueSecond.to.getMonth()]} ${fromToValueSecond.to.getFullYear()}`}
                                </InfoBox>
                            </div>

                            <OpeningHours
                                title="Opening Hours"
                                note="Open all year"
                                rows={[
                                    { title: 'Monday', hours: ['13:00 - 01:00 h'] },
                                    { title: 'Tuesday', hours: ['09:00 - 12:00 h', '13:00 - 19:00 h'] },
                                    { title: 'Wednesday', hours: ['13:00 - 01:00 h'] },
                                    { title: 'Thursday', hours: ['13:00 - 01:00 h'] },
                                    { title: 'Friday', hours: ['13:00 - 01:00 h'] },
                                    { title: 'Saturday', hours: ['13:00 - 01:00 h'] },
                                    { title: 'Sunday', hours: ['closed'] },
                                ]} />
                            <hr />
                            <DisplayPost
                                title="Real Estate Agents on Mallorca"
                                link={{ attrHref: '/docs' }}
                                description="The top and most experienced real estate agents on Mallorca help you find your ideal home in any location within the island. Find out more here!"
                                image={{
                                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg',
                                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/12/properties-620x330_c.jpg 620w'
                                }}
                                categoryTitle="Property" />
                            <DisplayPost
                                title="Best Restaurants in the Southwest of Mallorca"
                                link={{ attrHref: '/docs' }}
                                description="Stay at one of the 14 unique rooms in this extraordinary adults-only hotel in Lloseta, Serra de Tramuntana. Enjoy the beautiful scenery, homemade food and relaxing spa. Book NOW!"
                                image={{
                                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg',
                                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/07/bens-davall-08-300x159.jpg 300w'
                                }}
                                categoryTitle="Category Title"
                                isMasterPost
                                buttonLink={{ title: 'book now', attrHref: '/docs' }} />
                            <DisplayPost
                                title="Top Mallorca Hotels 2020"
                                link={{ attrHref: '/docs' }}
                                description="Mallorca offers visitors a vast choice of hotels and accommodation, whether you are here for a beach holiday, cycling trip or city break in Palma."
                                image={{
                                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-430x230.jpg',
                                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/03/best-palma-hotels-01-300x159.jpg 300w'
                                }} />

                            <GoogleMapAddress
                                title="Street Map"
                                map={{
                                    googleMapHelperApiKey: "AIzaSyDrX2CaIvlrLocKo4jvtga0Z3wxRH_KMsQ",
                                    defaultCenter: { lat: 39.568590, lng: 2.642447 },
                                    defaultZoom: 15,
                                    marker: { lat: 39.568590, lng: 2.642447 },
                                }}
                                address="C/ Caro, 16 , Palma de Mallorca"
                            />
                            <ContactDetails language="en" googleMapApiKey="AIzaSyDrX2CaIvlrLocKo4jvtga0Z3wxRH_KMsQ" />
                            <ContactDetails language="de" googleMapApiKey="AIzaSyDrX2CaIvlrLocKo4jvtga0Z3wxRH_KMsQ" />
                            <ContactDetails language="es" googleMapApiKey="AIzaSyDrX2CaIvlrLocKo4jvtga0Z3wxRH_KMsQ" />
                            <DisplayCategory
                                category={{
                                    title: 'Hotel News'
                                }}
                                articles={[
                                    {
                                        title: 'Top Mallorca Hotels 2020',
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-430x230.jpg',
                                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-300x159.jpg 300w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/son-net-hotel-mallorca-img01-620x330_c.jpg 620w'
                                        },
                                        description: 'Mallorca offers visitors a vast choice of hotels and accommodation, whether you are here for a beach holiday, cycling trip or city break in Palma.',
                                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                    },
                                    {
                                        title: '19 luxury hotels with great restaurants on Mallorca',
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/02/astir-restaurant-14-80x80.jpg',
                                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/02/astir-restaurant-14-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/02/astir-restaurant-14-150x150.jpg 150w'
                                        },
                                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                    },
                                    {
                                        title: 'Best Boutique Hotels on Mallorca 2020',
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-80x80.jpg',
                                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/12/Summum-Prime-Boutique-Hotel-1-150x150.jpg 150w'
                                        },
                                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                    },
                                    {
                                        title: 'Top 21 most romantic hotels on Mallorca',
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Terraza-Maricel-01-80x80.jpg',
                                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Terraza-Maricel-01-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/02/Terraza-Maricel-01-150x150.jpg 150w'
                                        },
                                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                    },
                                ]} />

                            <DisplayCategory
                                category={{
                                    title: 'Restaurant News',
                                    link: { attrHref: '#', attrTargetBlank: true }
                                }}
                                articles={[
                                    {
                                        title: 'Foodie heaven at Saranna Café & Gastrobar',
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg',
                                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/saranna-eating-palma-img01-300x159.jpg 300w'
                                        },
                                        description: 'Saranna Café & Gastrobar and its delicious home-made cuisine is making waves, from breakfast and brunch to incredible burgers!',
                                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                    },
                                    {
                                        title: 'Michelin-star greatness at VORO',
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg',
                                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/12/voro-restaurant-41-150x150.jpg 150w'
                                        },
                                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                    },
                                    {
                                        title: 'Seafood with sea views at newly opened Salicornia',
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg',
                                            srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-80x80.jpg 80w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/salicornia-restaurant-salmorejo-img001-150x150.jpg 150w'
                                        },
                                        link: { attrHref: '#', onClicked: () => alert('Is clicked!') }
                                    }
                                ]} />

                            <Banner
                                className='mb-l'
                                title="Read our magazine"
                                buttonText="Read now"
                                url={{ attrHref: '/docs/banner', onClicked: () => alert('Is clicked!') }}
                                image={{
                                    src: "https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/magazine-online_whatson_2020_EN.jpg"
                                }}
                            />

                            <YoutubeVideo
                                title="Youtube Video"
                                videoUrl="https://www.youtube.com/embed/6qErv1uzC-U" />

                            <YoutubeVideo
                                title="Youtube Video Playlist"
                                videoUrl="https://www.youtube.com/embed/?autoplay=1&list=PLt6goMH2SpPzXVKaoUKztlgVxBRvW2JWF&listType=playlist&loop=1&rel=0&iv_load_policy=3" />

                            <EventPreview
                                title="The Festival of Light"
                                image={{
                                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-430x230.jpg',
                                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/festa-de-la-llum-catedral-palma-img01-300x159.jpg 300w'
                                }}
                                link={{ attrHref: '#', onClicked: () => alert('Is clicked!') }}
                                date="02/02/2020"
                                time="08:00  - 09:00"
                                description="Palma is coming up roses! Twice a year in the famous La Seu Cathedral (if the sun happens to be shining) the cathedral´s rose windows reflect a figure 8." />

                            <EventPreview
                                title="Fira de la flor d’ametler"
                                image={{
                                    src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/fira-flor-ametler-2-430x230.jpg',
                                    srcSet: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/fira-flor-ametler-2-430x230.jpg 430w, https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/03/fira-flor-ametler-2-300x159.jpg 300w'
                                }}
                                link={{ attrHref: '#', onClicked: () => alert('Is clicked!') }}
                                date="02/02/2020  - 02/02/2020"
                                time="All day event"
                                description="One of Mallorca’s best kept secrets is the blooming of the almond blossoms at the end of February. Son Servera celebrates during Fira de la Flor d’ Ametler." />

                            <ThumbnailsList
                                title="Category"
                                thumbnails={[
                                    {
                                        image: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/palma.jpg' },
                                        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
                                        title: "Palma"
                                    },
                                    {
                                        image: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/central.jpg' },
                                        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
                                        title: "Central"
                                    },
                                    {
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Southwest.jpg'
                                        },
                                        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
                                        title: "Southwest"
                                    },
                                    {
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Southeast.jpg'
                                        },
                                        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
                                        title: "Southeast"
                                    },
                                    {
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Northwest.jpg'
                                        },
                                        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
                                        title: "Northwest"
                                    },
                                    {
                                        image: {
                                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/05/Northeast.jpg'
                                        },
                                        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
                                        title: "Northeast"
                                    },
                                ]}
                            />

                            <HotelOverview
                                language="en"
                                stars={5}
                                location={{ title: 'Andratx', link: { attrHref: '#' } }}
                                categories={[
                                    { title: 'Hideaways', link: { attrHref: '#' } },
                                    { title: 'New Hotels', link: { attrHref: '#' } }
                                ]}
                                numberOfRooms={64}
                                checkIn="14:00h"
                                checkOut="12:00h"
                                adultOnly
                                roomTypes={['Single room', 'Double room']}
                                priceFrom={145}
                                priceTo={375}
                                numberOfBedrooms={34}
                                numberOfBathrooms={10}
                                numberOfPeople={68}
                                distanceFromAirport="5 km"
                                distanceFromBeach="500 m"
                                nearestTown="Palma"
                                additionalInfo={[
                                    {
                                        title: 'Features',
                                        list: [
                                            'Reception 24h',
                                            'Luggage room',
                                            'Swimming Pool',
                                            'Garden',
                                            'Outdoor furniture',
                                            'Street Parking',
                                            'Wifi',
                                            'Sun loungers',
                                            'Bar',
                                            'Restaurant',
                                            'Wellness & Spa',
                                        ]
                                    },
                                    {
                                        title: 'Meals',
                                        list: [
                                            'Breakfast',
                                            'Half Board',
                                        ]
                                    },
                                    {
                                        title: 'Room amenities',
                                        list: [
                                            'Airconditioning',
                                            'Balcony',
                                            'Room service',
                                            'Safe',
                                        ]
                                    }
                                ]}
                            />

                            <HotelOverview
                                language="es"
                                stars={3}
                                location={{ title: 'Andratx', link: { attrHref: '#' } }}
                                categories={[
                                    { title: 'Hideaways', link: { attrHref: '#' } },
                                    { title: 'New Hotels', link: { attrHref: '#' } }
                                ]}
                                numberOfRooms={9}
                                checkIn="14:00h"
                                checkOut="12:00h"
                                adultOnly
                                roomTypes={['Single room', 'Double room']}
                                priceFrom={145}
                                priceTo={375}
                                numberOfBedrooms={34}
                                numberOfBathrooms={10}
                                numberOfPeople={68}
                                distanceFromAirport="5 km"
                                distanceFromBeach="500 m"
                                nearestTown="Palma"
                            />

                            <HotelOverview
                                language="de"
                                stars={4}
                                location={{ title: 'Andratx' }}
                                categories={[
                                    { title: 'Hideaways' },
                                ]}
                                numberOfRooms={123}
                                checkIn="14:00h"
                                checkOut="12:00h"
                                adultOnly
                                roomTypes={['Single room', 'Double room']}
                                priceFrom={145}
                                priceTo={375}
                                numberOfBedrooms={34}
                                numberOfBathrooms={10}
                                numberOfPeople={68}
                                distanceFromAirport="5 km"
                                distanceFromBeach="500 m"
                                nearestTown="Palma"
                            />

                            <Socials
                                language="en"
                                facebookLink={{ attrHref: '#' }}
                                instagramLink={{ attrHref: '#' }}
                                twitterLink={{ attrHref: '#' }}
                                pinterestLink={{ attrHref: '#' }}
                            />
                            <Socials
                                language="es"
                                facebookLink={{ attrHref: '#' }}
                                instagramLink={{ attrHref: '#' }}
                                twitterLink={{ attrHref: '#' }}
                                pinterestLink={{ attrHref: '#' }}
                            />
                            <Socials
                                language="de"
                                facebookLink={{ attrHref: '#' }}
                                instagramLink={{ attrHref: '#' }}
                                twitterLink={{ attrHref: '#' }}
                                pinterestLink={{ attrHref: '#' }}
                            />

                            <ArticleSummary
                                title="Palma de Mallorca"
                                subTitle="Your ultimate guide to Mallorca's capital city. What to do, see, shop & love in Palma"
                                viewsCount="11 243"
                                date="01.03.2020"
                                tags={[
                                    { title: 'nightlife' },
                                    { title: 'cycling' },
                                    { title: 'summer' },
                                ]}
                                bullets={[
                                    { title: 'Your ultimate guide to Mallorca’s capital city. ' },
                                    { title: 'A 10th century Muslim fortress rebuilt in the Gothic style by the Christians' },
                                    { title: 'Discover Santa Marina!' },
                                ]}
                            />

                        </Col>
                    </Row>


                    <InfoBox className="mb-m">
                        <span className="text-bold">Activities in</span> <a href="https://www.abc-mallorca.com/">Mallorca</a> If you are looking for ideas for your holiday and fun things to do, Mallorca has it all on just one island.
                        Whether you are a big family with children or are looking for an adventure on your own, there is something for summer as well as winter.
                        From outdoor activities and sports to water fun, you will never run out of things to do! Mallorca offers markets, museums, festivals and concerts in stunning Mediterranean locations.
                        A trip to Mallorca will never be forgotten!
                </InfoBox>
                </Container>

                <ActionBar
                    homeBtn={{
                        title: "Home",
                        onCliked: () => console.log('home'),
                        isActive: true
                    }}
                    collectionsBtn={{
                        title: "Plan",
                        onCliked: () => console.log('itineraries'),
                    }}
                    feedBtn={{
                        title: "Enjoy",
                        onCliked: () => console.log('feed'),
                    }}
                    profileBtn={{
                        title: "Account",
                        onCliked: () => console.log('profile'),
                    }}
                    actionBtn={{
                        title: `
                        ${this.state.actionBtnPlan ? "Plan" : ""}
                        ${this.state.actionBtnSort ? "Sort" : ""}
                        ${this.state.actionBtnBook ? "Book" : ""}
                        `,
                        titleClose: "Close",
                        subActons: [
                            ...(this.state.actionBtnPlan ? actionBtnTextTypes.Plan : []),
                            ...(this.state.actionBtnSort ? actionBtnTextTypes.Sort : []),
                            ...(this.state.actionBtnBook ? actionBtnTextTypes.Book : [])
                        ]
                    }}
                />

                <HomeSlider
                    slides={slides}
                    dots={true}
                    autoplay={false}
                    pauseOnHover={true}
                    autoplaySpeed={3000}
                    speed={300}
                    initialSlide={0}
                    infinite={false}
                    showMultipleSlides={true}
                    title="Regions"
                />

                <Container id="google2">
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <Collections collections={collections} className="mb-m" />
                            <Collections collections={collections} />
                            <Collections className="abc-negative-gutter" collections={collectionsTitle} title="Collections with title, action no icon, negative gutter" />

                            <ChoiceButtons
                                title="Adults"
                                buttons={this.state.choiceButtons}
                                onChange={this.onChangeChoiceButtons}
                                choiceButtonsName={'choice-adult'}
                                gridColProps={{ lg: "3" }}
                            />

                            <ChoiceButtons
                                title="Lunch"
                                buttons={this.state.choiceButtonsSecond}
                                onChange={this.onChangeChoiceButtons}
                                choiceButtonsName={'choice-adult-2'}
                            />
                        </Col>
                    </Row>

                </Container>

                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <SelectCards cards={selectCards} onSelect={this.onToggleSlectCards} categoryTitle="Menu" />

                            <LinkBox title="Edit Profile" description="Edit your personal information." icon="profilePrimaryL" />
                            <LinkBox title="Reservations" description="Manage your reservations." icon="reservationPrimaryL" />
                            <LinkBox title="Cards" description="Manage your cards." icon="paymentPrimaryL" />
                            <LinkBox title="Notifications" description="Configure your notifications." icon="notificationsPrimaryL" />
                            <LinkBox title="Settings" description="Configure your account settings." icon="settingsPrimaryL" />
                            <LinkBox title="Bens d'Avali" description="03 Jul, 15:30 h | 10 people" icon="reservationDeniedL" />
                            <LinkBox title="Sin Embargo Art Galleria" description="15 Jun, 19:00 h | 5 people" icon="reservationPendingL" />
                            <LinkBox title="Nama" description="15 Jun, 19:00 h | 5 people" icon="reservationConfirmedL" />
                            <LinkBox title="Moda Bar" description="23 May, 20:30 h | 2 people" icon="reservationCompleterdL" />
                            <LinkBox title="Aromata" description="23 May, 20:30 h | 2 people" icon="reservationNoShowL" />

                            <h3>Weather Widget</h3>
                            <WeatherWidget
                                title='Weather forecast: Palma De Mallorca'
                                className='abc-negative-gutter'
                                imgSrc='https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/calo-des-moro-beach01.jpg'
                                currentDegrees="14°"
                                currentWeatherIconType="sunny"
                                currentWeatherInfo={[
                                    'Clear',
                                    '65% humidity',
                                    'Wind: 2m/s W',
                                    'H 3 - L 21'
                                ]}
                                weeklyForecast={[
                                    {
                                        dayOfweek: 'TUE',
                                        degrees: '31°',
                                        iconType: 'sunny'
                                    },
                                    {
                                        dayOfweek: 'Wed',
                                        degrees: '21°',
                                        iconType: 'partlyCloudy'
                                    },
                                    {
                                        dayOfweek: 'THU',
                                        degrees: '33°',
                                        iconType: 'sunny'
                                    },
                                    {
                                        dayOfweek: 'FRI',
                                        degrees: '31°',
                                        iconType: 'cloudy'
                                    },
                                    {
                                        dayOfweek: 'SAT',
                                        degrees: '24°',
                                        iconType: 'rainy'
                                    },
                                    {
                                        dayOfweek: 'SUN',
                                        degrees: '34°',
                                        iconType: 'sunny'
                                    }
                                ]}
                            />
                            <h2>Service</h2>
                            <Service
                              image={{
                                  src: "https://www.abc-mallorca.es/wp-content/uploads/2018/09/Hotel.jpg"
                              }}
                              title="Nutrition advices and meal plan"
                              description={{
                                 text:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
                                 btnMoreText:"Read more",
                                 btnLessText:"See less"
                              }}
                              footer={{
                                  icon: 'priceSecondaryS',
                                  text: 'Price: 9 to 12 euro'
                              }}
                              className='abc-negative-gutter'
                              link={{
                                attrHref: "#"
                              }}
                           />
                        </Col>
                    </Row>
                </Container>

                <Hero img="https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/04/mercado-del-olivar-32.jpg" title="Restaurant name" description="Additional description" />

                <Cookies isAgreed={this.state.cookiesAgree}>
                    <p>
                        The website is using cookies.
                          If you continue browsing the website you agree with the <a href="/">cookie policy</a>.
                      </p>
                    <Button className="w-100" onClick={() => this.setState({ cookiesAgree: !this.state.cookiesAgree })}>Accept</Button>
                </Cookies>
                <div className="abc-pre-footer-mb"></div>
                <Footer
                    cards={[
                        {
                            title: 'Get our magazine',
                            description: 'Articles on island-related topics in seasonal and special editions. Check them out.',
                            image: {
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/get-mag-2017b.png'
                            },
                            buttonLink: {
                                title: 'get the magazine', attrHref: '#', onClicked: () => alert('Is clicked!')
                            }
                        },
                        {
                            title: 'Get our newsletter',
                            description: 'Become a member and receive regular updates!',
                            image: {
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/get-mag-2017b.png'
                            },
                            buttonLink: {
                                title: 'subscribe now', attrHref: '#', onClicked: () => alert('Is clicked!')
                            }
                        },
                        {
                            title: 'Follow us on Facebook',
                            description: 'Don´t miss our latest news, follow us on Facebook!',
                            image: {
                                src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/02/fb.png'
                            },
                            buttonLink: {
                                title: 'follow us', attrHref: '#', onClicked: () => alert('Is clicked!')
                            }
                        },
                    ]}
                    pagesLinks={[
                        { title: 'About Mallorca', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Events', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Restaurants', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Hotels', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Activities', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Services', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    ]}
                    otherLinks={[
                        { title: 'About abcMallorca', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Newsletter', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'abcMallorca Media', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Contact us', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    ]}
                    infoText="abcMallorca is the leading luxury lifestyle magazine, website and networking community for the Spanish Balearic island of Mallorca, published in English, Spanish and German. Interesting articles, guides, expert review & fabulous photo-shoots and videos provide a wealth of valuable insider information designed to help make living, doing business or spending time on this beautiful island a memorable experience."
                    facebookLink={{ attrHref: '#', attrTargetBlank: true }}
                    instagramLink={{ attrHref: '#', attrTargetBlank: true }}
                    twitterLink={{ attrHref: '#', attrTargetBlank: true }}
                    googleLink={{ attrHref: '#', attrTargetBlank: true }}
                    linkedinLink={{ attrHref: '#', attrTargetBlank: true }}
                    pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
                    youtubeLink={{ attrHref: '#', attrTargetBlank: true }}
                    copyrightText="© 2019 abc-knowledge S.L. All Rights Reserved."
                    policyLinks={[
                        { title: 'Privacy Policy', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Cookie Policy', attrHref: '#', onClicked: () => alert('Is clicked!') },
                        { title: 'Terms and Conditions', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    ]}
                />
            </div>
        );
    }
}

export default Components;
