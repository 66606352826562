import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Toast } from '@studiouxp/abc-mallorca-portal-ds';
import Docs from './views/docs/Docs';
import Components from './views/components/Components';
import DemoPages from './views/demopages/DemoPages';

// Init toast
Toast.configure();

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/docs/introduction" />
        <Redirect exact from="/docs" to="/docs/introduction" />
        <Route path="/docs" component={Docs} />
        <Route path="/components" component={Components} />
        <Route path="/demo-pages" component={DemoPages} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
