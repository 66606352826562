import React from 'react';

import { ReactAux, ChoiceButtons, Toggle, Select, Row, Col } from '@studiouxp/abc-mallorca-portal-ds';

const choiceButtons = [
    {
        value: 1,
    },
    {
        value: 2
    },
    {
        value: 3,
    },
    {
        value: 4,
    },
    {
        value: 5,
    },
    {
        value: 6, checked: true
    },
    {
        value: 7,
    },
    {
        value: 8,
    },
    {
        value: 9,
    },
    {
        value: 10,
    },
    {
        value: 11,
    },
    {
        value: 12,
    },
    {
        value: 13,
    },
    {
        value: 14,
    },
    {
        value: 15,
    },
    {
        value: 16,
    },
    {
        value: 17,
    },
    {
        value: 18,
    },
    {
        value: 19,
    },
    {
        value: 20,
    },
];

const updateCheckedChoices = (array: any, targetValue: any) => {
    return array.map((el: any) => {
        if (el.value.toString() === targetValue) {
            el['checked'] = true;
        } else {
            delete el.checked;
        }
        return el;
    });
}

class Step1 extends React.Component {

    state = {
        choiceButtons :choiceButtons,
        radioBaby: true,
        radioKids: false,
        selectBaby: 0,
        selectKids: 0,
    }

    onChangeChoiceButtons = (e: any) => {

        let updatedChoices = {};
        if (e.target.name === 'choice-adults') {
            const choiceButtons = updateCheckedChoices(this.state.choiceButtons, e.target.value);
            updatedChoices = { 
                   choiceButtons: choiceButtons,
            };
        } 

        this.setState({
            ...updatedChoices
        })
    }

    render() {
        return (
            <ReactAux>
                <ChoiceButtons
                    title="Adults"
                    buttons={this.state.choiceButtons.filter((el,i)=> i < 12)}
                    onChange={this.onChangeChoiceButtons}
                    choiceButtonsName={'choice-adults'}
                    gridColProps={{ lg: '2', md: '3' }}
                />

                <ChoiceButtons
                    title="Groups"
                    buttons={this.state.choiceButtons.filter((el,i)=> i >= 12)}
                    onChange={this.onChangeChoiceButtons}
                    choiceButtonsName={'choice-adults'}
                />

                <h2 className="mb-s">Kids</h2>
                <Row className="mb-s">
                    <Col lg="4" sm="6">
                        <Toggle
                            name="radioBaby"
                            value={'radioBaby'}
                            checked={this.state.radioBaby}
                            onChange={(e) => this.setState({ radioBaby: e.target.checked })}
                        >
                            Baby 0 - 2 y
                    </Toggle>
                    </Col>
                    <Col lg="2" sm="3">
                        <Select
                            id="selectBaby"
                            disabled={!this.state.radioBaby}
                            value={this.state.radioBaby ? this.state.selectBaby : 0}
                            options={[
                                { value: 0, text: '0' },
                                { value: 1, text: '1' },
                                { value: 2, text: '2' },
                                { value: 3, text: '3' },
                                { value: 4, text: '4' },
                            ]}
                            onChange={(e) => this.setState({ selectBaby: e.target.value })}
                        />
                    </Col>
                </Row>
                <Row className="mb-l">
                    <Col lg="4" sm="6">
                        <Toggle
                            name="radioKids"
                            value={'radioKids'}
                            checked={this.state.radioKids}
                            onChange={(e) => this.setState({ radioKids: e.target.checked })}
                        >
                            Kids 2 - 16 y
                    </Toggle>
                    </Col>
                    <Col lg="2" sm="3">
                        <Select
                            id="selectKids"
                            disabled={!this.state.radioKids}
                            value={this.state.radioKids ? this.state.selectKids : 0}
                            options={[
                                { value: 0, text: '0' },
                                { value: 1, text: '1' },
                                { value: 2, text: '2' },
                                { value: 3, text: '3' },
                                { value: 4, text: '4' },
                                { value: 5, text: '5' },
                                { value: 6, text: '6' },
                            ]}
                            onChange={(e) => this.setState({ selectKids: e.target.value })}
                        />
                    </Col>
                </Row>
            </ReactAux>
        )
    }
}

export default Step1;
