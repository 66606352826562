import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import { Footer } from '@studiouxp/abc-mallorca-portal-ds';

const FooterPage: React.FC = () => {
    return (
        <div>
            <h1>Footer</h1>
            <h2>Description</h2>
            <p>The Footer component provides the layout for footer sections in the Design System.</p>
            <p><strong>Important: </strong>For styling purposes, the footer does not provide spacing above itself.
            This means that there should be an element, serving as a <i>pre-footer</i>, that explicitly handles the
            spacing setup between it and the Footer. This is achieved through the following utility CSS classes, specifically
            configured for this scenario:
            <br /><br />
                - <i>abc-pre-footer-mb </i>: Sets the correct bottom margin to the element before the footer. This
                utility can be used when the UI design of that view expects or allows white space between the footer and its
                privious sibling.
                <br />
                - <i>abc-pre-footer-pb </i>: Sets the correct bottom padding to the element before the footer. This
                utility can be used in cases like the <Link to="/demo-pages/home">Home page</Link>, where the pre-footer
                element has custom background which visually reaches to the footer top border.
            </p>

            <div className="abc-pre-footer-mb"></div>
            <Footer
                cards={[
                    {
                        title: 'Get our magazine',
                        description: 'Articles on island-related topics in seasonal and special editions. Check them out.',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/get-mag-2017b.png'
                        },
                        buttonLink: {
                            title: 'get the magazine', attrHref: '#', onClicked: () => alert('Is clicked!')
                        }
                    },
                    {
                        title: 'Get our newsletter',
                        description: 'Become a member and receive regular updates!',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/get-mag-2017b.png'
                        },
                        buttonLink: {
                            title: 'subscribe now', attrHref: '#', onClicked: () => alert('Is clicked!')
                        }
                    },
                    {
                        title: 'Follow us on Facebook',
                        description: 'Don´t miss our latest news, follow us on Facebook!',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/02/fb.png'
                        },
                        buttonLink: {
                            title: 'follow us', attrHref: '#', onClicked: () => alert('Is clicked!')
                        }
                    },
                ]}
                pagesLinks={[
                    { title: 'About Mallorca', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Events', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Restaurants', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Hotels', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Activities', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Services', attrHref: '#', onClicked: () => alert('Is clicked!') },
                ]}
                otherLinks={[
                    { title: 'About abcMallorca', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Newsletter', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'abcMallorca Media', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Contact us', attrHref: '#', onClicked: () => alert('Is clicked!') },
                ]}
                infoText="abcMallorca is the leading luxury lifestyle magazine, website and networking community for the Spanish Balearic island of Mallorca, published in English, Spanish and German. Interesting articles, guides, expert review & fabulous photo-shoots and videos provide a wealth of valuable insider information designed to help make living, doing business or spending time on this beautiful island a memorable experience."
                facebookLink={{ attrHref: '#', attrTargetBlank: true }}
                instagramLink={{ attrHref: '#', attrTargetBlank: true }}
                twitterLink={{ attrHref: '#', attrTargetBlank: true }}
                googleLink={{ attrHref: '#', attrTargetBlank: true }}
                linkedinLink={{ attrHref: '#', attrTargetBlank: true }}
                pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
                youtubeLink={{ attrHref: '#', attrTargetBlank: true }}
                copyrightText="© 2019 abc-knowledge S.L. All Rights Reserved."
                policyLinks={[
                    { title: 'Privacy Policy', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Cookie Policy', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Terms and Conditions', attrHref: '#', onClicked: () => alert('Is clicked!') },
                ]}
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Footer, ReactAux } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <ReactAux>
            
            {/* The pre-footer element, that sets the required spacing above the Footer */}
            <div className="abc-pre-footer-mb"></div>

            <Footer
                cards={[
                    {
                        title: 'Get our magazine',
                        description: 'Articles on island-related topics in seasonal and special editions. Check them out.',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/get-mag-2017b.png'
                        },
                        buttonLink: {
                            title: 'get the magazine', attrHref: '#', onClicked: () => alert('Is clicked!')
                        }
                    },
                    {
                        title: 'Get our newsletter',
                        description: 'Become a member and receive regular updates!',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/banner/get-mag-2017b.png'
                        },
                        buttonLink: {
                            title: 'subscribe now', attrHref: '#', onClicked: () => alert('Is clicked!')
                        }
                    },
                    {
                        title: 'Follow us on Facebook',
                        description: 'Don´t miss our latest news, follow us on Facebook!',
                        image: {
                            src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2016/02/fb.png'
                        },
                        buttonLink: {
                            title: 'follow us', attrHref: '#', onClicked: () => alert('Is clicked!')
                        }
                    },
                ]}
                pagesLinks={[
                    { title: 'About Mallorca', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Events', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Restaurants', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Hotels', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Activities', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Services', attrHref: '#', onClicked: () => alert('Is clicked!') },
                ]}
                otherLinks={[
                    { title: 'About abcMallorca', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Newsletter', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'abcMallorca Media', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Contact us', attrHref: '#', onClicked: () => alert('Is clicked!') },
                ]}
                infoText="abcMallorca is the leading luxury lifestyle magazine, website and networking community for the Spanish Balearic island of Mallorca, published in English, Spanish and German. Interesting articles, guides, expert review & fabulous photo-shoots and videos provide a wealth of valuable insider information designed to help make living, doing business or spending time on this beautiful island a memorable experience."
                facebookLink={{ attrHref: '#', attrTargetBlank: true }}
                instagramLink={{ attrHref: '#', attrTargetBlank: true }}
                twitterLink={{ attrHref: '#', attrTargetBlank: true }}
                googleLink={{ attrHref: '#', attrTargetBlank: true }}
                linkedinLink={{ attrHref: '#', attrTargetBlank: true }}
                pinterestLink={{ attrHref: '#', attrTargetBlank: true }}
                youtubeLink={{ attrHref: '#', attrTargetBlank: true }}
                copyrightText="© 2019 abc-knowledge S.L. All Rights Reserved."
                policyLinks={[
                    { title: 'Privacy Policy', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Cookie Policy', attrHref: '#', onClicked: () => alert('Is clicked!') },
                    { title: 'Terms and Conditions', attrHref: '#', onClicked: () => alert('Is clicked!') },
                ]}
            />
        </ReactAux>
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>cards</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "title" : string, "description": string, "image": object with: "src": string, "srcSet": string,<br />
                            "buttonLink": object with: "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the cards information.</td>
                    </tr>
                    <tr>
                        <td>pagesLinks</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set the links in left side.</td>
                    </tr>
                    <tr>
                        <td>otherLinks</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set the links in right side.</td>
                    </tr>
                    <tr>
                        <td>infoText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the info text.</td>
                    </tr>
                    <tr>
                        <td>facebookLink</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set link for facebook icon.</td>
                    </tr>
                    <tr>
                        <td>instagramLink</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set link for instagram icon.</td>
                    </tr>
                    <tr>
                        <td>twitterLink</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set link for twitter icon.</td>
                    </tr>
                    <tr>
                        <td>googleLink</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set link for google icon.</td>
                    </tr>
                    <tr>
                        <td>linkedinLink</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set link for linkedin icon.</td>
                    </tr>
                    <tr>
                        <td>pinterestLink</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set link for pinterest icon.</td>
                    </tr>
                    <tr>
                        <td>youtubeLink</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set link for youtube icon.</td>
                    </tr>
                    <tr>
                        <td>copyrightText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>The the copyright text.</td>
                    </tr>
                    <tr>
                        <td>policyLinks</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                            </td>
                        <td className="text-center">-</td>
                        <td>Set the links in bottom part.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FooterPage;
