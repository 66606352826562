import React from 'react';
import Highlight from 'react-highlight';

import { HomeSlider } from '@studiouxp/abc-mallorca-portal-ds';

const slides = [
  { text: "Example video", videoCode: '0p-vXwu0xZk?rel=0&enablejsapi=1&autoplay=1&mute=1' },
  {
    text: "Example 1",
    image: {
      imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/08/cala-boquer-pollensa.jpg' },
      imageLink: { attrHref: '#' }
    },
  },
  {
    text: "Example 2",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/04/mar-de-nudos-featured.jpg' } },
  },
  {
    text: "Example 3",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/10/carrosa-hotel-mallorca-rural-arta-img01.jpg' } },
  },
  {
    text: "Example 4",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2020/07/portals-nous.jpg' } },
  },
  {
    text: "Example 5",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2019/08/cala-mondrago-drone-01.jpg' } },
  },
  {
    text: "Example 6",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2018/07/calo-des-moro-beach01.jpg' } }
  }
];

const HomeSliderPage: React.FC = () => {
  return (
    <div>
      <h1>Home Slider</h1>

      <h2>Description</h2>
      <p className="mb-m">Utilizing <a href="https://react-slick.neostack.com/docs/get-started" target="_blank" rel="noopener noreferrer">React Slick
            </a> library.</p>

      <h3>Default</h3>
      <HomeSlider
        slides={slides}
        dots={true}
        autoplay={true}
        pauseOnHover={true}
        autoplaySpeed={4000}
        speed={300}
        initialSlide={0}
        infinite={false}
        className="example"
        title="Regions"
      />

      <h3>Multiple slides and negative gutter</h3>
      <HomeSlider
        slides={slides}
        dots={true}
        autoplay={false}
        pauseOnHover={true}
        autoplaySpeed={3000}
        speed={300}
        initialSlide={0}
        infinite={false}
        showMultipleSlides={true}
        title="Regions"
      />

      <h3>Multiple slides, background and negative gutter</h3>
      <HomeSlider
        slides={slides}
        dots={true}
        autoplay={false}
        pauseOnHover={true}
        autoplaySpeed={3000}
        speed={300}
        initialSlide={0}
        infinite={false}
        showMultipleSlides={true}
        title="Regions"
        isBackground
      />

      <Highlight className="React">
        {
          `
import React from 'react';
import { HomeSlider } from '@studiouxp/abc-mallorca-portal-ds';

const slides = [
  { text: "Example video", videoCode: '0p-vXwu0xZk?rel=0&enablejsapi=1&autoplay=1&mute=1' },
  {
    text: "Example 1",
    image: {
      imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_platos-56.jpg' },
      imageLink: { attrHref: '#' }
    },
  },
  {
    text: "Example 2",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/el-pesquero-restaurant-exterior-955x505_c.jpg' } },
  },
  {
    text: "Example 3",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/SALMON-copia.jpg' } },
  },
  {
    text: "Example 4",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_interior_25-955x505_c.jpg' } },
  },
  {
    text: "Example 5",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/el-pesquero-restaurant-exterior-evening.jpg' } },
  },
  {
    text: "Example 6",
    image: { imagePath: { src: 'https://mk0abcmallorca71dgvy.kinstacdn.com/wp-content/uploads/2015/06/Pesquero_platos-33.jpg' } }
  }
];

const Example: React.FC = (props) => {
    return (
        <HomeSlider 
            slides={slides}
            dots={true}
            autoplay={true}
            pauseOnHover={true}
            autoplaySpeed={4000}
            speed={300}
            initialSlide={0}
            infinite={false}
            className="example" 
        />

       // HomeSlider with multiple slides
        <HomeSlider
          slides={slides}
          dots={true}
          autoplay={false}
          pauseOnHover={true}
          autoplaySpeed={3000}
          speed={300}
          initialSlide={0}
          infinite={false}

          //props for HomeSlider with multiple slides
          showMultipleSlides={true}
          title="Regions"
       />

       // HomeSlider with multiple slides annd background
       <HomeSlider
       slides={slides}
       dots={true}
       autoplay={false}
       pauseOnHover={true}
       autoplaySpeed={3000}
       speed={300}
       initialSlide={0}
       infinite={false}
       showMultipleSlides={true}
       title="Regions"
       isBackground
     />
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>slides</td>
            <td className="w-100">array of objects. Object with props:<br />
              "text" : string,<br />
              <hr />
              "image": object with: "imagePath": object with: "src": string, "srcSet": string ,"imageLink": object with: "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}<br />
              <hr />
              "videoCode": string
            </td>
            <td className="text-center">-</td>
            <td>Sets image or video slides.</td>
          </tr>
          <tr>
            <td>Some of ReactSlick props: </td>
            <td>Available options: dots, infinite, autoplay, autoplaySpeed, pauseOnHover, speed, initialSlide</td>
            <td>
              <a href="https://react-slick.neostack.com/docs/api" target="_blank"
                rel="noopener noreferrer">Default values</a>
              <br />
               infinite: false
              </td>
            <td>For ease of use, the props of HomeSlider component extend ReactSlick, but there
            are restrictions for some of these props due to implementation logic and design consistency.
            </td>
          </tr>
          <tr>
            <td>title</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets the title.</td>
          </tr>
          <tr>
            <td>showMultipleSlides</td>
            <td className="text-center">boolean</td>
            <td className="text-center">-</td>
            <td>Sets the HomeSlider multiple slides styles and structure.</td>
          </tr>
          <tr>
            <td>isBackground</td>
            <td className="text-center">boolean</td>
            <td className="text-center">false</td>
            <td>Sets the HomeSlider background.</td>
          </tr>
          <tr>
            <td>className</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML class to the wrapping element of the component.</td>
          </tr>
          <tr>
            <td>id</td>
            <td className="text-center">string</td>
            <td className="text-center">-</td>
            <td>Sets HTML id to the wrapping element of the component.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default HomeSliderPage;
