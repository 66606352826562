import React from 'react';
import { Switch, Route } from 'react-router-dom';

import 'highlight.js/styles/dracula.css';

import Sidebar from './Sidebar/Sidebar';
import DocContainer from './DocContainer/DocContainer';


import {
    Introduction, Container, Button, FormControls, ReactAux,
    OpeningHours, BusinessContacts, Datepicker, DisplayPost, Footer, GoogleMapAddress, HeroSlider,
    ContactDetails, DisplayCategory, YoutubeVideo, RelatedArticlesSlider, InfoBox, EventPreview, Banner,
    HomeSlider, ThumbnailsList, HotelOverview, FilterWrapper, Typography, Icon, Header, ActionBar,
    Socials, ArticleSummary, BackgroundWrapper, DemoPages, Modal, Cookies, Collections, SelectCards, ChoiceButtons,
    Hero, PaymentFormControls, BookingSteps, LinkBox, SearchWidget, CreditCard, Loader, WeatherWidget, Toast, Spacing,
    TestimonialsSlider, Magazine, FAQ, Quote, Service
} from './pages';

class Docs extends React.Component {

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Sidebar />
                <DocContainer>
                    <Switch>
                        <Route path="/docs/action-bar" component={ActionBar} />
                        <Route path="/docs/article-summary" component={ArticleSummary} />
                        <Route path="/docs/button" component={Button} />
                        <Route path="/docs/business-contacts" component={BusinessContacts} />
                        <Route path="/docs/container" component={Container} />
                        <Route path="/docs/datepicker" component={Datepicker} />
                        <Route path="/docs/hero-slider" component={HeroSlider} />
                        <Route path="/docs/home-slider" component={HomeSlider} />
                        <Route path="/docs/related-articles-slider" component={RelatedArticlesSlider} />
                        <Route path="/docs/testimonials-slider" component={TestimonialsSlider} />
                        <Route path="/docs/display-post" component={DisplayPost} />
                        <Route path="/docs/filter-wrapper" component={FilterWrapper} />
                        <Route path="/docs/form-controls" component={FormControls} />
                        <Route path="/docs/introduction" component={Introduction} />
                        <Route path="/docs/react-aux" component={ReactAux} />
                        <Route path="/docs/opening-hours" component={OpeningHours} />
                        <Route path="/docs/display-post" component={DisplayPost} />
                        <Route path="/docs/header" component={Header} />
                        <Route path="/docs/footer" component={Footer} />
                        <Route path="/docs/google-map-address" component={GoogleMapAddress} />
                        <Route path="/docs/contact-details" component={ContactDetails} />
                        <Route path="/docs/display-category" component={DisplayCategory} />
                        <Route path="/docs/youtube-video" component={YoutubeVideo} />
                        <Route path="/docs/banner" component={Banner} />
                        <Route path="/docs/event-preview" component={EventPreview} />
                        <Route path="/docs/modal" component={Modal} />
                        <Route path="/docs/quote" component={Quote} />
                        <Route path="/docs/info-box" component={InfoBox} />
                        <Route path="/docs/background-wrapper" component={BackgroundWrapper} />
                        <Route path="/docs/thumbnails-list" component={ThumbnailsList} />
                        <Route path="/docs/hotel-overview" component={HotelOverview} />
                        <Route path="/docs/typography" component={Typography} />
                        <Route path="/docs/toast" component={Toast} />
                        <Route path="/docs/faq" component={FAQ} />
                        <Route path="/docs/icon" component={Icon} />
                        <Route path="/docs/select-cards" component={SelectCards} />
                        <Route path="/docs/socials" component={Socials} />
                        <Route path="/docs/service" component={Service} />
                        <Route path="/docs/demo-pages" component={DemoPages} />
                        <Route path="/docs/cookies" component={Cookies} />
                        <Route path="/docs/choice-buttons" component={ChoiceButtons} />
                        <Route path="/docs/collections" component={Collections} />
                        <Route path="/docs/hero" component={Hero} />
                        <Route path="/docs/payment-form-controls" component={PaymentFormControls} />
                        <Route path="/docs/booking-steps" component={BookingSteps} />
                        <Route path="/docs/link-box" component={LinkBox} />
                        <Route path="/docs/search-widget" component={SearchWidget} />
                        <Route path="/docs/credit-card" component={CreditCard} />
                        <Route path="/docs/loader" component={Loader} />
                        <Route path="/docs/weather-widget" component={WeatherWidget} />
                        <Route path="/docs/utilities/spacing" component={Spacing} />
                        <Route path="/docs/magazine" component={Magazine} />
                    </Switch>
                </DocContainer>
            </div>
        );
    }
}

export default Docs;
