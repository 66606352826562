import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import { BackgroundWrapper } from '@studiouxp/abc-mallorca-portal-ds';

const BackgroundWrapperPage: React.FC = () => {
    return (
        <div>
            <h1>Background Wrapper</h1>

            <h2>Description</h2>
            <p>
                Background Wrapper component wraps the passed content and applies background color. Additionally,
              the component renders the content inside an internal <Link to="./container">Container</Link>, as this
              functionallity can be toggled. The Background Wrapper is most useful
              around sections, that can be logically grouped.
            </p>
            <p><strong>Important:</strong> Some components like <Link to="./home-slider">HomeSlider</Link> have
            their own background variations, so Background Wrapper would not be suitable container for them. In general,
            Background Wrapper could not be used, when the components it wraps have options for custom styles.
            </p>
            <BackgroundWrapper>
                <h1 className="mb-0">Feed</h1>
            </BackgroundWrapper>
            <BackgroundWrapper>
                <h2 className="mb-0">Feed</h2>
            </BackgroundWrapper>
            <BackgroundWrapper>
                <h3 className="mb-0">Feed</h3>
            </BackgroundWrapper>
            <BackgroundWrapper>
                <p className="mb-0 sub-title">Feed</p>
            </BackgroundWrapper>
            <BackgroundWrapper>
                <p className="mb-0">Feed</p>
            </BackgroundWrapper>
            <BackgroundWrapper appearance="primary">
                <p className="mb-0">BackgroundWrapper with primary style appearance</p>
            </BackgroundWrapper>
            <BackgroundWrapper appearance="secondary">
                <p className="mb-0">BackgroundWrapper with explicit secondary style appearance</p>
            </BackgroundWrapper>
            <BackgroundWrapper insertGrid={false}>
                <p className="mb-0">BackgroundWrapper without inner grid elements</p>
            </BackgroundWrapper>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { BackgroundWrapper } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
            <BackgroundWrapper>
                <h1 className="mb-0">Feed</h1>
            </BackgroundWrapper>
            <BackgroundWrapper>
                <h2 className="mb-0">Feed</h2>
            </BackgroundWrapper>
            <BackgroundWrapper>
                <h3 className="mb-0">Feed</h3>
            </BackgroundWrapper>
            <BackgroundWrapper>
                <p className="mb-0 sub-title">Feed</p>
            </BackgroundWrapper>
            <BackgroundWrapper>
                <p className="mb-0">Feed</p>
            </BackgroundWrapper>
            <BackgroundWrapper appearance="primary">
                <p className="mb-0">BackgroundWrapper with primary style appearance</p>
            </BackgroundWrapper>
            <BackgroundWrapper appearance="secondary">
                <p className="mb-0">BackgroundWrapper with explicit secondary style appearance</p>
            </BackgroundWrapper>
            <BackgroundWrapper insertGrid={false}>
                <p className="mb-0">BackgroundWrapper without inner grid elements</p>
            </BackgroundWrapper>
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>appearance</td>
                        <td>"primary", "secondary"</td>
                        <td>"secondary"</td>
                        <td>Sets the style of the BackgroundWrapper.</td>
                    </tr>
                    <tr>
                        <td>insertGrid</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>Defines whether a container with a row and column will
                             wrap the content of the BackgroundWrapper.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default BackgroundWrapperPage;
