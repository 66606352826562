import React from 'react';

import {
    Container, Row, Col, LinkBox,
} from '@studiouxp/abc-mallorca-portal-ds';
import { profileRoutes } from '../../helpers/constants';

const MyProfile: React.FC = () => {

    return (
        <Container>
            <Row className='mt-l'>
                <Col lg={{ size: 8, offset: 2 }}>
                    <LinkBox
                        title="Edit Profile"
                        description="Edit your personal information."
                        icon="profilePrimaryL"
                        link={{ attrHref: profileRoutes.editProfile, attrTitle: 'Edit profile' }}
                    />
                    <LinkBox
                        title="Reservations"
                        description="Manage your reservations."
                        icon="reservationPrimaryL"
                        link={{ attrHref: profileRoutes.reservations, attrTitle: 'Reservations' }}
                    />
                    <LinkBox
                        title="Cards"
                        description="Manage your cards."
                        icon="paymentPrimaryL"
                        link={{ attrHref: profileRoutes.cards, attrTitle: 'Cards' }}
                    />
                    <LinkBox
                        title="Notifications"
                        description="Configure your notifications."
                        icon="notificationsPrimaryL"
                        link={{ attrHref: profileRoutes.notifications, attrTitle: 'Notifications' }}
                    />
                    <LinkBox
                        title="Settings"
                        description="Configure your account settings."
                        icon="settingsPrimaryL"
                        link={{ attrHref: profileRoutes.settings, attrTitle: 'Settings' }}
                    />
                    <a href="/">Get help.</a>
                </Col>
            </Row>
        </Container>
    );
}

export default MyProfile;
