import React from 'react';
import Highlight from 'react-highlight';

import { HotelOverview } from '@studiouxp/abc-mallorca-portal-ds';

const HotelOverviewPage: React.FC = () => {
    return (
        <div>
            <h1>Hotel Overview</h1>

            <HotelOverview
                language="en"
                stars={5}
                location={{ title: 'Andratx', link: { attrHref: '#', onClicked: () => alert('Is clicked!') } }}
                categories={[
                    { title: 'Hideaways', link: { attrHref: '#', onClicked: () => alert('Is clicked!') } },
                    { title: 'New Hotels', link: { attrHref: '#', onClicked: () => alert('Is clicked!') } }
                ]}
                numberOfRooms={64}
                checkIn="14:00h"
                checkOut="12:00h"
                adultOnly
                roomTypes={['Single room', 'Double room']}
                priceFrom={145}
                priceTo={375}
                numberOfBedrooms={34}
                numberOfBathrooms={10}
                numberOfPeople={68}
                distanceFromAirport="5 km"
                distanceFromBeach="500 m"
                nearestTown="Palma"
                additionalInfo={[
                    {
                        title: 'Features',
                        list: [
                            'Reception 24h',
                            'Luggage room',
                            'Swimming Pool',
                            'Garden',
                            'Outdoor furniture',
                            'Street Parking',
                            'Wifi',
                            'Sun loungers',
                            'Bar',
                            'Restaurant',
                            'Wellness & Spa',
                        ]
                    },
                    {
                        title: 'Meals',
                        list: [
                            'Breakfast',
                            'Half Board',
                        ]
                    },
                    {
                        title: 'Room amenities',
                        list: [
                            'Airconditioning',
                            'Balcony',
                            'Room service',
                            'Safe',
                        ]
                    }
                ]}
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { HotelOverview } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <HotelOverview
            language="en"
            stars={5}
            location={{ title: 'Andratx', link: { attrHref: '#', onClicked: () => alert('Is clicked!') } }}
            categories={[
                { title: 'Hideaways', link: { attrHref: '#', onClicked: () => alert('Is clicked!') } },
                { title: 'New Hotels', link: { attrHref: '#', onClicked: () => alert('Is clicked!') } }
            ]}
            numberOfRooms={64}
            checkIn="14:00h"
            checkOut="12:00h"
            adultOnly
            roomTypes={['Single room', 'Double room']}
            priceFrom={145}
            priceTo={375}
            numberOfBedrooms={34}
            numberOfBathrooms={10}
            numberOfPeople={68}
            distanceFromAirport="5 km"
            distanceFromBeach="500 m"
            nearestTown="Palma"
            additionalInfo={[
                {
                    title: 'Features',
                    list: [
                        'Reception 24h',
                        'Luggage room',
                        'Swimming Pool',
                        'Garden',
                        'Outdoor furniture',
                        'Street Parking',
                        'Wifi',
                        'Sun loungers',
                        'Bar',
                        'Restaurant',
                        'Wellness & Spa',
                    ]
                },
                {
                    title: 'Meals',
                    list: [
                        'Breakfast',
                        'Half Board',
                    ]
                },
                {
                    title: 'Room amenities',
                    list: [
                        'Airconditioning',
                        'Balcony',
                        'Room service',
                        'Safe',
                    ]
                }
            ]}
        />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>language</td>
                        <td>"en", "es", "de"</td>
                        <td className="text-center">-</td>
                        <td>Set the langueage for component.</td>
                    </tr>
                    <tr>
                        <td>stars</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set how stars to display.</td>
                    </tr>
                    <tr>
                        <td>location</td>
                        <td className="text-center">
                            Object with props: <br />
                            "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the location.</td>
                    </tr>
                    <tr>
                        <td>categories</td>
                        <td className="text-center">
                            Array of objects with props: <br />
                            "title": string, "attrHref": string, "attrTitle": string, "attrTargetBlank": boolean, {'"onClicked": () => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the categories.</td>
                    </tr>
                    <tr>
                        <td>numberOfRooms</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set number of rooms.</td>
                    </tr>
                    <tr>
                        <td>checkIn</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set text for checkIn.</td>
                    </tr>
                    <tr>
                        <td>checkOut</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set text for checkOut.</td>
                    </tr>
                    <tr>
                        <td>adultOnly</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Show the icon and text.</td>
                    </tr>
                    <tr>
                        <td>roomTypes</td>
                        <td>array of strings</td>
                        <td className="text-center">-</td>
                        <td>Set the room types.</td>
                    </tr>
                    <tr>
                        <td>priceFrom</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set number for starting price in euro.</td>
                    </tr>
                    <tr>
                        <td>priceTo</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set number for end price in euro.</td>
                    </tr>
                    <tr>
                        <td>numberOfBedrooms</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set number of bedrooms.</td>
                    </tr>
                    <tr>
                        <td>numberOfBathrooms</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set number of bathrooms.</td>
                    </tr>
                    <tr>
                        <td>numberOfPeople</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set number of people.</td>
                    </tr>
                    <tr>
                        <td>distanceFromAirport</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set text for distance from airport.</td>
                    </tr>
                    <tr>
                        <td>distanceFromBeach</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set text for distance from beach.</td>
                    </tr>
                    <tr>
                        <td>nearestTown</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the nearest town.</td>
                    </tr>
                    <tr>
                        <td>additionalInfo</td>
                        <td className="text-center">
                            Array of objects with props: <br />
                            "title": string, "list": array of strings
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the additional info.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default HotelOverviewPage;
