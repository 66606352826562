import React from 'react';
import Highlight from 'react-highlight';

import { YoutubeVideo } from '@studiouxp/abc-mallorca-portal-ds';

const YoutubeVideoPage: React.FC = () => {
    return (
        <div>
            <h1>Youtube Video</h1>

            <YoutubeVideo
                title="Youtube Video"
                videoUrl="https://www.youtube.com/embed/6qErv1uzC-U" />

            <YoutubeVideo
                title="Youtube Video Playlist"
                videoUrl="https://www.youtube.com/embed/?autoplay=1&list=PLt6goMH2SpPzXVKaoUKztlgVxBRvW2JWF&listType=playlist&loop=1&rel=0&iv_load_policy=3" />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { YoutubeVideo } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <YoutubeVideo
            title="Youtube Video"
            videoUrl="https://www.youtube.com/embed/6qErv1uzC-U" />

        <YoutubeVideo
            title="Youtube Video Playlist"
            videoUrl="https://www.youtube.com/embed/?autoplay=1&list=PLt6goMH2SpPzXVKaoUKztlgVxBRvW2JWF&listType=playlist&loop=1&rel=0&iv_load_policy=3" />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>videoUrl</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the embed link for youtube video.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default YoutubeVideoPage;
