import React from 'react';

import {
    Container, Row, Col, Icon, Magazine,
} from '@studiouxp/abc-mallorca-portal-ds';

const magazinesList = [
    {
        image: { src: 'https://i.ibb.co/pZTSh5P/Screenshot-2020-10-20-at-11-12-20.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/1rvJ5xv/Screenshot-2020-10-20-at-11-12-35.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/8BQJV89/Screenshot-2020-10-20-at-11-12-47.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/hFLdLbb/Screenshot-2020-10-20-at-13-20-54.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.postimg.cc/ZYj95CKW/logooo.jpg' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        buttonText: 'buy now!',
    },
    {
        image: { src: 'https://i.ibb.co/2cWWcPM/Screenshot-2020-10-20-at-13-21-06.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/2cWWcPM/Screenshot-2020-10-20-at-13-21-06.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/1rvJ5xv/Screenshot-2020-10-20-at-11-12-35.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/8BQJV89/Screenshot-2020-10-20-at-11-12-47.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/hFLdLbb/Screenshot-2020-10-20-at-13-20-54.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.ibb.co/pZTSh5P/Screenshot-2020-10-20-at-11-12-20.png' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
    },
    {
        image: { src: 'https://i.postimg.cc/ZYj95CKW/logooo.jpg' },
        url: { attrHref: '/docs', onClicked: () => alert('Is clicked!') },
        buttonText: 'buy now!',
    },
];

const MagazinePage: React.FC = () => {
    return (
        <Container>
            <Row>
                <Col lg={{ size: 8, offset: 2 }}>
                    <div className="mt-s mb-s">
                        <p className="small d-inline-flex align-items-center"><a href="/">Home</a><Icon icon="arrowRightS" /></p>
                        <p className="small d-inline-flex align-items-center"><a href="/">Level</a><Icon icon="arrowRightS" /></p>
                        <p className="small d-inline-flex align-items-center"><a href="/">Level</a><Icon icon="arrowRightS" /></p>
                        <p className="small d-inline-flex">Active page</p>
                    </div>
                    <h1 className="mb-m">Read the abcMallorca magazine online</h1>
                    <p className="mb-m">The successful lifestyle magazine abcMallorca is published on Balearic Island Mallorca, in English, German and Spanish. Each edition consists of articles and images of topics related to the island including health, food, design, fashion, sport, business, and local society life. Take a look at cover photos and Helen Cummins' editorials from selected editions.</p>

                    <Magazine magazines={magazinesList} />
                </Col>
            </Row>
        </Container>
    )
}

export default MagazinePage
