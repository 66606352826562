import React from 'react';
import Highlight from 'react-highlight';

import { Button } from '@studiouxp/abc-mallorca-portal-ds';

const ButtonPage: React.FC = () => {
  return (
    <div>
      <h1>Button</h1>

      <h2>Description</h2>
      <p>
        Button components extend the native Button element and
        are presented in <i>primary</i>, <i>secondary</i> and <i>ghost</i> appearances. The content of buttons <i>primary</i> and <i>secondary</i> is always
        transformed to upper-case.
      </p>
      <div>
        <Button className="mr-s mb-s">Primary button</Button>
        <Button className="mr-s mb-s" appearance="primary" disabled>primary button disabled</Button>
        <Button className="mr-s mb-s" appearance="secondary">secondary button</Button>
        <Button className="mr-s mb-s" appearance="secondary" disabled>secondary button disabled</Button>
        <span style={{ background: 'black', padding: '8px'}} className='mr-s d-inline-block mb-s'>
          <Button appearance="ghost" >Ghost button</Button>
        </span>
        <span style={{ background: 'black', padding: '8px'}} className='mr-s d-inline-block mb-s'>
          <Button appearance="ghost" disabled >Ghost button disabled</Button>
        </span>

        <h3>Big Buttons</h3>
        <Button className="mr-s mb-s" size="big">Primary Big button</Button>
        <Button className="mr-s mb-s" appearance="secondary" size="big">secondary Big button</Button>
        <Button className="mr-s mb-s" size="big" disabled>Primary Big button</Button>
        <Button className="mr-s mb-s" appearance="secondary" size="big" disabled>secondary Big button</Button>

        <h3>Buttons with icon</h3>
        <Button className="mr-s mb-s" icon="bookWhiteM" >Primary button with icon</Button>
        <Button className="mr-s mb-s" appearance="secondary" icon="aboutMallorcaPrimaryM">Secondary button with icon</Button>
        <Button className="mr-s mb-s" size="big" icon="bookWhiteM" >Primary Big button with icon</Button>
        <Button className="mr-s mb-s" appearance="secondary" size="big" icon="aboutMallorcaPrimaryM">Secondary Big button with icon</Button>
      </div>

      <Highlight className="React">
        {
          `
import React from 'react';
import { Button } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
      <Button>Primary button</Button>
      <Button appearance="primary" disabled>primary button disabled</Button>
      <Button appearance="secondary">secondary button</Button>
      <Button appearance="secondary" disabled>secondary button disabled</Button>
      <Button appearance="ghost" >Ghost button</Button>
      <Button appearance="ghost" disabled >Ghost button disabled</Button>

      // Big btn
      <Button size="big">Primary Big button</Button>
      <Button appearance="secondary" size="big">secondary Big button</Button>
      <Button size="big" disabled>Primary Big button</Button>
      <Button appearance="secondary" size="big" disabled>secondary Big button</Button>

      // Btn with icon
      <Button icon="bookWhiteM" >Primary Big button with icon</Button>
      <Button appearance="secondary" icon="aboutMallorcaPrimaryM">Secondary Big button with icon</Button>
      <Button size="big" icon="bookWhiteM" >Primary Big button with icon</Button>
      <Button appearance="secondary" size="big" icon="aboutMallorcaPrimaryM">Secondary Big button with icon</Button>
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>appearance</td>
            <td>"primary", "secondary", "ghost"</td>
            <td>"primary"</td>
            <td>Sets the style of the button.</td>
          </tr>
          <tr>
            <td>size</td>
            <td>"big", "regular"</td>
            <td>"regular"</td>
            <td>Sets the size of the button.</td>
          </tr>
          <tr>
            <td>icon</td>
            <td>string</td>
            <td></td>
            <td>Sets the size of the button.</td>
          </tr>
          <tr>
            <td>native button attributes</td>
            <td className='text-center'>-</td>
            <td className='text-center'>-</td>
            <td>All HTML button attributes are supported.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ButtonPage;
