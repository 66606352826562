import React from 'react';
import Highlight from 'react-highlight';

import { InfoBox } from '@studiouxp/abc-mallorca-portal-ds';

const InfoBoxPage: React.FC = () => {
  return (
    <div>
      <h1>Info Box</h1>

      <h2>Description</h2>
      <p>
        Info Box component wraps your content in paragraph. Make sure you are not placing any block elements in it.
            </p>
      <InfoBox>
        <span className="text-bold">Activities in</span> <a href="https://www.abc-mallorca.com/">Mallorca</a> If you are looking for ideas for your holiday and fun things to do, Mallorca has it all on just one island.
                Whether you are a big family with children or are looking for an adventure on your own, there is something for summer as well as winter.
                From outdoor activities and sports to water fun, you will never run out of things to do! Mallorca offers markets, museums, festivals and concerts in stunning Mediterranean locations.
                A trip to Mallorca will never be forgotten!
      </InfoBox>

      <InfoBox className="mt-s" textSize='normal' title="InfoBox">
        <span className="text-bold">Activities in</span> <a href="https://www.abc-mallorca.com/">Mallorca</a> If you are looking for ideas for your holiday and fun things to do, Mallorca has it all on just one island.
                Whether you are a big family with children or are looking for an adventure on your own, there is something for summer as well as winter.
                From outdoor activities and sports to water fun, you will never run out of things to do! Mallorca offers markets, museums, festivals and concerts in stunning Mediterranean locations.
                A trip to Mallorca will never be forgotten!
            </InfoBox>

      <InfoBox className="mt-s" noBackground>
        <strong>Activities in</strong> <a href="https://www.abc-mallorca.com/">Mallorca</a> If you are looking for ideas for your holiday and fun things to do, Mallorca has it all on just one island.
                Whether you are a big family with children or are looking for an adventure on your own, there is something for summer as well as winter.
                From outdoor activities and sports to water fun, you will never run out of things to do! Mallorca offers markets, museums, festivals and concerts in stunning Mediterranean locations.
                A trip to Mallorca will never be forgotten!
            </InfoBox>

      <Highlight className="React">
        {
          `
import React from 'react';
import { InfoBox } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <InfoBox>
            <span className="text-bold">Activities in</span> <a href="https://www.abc-mallorca.com/">Mallorca</a> If you are looking for ideas for your holiday and fun things to do, Mallorca has it all on just one island. 
            Whether you are a big family with children or are looking for an adventure on your own, there is something for summer as well as winter. 
            From outdoor activities and sports to water fun, you will never run out of things to do! Mallorca offers markets, museums, festivals and concerts in stunning Mediterranean locations. 
            A trip to Mallorca will never be forgotten!
        </InfoBox>

        <InfoBox className="mt-s" textSize='normal' title="InfoBox">
            <span className="text-bold">Activities in</span> <a href="https://www.abc-mallorca.com/">Mallorca</a> If you are looking for ideas for your holiday and fun things to do, Mallorca has it all on just one island. 
            Whether you are a big family with children or are looking for an adventure on your own, there is something for summer as well as winter. 
            From outdoor activities and sports to water fun, you will never run out of things to do! Mallorca offers markets, museums, festivals and concerts in stunning Mediterranean locations. 
            A trip to Mallorca will never be forgotten!
        </InfoBox>

        <InfoBox className="mt-s" noBackground>
          <strong>Activities in</strong> <a href="https://www.abc-mallorca.com/">Mallorca</a> If you are looking for ideas for your holiday and fun things to do, Mallorca has it all on just one island. 
          Whether you are a big family with children or are looking for an adventure on your own, there is something for summer as well as winter. 
          From outdoor activities and sports to water fun, you will never run out of things to do! Mallorca offers markets, museums, festivals and concerts in stunning Mediterranean locations. 
          A trip to Mallorca will never be forgotten!
        </InfoBox>
    );
}

export default Example;
    `}
      </Highlight>

      <table className="doc-table mb-3">
        <thead>
          <tr>
            <th colSpan={4}>Props</th>
          </tr>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Options</strong>
            </td>
            <td>
              <strong>Default</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>title</td>
            <td>string</td>
            <td className='text-center'>-</td>
            <td>Set title.</td>
          </tr>
          <tr>
            <td>textSize</td>
            <td>"normal", "small"</td>
            <td>"small"</td>
            <td>Sets font size.</td>
          </tr>
          <tr>
            <td>noBackground</td>
            <td className="text-center">boolean</td>
            <td className="text-center">false</td>
            <td>Defines if the Collections element is rendered with border and without a background.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default InfoBoxPage;
