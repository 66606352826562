import React from 'react';
import Highlight from 'react-highlight';

import { ChoiceButtons } from '@studiouxp/abc-mallorca-portal-ds';

const choiceButtons = [
    {
        value: 1,
        title: '1',
    },
    {
        value: 2,
        checked: true
    },
    {
        value: 3,
    },
    {
        value: 4,
    },
    {
        value: 5,
    },
    {
        value: 6,
    },
    {
        value: 7,
    },
    {
        value: 8,
    }
];


const choiceButtonsSecond = [
    {
        value: "09:00",
        disabled: true,
    },
    {
        value: "10:30",
    },
    {
        value: "11:15",
    },
    {
        value: "14:45",
    },
    {
        value: "18:22",
    },
    {
        value: "19:45",
    },
    {
        value: "22:30",
    },
    {
        value: "23:45",
    }
];


const updateCheckedChoices = (array: any, targetValue: any) => {
    return array.map((el: any) => {
        if (el.value.toString() === targetValue) {
            el['checked'] = true;
        } else {
            delete el.checked;
        }
        return el;
    });
}

class ChoiceButtonsPage extends React.Component {
    state = {
        choiceButtons: choiceButtons,
        choiceButtonsSecond: choiceButtonsSecond
    }

    onChangeChoiceButtons = (e: any) => {

        let updatedChoices = {};
        if (e.target.name === 'choice-adult') {
            const choiceButtons = updateCheckedChoices(this.state.choiceButtons, e.target.value);
            updatedChoices = { choiceButtons: choiceButtons };
        } else if (e.target.name === 'choice-hours') {
            const choiceButtonsSecond = updateCheckedChoices(this.state.choiceButtonsSecond, e.target.value);
            updatedChoices = { choiceButtonsSecond: choiceButtonsSecond };
        }

        this.setState({
            ...updatedChoices
        })
    }
    render() {
        return (
            <div>
                <h1>ChoiceButtons</h1>
                <ChoiceButtons
                    title="Adults"
                    buttons={this.state.choiceButtons}
                    onChange={this.onChangeChoiceButtons}
                    choiceButtonsName={'choice-adult'}
                    gridColProps={{lg:"3"}}
                />

                <ChoiceButtons
                    title="Lunch"
                    buttons={this.state.choiceButtonsSecond}
                    onChange={this.onChangeChoiceButtons}
                    choiceButtonsName={'choice-hours'}
                />


<Highlight className="React">{
`
import React from 'react';
import { Modal, Container, Row, Col, FormControl, Input, Button  } from '@studiouxp/abc-mallorca-portal-ds';
// demo data
const choiceButtons = [
    {
        value: 1,
        title: '1',
    },
    {
        value: 2,
        checked: true
    },
    ....
    {
        value: 8,
    }
];

const choiceButtonsSecond = [
{
    value: "09:00",
    disabled: true,
},
{
    value: "10:30",
},
....
{
    value: "23:45",
}
];

// helper for the demo
const updateCheckedChoices = (array: any, targetValue: any) => {
    return array.map((el: any) => {
        if (el.value.toString() === targetValue) {
            el['checked'] = true;
        } else {
            delete el.checked;
        }
        return el;
    });
}


class Example extends React.Component{
    state = {
        choiceButtons: choiceButtons,
        choiceButtonsSecond: choiceButtonsSecond
    }

    // demo implementation
    onChangeChoiceButtons = (e: any) => {

        let updatedChoices = {};
        if (e.target.name === 'choice-adult') {
            const choiceButtons = updateCheckedChoices(this.state.choiceButtons, e.target.value);
            updatedChoices = { choiceButtons: choiceButtons };
        } else if (e.target.name === 'choice-hours') {
            const choiceButtonsSecond = updateCheckedChoices(this.state.choiceButtonsSecond, e.target.value);
            updatedChoices = { choiceButtonsSecond: choiceButtonsSecond };
        }

        this.setState({
            ...updatedChoices
        })
    }

    render(){
        return (          
            <ChoiceButtons
                title="Adults"
                buttons={this.state.choiceButtons}
                onChange={this.onChangeChoiceButtons}
                choiceButtonsName={'choice-adult'}

                // wont need to use this by default
                gridColProps={{lg:"3"}}
            />

             <ChoiceButtons
                title="Lunch"
                buttons={this.state.choiceButtonsSecond}
                onChange={this.onChangeChoiceButtons}
                choiceButtonsName={'choice-hours'}
            />
        );
    }
}

export default Example;
        `}
</Highlight>

                <table className="doc-table">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Set the title.</td>
                        </tr>
                        <tr>
                            <td>buttons</td>
                            <td>
                              Array of objects with props: <br/> 
                              "value": string || number <br/> 
                              "title": string ( If not set {'=>'} "value" will be set as "title".)<br/> 
                              "disabled": boolean,
                              "checked": boolean
                            </td>
                            <td className="text-center">-</td>
                            <td>Set choice buttons.</td>
                        </tr>
                        <tr>
                            <td>onChange</td>
                            <td> Function </td>
                            <td className="text-center">-</td>
                            <td>Set onChange function to every choice button.</td>
                        </tr>
                        <tr>
                            <td>choiceButtonsName</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Set name attribute to every choice button.</td>
                        </tr>
                        <tr>
                            <td>gridColProps</td>
                            <td className="text-center">ReactStrap props <a target="_blank" href="https://reactstrap.github.io/components/layout/" rel="noopener noreferrer" title="react-strap-doc">here</a></td>
                            <td className="text-center">xs="3"</td>
                            <td>Sets grid col classes to the wrapping element of the choice.</td>
                        </tr>
                        <tr>
                            <td>className</td>
                            <td className="text-center">string</td>
                            <td className="text-center">-</td>
                            <td>Sets HTML class to the wrapping element of the component.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

export default ChoiceButtonsPage;
