import React from 'react';
import Highlight from 'react-highlight';

import { OpeningHours } from '@studiouxp/abc-mallorca-portal-ds';

const OpeningHoursPage: React.FC = () => {
    return (
        <div>
            <h1>Opening Hours</h1>

            <OpeningHours
                title="Opening Hours"
                note="Open all year"
                rows={[
                    { title: 'Monday', hours: ['13:00 - 01:00 h'] },
                    { title: 'Tuesday', hours: ['09:00 - 12:00 h', '13:00 - 19:00 h'] },
                    { title: 'Wednesday', hours: ['13:00 - 01:00 h'] },
                    { title: 'Thursday', hours: ['13:00 - 01:00 h'] },
                    { title: 'Friday', hours: ['13:00 - 01:00 h'] },
                    { title: 'Saturday', hours: ['13:00 - 01:00 h'] },
                    { title: 'Sunday', hours: ['closed'] },
                ]} />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { OpeningHours } from '@studiouxp/abc-mallorca-portal-ds';

const Example: React.FC = (props) => {
    return (
        <OpeningHours
            title="Opening Hours"
            note="Open all year"
            rows={[
                { title: 'Monday', hours: ['13:00 - 01:00 h'] },
                { title: 'Tuesday', hours: ['09:00 - 12:00 h', '13:00 - 19:00 h'] },
                { title: 'Wednesday', hours: ['13:00 - 01:00 h'] },
                { title: 'Thursday', hours: ['13:00 - 01:00 h'] },
                { title: 'Friday', hours: ['13:00 - 01:00 h'] },
                { title: 'Saturday', hours: ['13:00 - 01:00 h'] },
                { title: 'Sunday', hours: ['closed'] },
            ]} />
    );
}

export default Example;
    `}
            </Highlight>

            <table className="doc-table">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the title for component.</td>
                    </tr>
                    <tr>
                        <td>rows</td>
                        <td className="text-center">
                            array of objects.<br />
                            Object must have props:<br />
                            "title" : string,
                            "hours": array of strings
                            </td>
                        <td className="text-center">-</td>
                        <td>Set the content.</td>
                    </tr>
                    <tr>
                        <td>note</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the note.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default OpeningHoursPage;
